import { gql }                   from "@apollo/client";
import { useQuery }              from "@apollo/client";
import { useParams }             from "@relcu/react-router";
import { useSource }             from "@relcu/ui";
import { useMemo }               from "react";
import { GetDashboards }         from "./__types__/GetDashboards";
import { GetTableauToken }       from "./__types__/GetTableauToken";
import { GetViewerTableauToken } from "./__types__/GetViewerTableauToken";


export const GET_TABLEAU_TOKEN = gql`
  query GetViewerTableauToken{
    viewer {
      tableauToken
    }
  }`;


export const GET_DASHBOARDS = gql`
  query GetDashboards{
    dashboards(where:{},order:order_ASC) {
      edges {
        node {
          objectId
          objectIcon
          objectName
          parentObjectId
          url
          type
          disabled
        }
      }
    }
  }`;


export function useDashboardView() {
  const { data: { dashboards : {edges : dashboards = []} = {} } = {}, loading: loadingDashboards } = useQuery<GetDashboards>(GET_DASHBOARDS, { fetchPolicy: "cache-first" });

  const { $viewer : { team = null, role = null} = {} } = useSource();
  const params = useParams();
  const selectedDashboard = useMemo(()=>{
    let p = Object.values(params);
    return p[0] && p[0]!="" ? dashboards.find((d) => d.node.objectId==p[0]) : null;
  },[params,dashboards]);

  const childDashboards = useMemo(()=>{
    return selectedDashboard ? dashboards.filter((d)=>d.node.parentObjectId===selectedDashboard.node.objectId) : dashboards.filter((d)=>!d.node.parentObjectId);
  },[dashboards,selectedDashboard]);
  return {
    team,
    role,
    dashboards,
    loadingDashboards,
    selectedDashboard,
    childDashboards
  }

}