import React                       from "react";
import { FC }                      from "react";
import { useCallback }             from "react";
import { useApolloClient }         from "@apollo/client";
import { FormSpy }                 from "@relcu/final-form";
import { Form }                    from "@relcu/final-form";
import { useThrottleCallback }     from "@relcu/ui";
import { useAlert }                from "@relcu/ui";
import { deepPick }                from "@relcu/ui";
import { JsonField }               from "@relcu/ui";
import { defaultMutators }         from "@relcu/ui";
import { Box }                     from "@relcu/ui";
import { Widget }                  from "@relcu/ui";
import { useSource }               from "@relcu/ui";
import { schemaVar }               from "../../../../reactiveVars";
import { transformFields }         from "../../../../utils/graphQlUtils";
import { getField }                from "../../../../utils/schemaUtils";
import { getJqlMutation }          from "../../Jql";
import { CustomEditWidgetClasses } from "./CustomEditWidgetClasses";
import "./custom-edit-widget.css";

export interface CustomEditWidgetProps {
  header: {
    title: string[]
  };
  fields: any[];
  jql?: {
    query?: {
      find?: any
      get?: any
    },
    mutation?: {
      create?: any,
      update?: any,
      delete?: any,
    }
    subscription?: any
  };
}

//todo fast solution need to prettify
export const CustomEditWidget: FC<CustomEditWidgetProps> = (props) => {
  const { fields, jql } = props;
  const client = useApolloClient();
  const { success, error } = useAlert();
  const { $object: node } = useSource();
  const schemas = schemaVar();

  const update = async (variables) => {
    const { documentNode: mutation } = getJqlMutation(jql.mutation.update, { operationName: `QuickUpdate${node.__typename}Form` });
    const { data } = await client.mutate({
      refetchQueries: [`${node.__typename}ListViewQuery`],
      mutation,
      variables
    });
    return data?.[ jql.mutation.update.operation ];
  };

  const saveNode = useThrottleCallback(async (data, form) => {
      try {
        const { initialValues, dirtyFields } = form.getState();
        if (Object.keys(dirtyFields).length < 1) {
          return;
        }
        let variables;
        const registeredFields = form.getRegisteredFields();
        const values = deepPick(data, registeredFields);
        const fields = transformFields(values, initialValues, node.__typename, schemas);
        variables = { input: { id: data.objectId, fields } };
        await update(variables);
        success("Changes saved successfully.");
        // const result = await submit(variables, form);
        // jqlForm.onSuccess(result, form, variables);
      } catch (e) {
        // error(`Oops. Something went wrong!`);
        // const onFailure = onError || jqlForm.onError;
        // return onFailure(e, form, variables);
      }
    }, 2000, false
  );

  const handleFormChange = useCallback((values, form) => {
    queueMicrotask(() => saveNode(values, form));
  }, []);

  return (
    <Widget gap={"XS"} className={CustomEditWidgetClasses.CustomEditWidget}>
      <Box container direction={"column"}>
        {/*<Box container justify={"start"} className={CustomEditWidgetClasses.CustomEditWidgetMainRow}>*/}
        {/*  {title}*/}
        {/*</Box>*/}
        <Form
          onSubmit={() => undefined}
          initialValues={node}
          mutators={defaultMutators}>
          {
            ({ form, handleSubmit }) => {
              return <form
                onSubmit={handleSubmit}
                noValidate
                style={{ display: "contents" }}>
                {
                  fields.map((field, index) => {
                      const { defaultValue, ...rest } = field;
                      return <JsonField
                        options={getField(node.__typename, field.name)?.[ "options" ]}
                        key={index}
                        {...rest}
                        mode={"edit"}
                        name={field.name}
                        view={"write"}
                      />;
                    }
                  )
                }
                <FormSpy subscription={{ values: true }} onChange={({ values }) => handleFormChange(values, form)}/>
              </form>;
            }
          }
        </Form>
      </Box>
    </Widget>
  );
};
