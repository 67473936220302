import React                from "react";
import { useContext }       from "react";
import { useCallback }      from "react";
import { useReactiveVar }   from "@apollo/client";
import { Form }             from "@relcu/form";
import { Button }           from "@relcu/rc";
import { useAlert }         from "@relcu/ui";
import { FontIcon }         from "@relcu/ui";
import { Box }              from "@relcu/ui";
import { TextField }        from "@relcu/ui";
import { ClientContext }    from "../../../Client";
import { MICROSOFT_SCOPE }  from "../../../constants/Microsoft";
import { loadingVar }       from "../../../reactiveVars";
import { GmailLoginButton } from "../../Auth/Gmail/GmailLoginButton";
import { MsLoginButton }    from "../../Auth/Microsoft/MsLoginButton";
import { LoginClasses }     from "./LoginClasses";

export const Login = React.memo((props) => {
  const { login, deviceId } = useContext(ClientContext);
  const { error } = useAlert();
  const connecting = useReactiveVar(loadingVar);
  const validate = useCallback((values) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = "Username is required.";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  }, []);

  function onLogin({ username, password }) {
    authenticate(`Basic ${btoa(`${username}:${password}`)}`);
  }

  function ssoLogin(token) {
    authenticate(`SSO ${token || ""}`);
  }

  function authenticate(authorization) {
    login(authorization);
  }

  const onLoginViaSuccess = (data) => ssoLogin(data.token);
  const onLoginViaFailure = (data) => {
    error(data.error.message);
  };

  return (
    <Form
      subscription={{ submitting: true }}
      validate={validate}
      onSubmit={onLogin}
    >
      {({ handleSubmit, submitting, errors }) => (
        <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
          <Box container direction={"column"} gap={"L"} flex={"1 0"} className={LoginClasses.Login}>
            <Box container direction={"column"} gap={"XXXL"} flex={"1 0"}>
              <Box container justify={"center"} className={LoginClasses.LoginHeader}>
                Log in to your Relcu account
              </Box>

              <Box container justify={"center"} direction={"column"} gap={"M"}>
                <TextField
                  name="username"
                  label="USERNAME"
                  icon={<FontIcon type={"account_box"} alignSelf={"center"}/>}
                  required
                  placeholder={"Enter your username"}
                />
                <TextField
                  name="password"
                  label="PASSWORD"
                  type={"password"}
                  icon={<FontIcon type={"https"} alignSelf={"center"}/>}
                  required
                  placeholder={"Enter your password"}
                />
              </Box>
              <Box container direction={"column"} gap={"S"}>
                <Box container justify={"center"} className={LoginClasses.LoginButtonContainer}>
                  <Button type={"submit"} disabled={submitting || connecting} style={{ flex: 1 }}>LOGIN</Button>
                </Box>
                {
                  window.__CONFIG__.outlook &&
                  <>
                    <Box container justify={"center"}>
                      or
                    </Box>
                    <MsLoginButton
                      disabled={connecting}
                      deviceId={deviceId}
                      scope={[MICROSOFT_SCOPE.USER_READ]}
                      onSuccess={onLoginViaSuccess}
                      onError={onLoginViaFailure}
                    />
                  </>
                }
                {
                  window.__CONFIG__.gmail &&
                  <>
                    <Box container justify={"center"}>
                      or
                    </Box>

                    <GmailLoginButton
                      disabled={connecting}
                      deviceId={deviceId}
                      onSuccess={onLoginViaSuccess}
                      onError={onLoginViaFailure}
                    />
                  </>
                }
              </Box>
            </Box>
            {/*<Box  container justify={"center"} className={LoginClasses.LoginLink}>*/}
            {/*  <Link to={"/forgot-password"}>Forgot password?</Link>*/}
            {/*</Box>*/}
          </Box>
        </form>
      )}
    </Form>
  );
});
Login.displayName = "Login";
export default Login;
