import React                                                         from "react";
import { FC }                                                        from "react";
import { useContext }                                                from "react";
import { createContext }                                             from "react";
import { useMemo }                                                   from "react";
import { useState }                                                  from "react";
import { useNavigate }                                               from "@relcu/react-router";
import { useParams }                                                 from "@relcu/react-router";
import { useReactiveVar }                                            from "@apollo/client";
import { useMutation }                                               from "@apollo/client";
import { OnChange }                                                  from "@relcu/form";
import { FormSpy }                                                   from "@relcu/form";
import { IconButton }                                                from "@relcu/rc";
import { ToggleDropdown }                                            from "@relcu/rc";
import { Icon }                                                      from "@relcu/rc";
import { Form }                                                      from "@relcu/rc";
import { Stack }                                                     from "@relcu/rc";
import { Button }                                                    from "@relcu/rc";
import { TabItem }                                                   from "@relcu/rc";
import { TabsSwitcher }                                              from "@relcu/rc";
import { TabPanel }                                                  from "@relcu/rc";
import { TabPanelContainer }                                         from "@relcu/rc";
import { TabContainer }                                              from "@relcu/rc";
import { Container }                                                 from "@relcu/rc";
import { Typography }                                                from "@relcu/rc";
import { Page }                                                      from "@relcu/rc";
import { Toolbar }                                                   from "@relcu/rc";
import { Header }                                                    from "@relcu/rc";
import { Content as RCContent }                                      from "@relcu/rc";
import { Alignment }                                                 from "@relcu/ui";
import { Tooltip }                                                   from "@relcu/ui";
import { toFirstUpper }                                              from "@relcu/ui";
import { useAlert }                                                  from "@relcu/ui";
import { useSource }                                                 from "@relcu/ui";
import { omit }                                                      from "@relcu/ui";
import { defaultMutators }                                           from "@relcu/ui";
import { HorizontalDivider }                                         from "@relcu/ui";
import { ToggleSwitch }                                              from "@relcu/ui";
import { fullScreenVar }                                             from "../../../../../reactiveVars";
import { toNodeId }                                                  from "../../../../../utils/helpers";
import { toFirstLower }                                              from "../../../../../utils/helpers";
import { getReplacementParams }                                      from "../../../../../utils/schemaUtils";
import { DirtyDialogController }                                     from "../../../../Generation";
import { CreatePhoneMessageTemplateVariables }                       from "../__types__/CreatePhoneMessageTemplate";
import { CreatePhoneMessageTemplate }                                from "../__types__/CreatePhoneMessageTemplate";
import { GetPhoneMessageTemplates_phoneMessageTemplates_edges_node } from "../__types__/GetPhoneMessageTemplates";
import { UpdatePhoneMessageTemplateVariables }                       from "../__types__/UpdatePhoneMessageTemplate";
import { UpdatePhoneMessageTemplate }                                from "../__types__/UpdatePhoneMessageTemplate";
import { UPDATE_PHONE_MESSAGE_TEMPLATE }                             from "../PhoneMessageTemplateViewQueries";
import { CREATE_PHONE_MESSAGE_TEMPLATE }                             from "../PhoneMessageTemplateViewQueries";
import {
  PhoneMessageTemplateDetails
}                                                                    from "./PhoneMessageTemplateDetails/PhoneMessageTemplateDetails";
import {
  PhoneMessageTemplateEditor
}                                                                    from "./PhoneMessageTemplateEditor";

export interface ContentProps {
  expand: boolean;
  setExpand: any;
  template?: GetPhoneMessageTemplates_phoneMessageTemplates_edges_node;
  onDuplicate?(data: GetPhoneMessageTemplates_phoneMessageTemplates_edges_node);
  onRemove?(id: string);
  hasPermissions: boolean;
  replaceableFieldsSources?: string[];
}

const requiredFields = {
  "title": "Template name",
  "text": "Content",
  "availableFor": "Available for"
};

const errors = {
  "title": "Template name is required.",
  "text": "Content is required.",
  "availableFor": "Please select at least one option."
};

const fieldsTabs = {
  "title": 0,
  "availableFor": 0,
  "content": 1
};

export const Content: FC<ContentProps> = React.memo(function Content(props) {
  const {
    expand,
    setExpand,
    template,
    onDuplicate,
    onRemove,
    hasPermissions
  } = props;
  const isFullScreen = useReactiveVar(fullScreenVar);
  const { $viewer, $object } = useSource();
  const { success } = useAlert();
  const { phoneMessageTemplateId, objectId: userObjectId, ...rest } = useParams();
  const navigate = useNavigate();
  const isLO = useMemo(() => $viewer.role == "loan_officer", [$viewer]);
  const initialValues = useMemo(() => {
    if (phoneMessageTemplateId == "create") {
      return {
        type: "sms",
        title: "New template",
        text: "",
        applyTo: [],
        availableFor: []
      };
    }

    return { ...template, user: isLO ? $viewer.objectId : null };
  }, [template, phoneMessageTemplateId]);

  const [preview, setPreview] = useState(!hasPermissions);
  const [create] = useMutation<CreatePhoneMessageTemplate, CreatePhoneMessageTemplateVariables>(CREATE_PHONE_MESSAGE_TEMPLATE);
  const [update] = useMutation<UpdatePhoneMessageTemplate, UpdatePhoneMessageTemplateVariables>(UPDATE_PHONE_MESSAGE_TEMPLATE, {
    refetchQueries: ["GetPhoneMessageTemplates"]
  });

  const handleSubmit = async (values, form) => {
    const previewFields = props.replaceableFieldsSources.map(field => toFirstLower(field));
    const formValues: any = omit(values, [...previewFields, "user", "contact", "id", "objectIcon", "objectId", "__typename", "ACL", "scope"]);
    const errors = Object.keys(requiredFields).reduce((previousValue, currentValue) => {
      if (!values[ currentValue ] || (Array.isArray(values[ currentValue ]) && values[ currentValue ].length == 0)) {
        previousValue[ currentValue ] = errors[ currentValue ];
      }

      return previousValue;
    }, {});

    if (Object.keys(errors).length) {
      return errors;
    }
    const data = {
      ...formValues,
      text: formValues.text.replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, "").replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, "").replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, "").replace(/<\/\s*(?:p|div)>/ig, "\n").replace(/<br[^>]*\/?>/ig, "\n").replace(/<[^>]*>/ig, "")
    };

    if (values.id) {
      if (data.owner) {
        data[ "owner" ] = {
          link: toNodeId({ className: formValues.owner.__typename, objectId: formValues.owner.objectId })
        };
      } else {
        delete data.owner;
      }

      await update({
        variables: {
          input: {
            id: values.id,
            fields: data
          }
        }
      });
      setTimeout(() => {
        form.reset(values);
      }, 0);
      success("Template successfully updated.");
    } else {
      if ($object.className !== "Settings") {
        data[ "owner" ] = {
          link: $viewer.id
        };
      }

      const { data: { createPhoneMessageTemplate: { phoneMessageTemplate: { objectId, type } } = {} } = {} } = await create({
        variables: {
          input: {
            fields: data
          }
        }
      });
      // todo redirect to result.data.createEmailTemplate.emailTemplate.objectId
      success("Template successfully created.");
      setTimeout(() => {
        form.reset(values);
      }, 0);
      setTimeout(() => {
        if ($object.className == "Settings") {
          navigate(`/settings/templates/${objectId}`);
        } else {
          navigate(`/user/${userObjectId}/templates/${objectId}`);
        }
      }, 500);
    }
  };

  const handleFullScreen = () => {
    const isFullScreen = fullScreenVar();
    fullScreenVar(!isFullScreen);
  };

  const checkCanAction = (values) => {
    return !!values.text?.trim() && !!(values?.title?.trim()) && values.applyTo?.length && values.availableFor?.length;
  };

  const canUpdate = ($object?.emailTemplateEditorPermissions?.[ template?.type ] || $object.className == "Settings" || $object.id === $viewer.id);
  return <Container style={{ overflowX: "auto" }} key={rest[ "*" ] || template?.id}>
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...defaultMutators,
        setFieldState: (args, state, tools) => {
          const [error, field] = args;
          const formField = state.fields[ field ];
          if (formField) {
            if (error) {
              formField.data = { ...field.data, error };
            } else {
              formField.data = {};
            }
          }
        }
      }}
      fluid
      className={"mail-template-form"}>
      <PhoneMessageTemplateContext.Provider value={{
        replaceableFieldsSources: props.replaceableFieldsSources,
        hasPermissions,
        preview
      }}>
        <>
          <Header>
            <Toolbar
              childrenRenderMode={"clone"}
              spacing={16}
              size={expand ? "md" : "sm"}
              style={{
                padding: `0  16px 0 ${expand ? "16px" : "8px"}`,
                height: "56px",
                verticalAlign: "center"
              }}>
              {
                !expand &&
                <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
              }
              <FormSpy subscription={{ values: true }}>
                {
                  ({ values }) => {
                    return (
                      (template?.id && hasPermissions) ?
                        <ToggleDropdown
                          onClick={(event) => event.stopPropagation()}
                          toggleRenderer={
                            <Button
                              size={"xs"}
                              appearance={"subtle"}
                              endIcon={<Icon type="keyboard_arrow_down"/>}>
                              {values?.title}
                            </Button>
                          }
                          placement={"autoVerticalEnd"}>
                          {
                            canUpdate &&
                            <ToggleDropdown.Item
                              eventKey={3}
                              onSelect={(event) => onDuplicate({ ...template, ...values })}>
                              <Icon type="content_copy"/>
                              Duplicate
                            </ToggleDropdown.Item>
                          }
                          <ToggleDropdown.Item
                            eventKey={3}
                            onSelect={(event) => onRemove(template.id)}>
                            <Icon type="delete"/>
                            Remove
                          </ToggleDropdown.Item>
                        </ToggleDropdown> :
                        <Button
                          disabled
                          size={"xs"}
                          appearance={"subtle"}>
                          {values?.title}
                        </Button>
                    );
                  }
                }
              </FormSpy>
              <div style={{ flexGrow: 1 }}/>
              {
                hasPermissions &&
                <FormSpy subscription={{ values: true }}>
                  {
                    ({ values }) => {
                      const canAction = checkCanAction(values);
                      return <Button size={"xs"} type={"submit"} disabled={!canAction}>
                        {
                          canAction ?
                            (phoneMessageTemplateId == "create" ? "CREATE" : "SAVE") + " TEMPLATE" :
                            <Tooltip title={"Please complete all information necessary for template creation."}
                                     alignment={Alignment.Top}>
                              <p> {phoneMessageTemplateId == "create" ? "CREATE" : "SAVE"} TEMPLATE</p>
                            </Tooltip>
                        }
                      </Button>;
                    }
                  }
                </FormSpy>
              }
              <IconButton
                onClick={handleFullScreen}
                icon={<Icon type={isFullScreen ? "close_fullscreen" : "open_in_full"}/>}
                style={{ color: "var(--rc-accent-03-primary)" }}
                appearance={"subtle"}/>
            </Toolbar>
          </Header>
          <TemplateContent
            setPreview={setPreview}/>
        </>
      </PhoneMessageTemplateContext.Provider>
    </Form>
  </Container>;
});

const TemplateContent = ({ setPreview }) => {
  const { hasPermissions, preview } = useContext(PhoneMessageTemplateContext);
  const [tab, setTab] = useState(0);
  const [replacementParams, setReplacementParams] = useState([]);

  const handleApplyToChange = (value) => {
    let classes = ["User", "Settings"];
    if (value) {
      classes = [...classes, ...value];
    }
    const params = getReplacementParams(classes.map(v => toFirstUpper(v)), "sms");
    setReplacementParams(params);
  };

  return <RCContent style={{ overflow: "overlay", flex: 1 }}>
    <DirtyDialogController/>
    <FormSpy
      subscription={{ submitErrors: true }}
      onChange={({ submitErrors }) => {
        const invalidField = submitErrors && fieldsTabs[ Object.keys(submitErrors)[ 0 ] ];
        if (invalidField != null) {
          setTimeout(() => {
            setTab(invalidField);
          }, 0);//todo workaround
        }
      }}/>
    <OnChange
      name={"applyTo"}
      children={handleApplyToChange}/>
    <FormSpy
      subscription={{ initialValues: true }}
      onChange={({ initialValues }) => {
        setTimeout(() => {
          handleApplyToChange(initialValues.applyTo);
        }, 0);//todo workaround
      }}/>
    <TabContainer gap={11} outerState={[tab, setTab]} className={"mail-template-tab-container"}>
      <TabPanelContainer>
        {
          hasPermissions &&
          <TabPanel>
            <PhoneMessageTemplateDetails/>
          </TabPanel>
        }
        <TabPanel>
          <PhoneMessageTemplateEditor replacements={replacementParams}/>
        </TabPanel>
      </TabPanelContainer>
      <TabsSwitcher itemsWidth={145}>
        {
          hasPermissions &&
          <TabItem active={true}>
            Template details
          </TabItem>
        }
        <TabItem active={false}>
          Content
        </TabItem>
      </TabsSwitcher>
      {
        hasPermissions &&
        <Stack spacing={12} className={"mail-template-preview-data"}>
          <Typography variant={"base14"}>
            Preview with data
          </Typography>
          <ToggleSwitch checked={preview} onChange={setPreview}/>
        </Stack>
      }
      <HorizontalDivider className={"mail-template-controller-divider"}/>
    </TabContainer>
  </RCContent>;
};

export const PhoneMessageTemplateContext = createContext<{
  hasPermissions: boolean,
  preview: any,
  replaceableFieldsSources: string[]
}>(null);
