import { useContext }                        from "react";
import { useEffect }                         from "react";
import { useCallback }                       from "react";
import { useMemo }                           from "react";
import { useState }                          from "react";
import { useRef }                            from "react";
import { DateTime }                          from "luxon";
import { useSubscription }                   from "@apollo/client";
import { useQuery }                          from "@apollo/client";
import { useMutation }                       from "@apollo/client";
import { FormApi }                           from "@relcu/final-form";
import { omit }                              from "@relcu/ui";
import { toFirstLower }                      from "@relcu/ui";
import { useThrottleCallback }               from "@relcu/ui";
import { isEmail }                           from "@relcu/ui";
import { useAlert }                          from "@relcu/ui";
import { useSource }                         from "@relcu/ui";
import { GetMailBoxesVariables }             from "../../../../../../graph/__types__/GetMailBoxes";
import { GetMailBoxes }                      from "../../../../../../graph/__types__/GetMailBoxes";
import { SubscribeMailBoxesDeleteVariables } from "../../../../../../graph/__types__/SubscribeMailBoxesDelete";
import { SubscribeMailBoxesDelete }          from "../../../../../../graph/__types__/SubscribeMailBoxesDelete";
import { GET_MAILBOXES }                     from "../../../../../../graph/operations.graphql";
import { SUBSCRIBE_MAILBOXES_DELETE }        from "../../../../../../graph/operations.graphql";
import { layoutVar }                         from "../../../../../../reactiveVars";
import { emailDraftsVar }                    from "../../../../../../reactiveVars";
import { mailInputVar }                      from "../../../../../../reactiveVars";
import { SubscriptionEvent }                 from "../../../../../../types/graphql-global-types";
import { formatPhoneNumber }                 from "../../../../../../utils/helpers";
import { getDefaultPhoneNumberForCall }      from "../../../../../../utils/helpers";
import { prepareMessageText }                from "../../../../../../utils/mailUtils";
import { applySignature }                    from "../../../../../../utils/mailUtils";
import { getHumanized }                      from "../../../../../../utils/schemaUtils";
import { GetLoanEstimateSentVariables }      from "../../../../../__types__/GetLoanEstimateSent";
import { GetLoanEstimateSent }               from "../../../../../__types__/GetLoanEstimateSent";
import { GetLoanProposalSentVariables }      from "../../../../../__types__/GetLoanProposalSent";
import { GetLoanProposalSent }               from "../../../../../__types__/GetLoanProposalSent";
import { SaveDraftMailVariables }            from "../../../../../__types__/SaveDraftMail";
import { SaveDraftMail }                     from "../../../../../__types__/SaveDraftMail";
import { SendDraftMailVariables }            from "../../../../../__types__/SendDraftMail";
import { SendDraftMail }                     from "../../../../../__types__/SendDraftMail";
import { UpdateLoanEstimateSentVariables }   from "../../../../../__types__/UpdateLoanEstimateSent";
import { UpdateLoanEstimateSent }            from "../../../../../__types__/UpdateLoanEstimateSent";
import { UpdateLoanProposalVariables }       from "../../../../../__types__/UpdateLoanProposal";
import { UpdateLoanProposal }                from "../../../../../__types__/UpdateLoanProposal";
import { usePermissions }  from "../../../../../AccessControl";
import { MailRendererRef } from "../../../../../ContentVisualizer";
import { SEND_DRAFT_MAIL } from "../../../../../operations.graphql";
import { SAVE_DRAFT_MAIL }                   from "../../../../../operations.graphql";
import { DELETE_DRAFT_MAIL }                 from "../../../../../operations.graphql";
import { GET_LOAN_ESTIMATE_SENT }            from "../../../../../operations.graphql";
import { GET_LOAN_PROPOSAL_SENT }            from "../../../../../operations.graphql";
import { UPDATE_LOAN_ESTIMATE_SENT }         from "../../../../../operations.graphql";
import { UPDATE_LOAN_PROPOSAL }              from "../../../../../operations.graphql";
import { useMailboxes }                      from "../../../../../useMailboxes";
import { useJqlLazyQuery }                   from "../../../../Jql";
import { ContentContext }                    from "../Content";
import { ComposeProps }                      from "./Compose";

const scopes = {
  "Lead": [
    "id",
    "objectId",
    "navigate",
    "members"
  ],
  "Rest": [
    "id",
    "objectId",
    "objectName",
    "objectIcon",
    "phones",
    "emails"
  ]
};

const filterNeedKeys = (arr, need: string[]) => {
  const result = [];

  need.forEach(n => {
    const res = arr?.find(a => {
      if (typeof a == "string") {
        return n == a;
      } else {
        return n == Object.keys(a)[ 0 ];
      }
    });

    if (res) {
      result.push(res);
    }

  });

  return result;
};

export function useCompose(props: ComposeProps) {
  const { draftReplyToMessage } = props;
  const { $object: node, $viewer: user, $settings } = useSource();
  const { mailBoxes: mBoxes } = useMailboxes();
  const contentRendererRef = useRef<MailRendererRef>();
  const sent = useRef<boolean>(false);
  const signatureRendererRef = useRef<MailRendererRef>();
  const [renderedSubject, setRenderedSubject] = useState<string>();
  const { conversation } = useContext(ContentContext);
  const layout = layoutVar();
  const fields = filterNeedKeys(layout[ conversation.scope?.__typename ]?.jql?.query?.get?.fields, scopes[ conversation.scope?.__typename ] ?? scopes[ "Rest" ]);
  const scopeClassName = conversation.scope?.__typename ?? "Lead";
  const canAttachFlyer = node.__typename != "User" && user.flyerPermissions?.send;
  const [getScope] = useJqlLazyQuery({
    operation: toFirstLower(scopeClassName),
    variables: {
      id: {
        name: "id",
        type: `ID!`
      }
    },
    fields: fields
  }, {
    operationName: `Get${scopeClassName}ById`
  });
  const isForward = useMemo(() => {
    if (props.initialValues?.subject) {
      return props.initialValues.subject.startsWith("Fwd:");
    }
    return false;
  }, [draftReplyToMessage?.subject]);
  const [memberContacts, setMemberContacts] = useState([]);
  const { canUpdate } = usePermissions(node);
  const { success, error } = useAlert();
  const loanEstimateId = useMemo(() => props.initialValues?.loanEstimateId, [props.initialValues?.loanEstimateId]);
  const proposalId = useMemo(() => props.initialValues?.loanProposalId, [props.initialValues?.loanProposalId]);
  const templateFilters = useMemo(() => {
    const filters: Record<string, any> = {
      enabled: { equalTo: true },
      availableFor: { equalTo: "manual" },
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: user.id
              }
            }
          }
        }
      ]
    };
    if (!proposalId && !loanEstimateId) {
      filters.AND = [
        { applyTo: { notEqualTo: "loanProposal" } }
      ];
    }

    if (node.__typename != "User") {
      if (filters.AND) {
        filters[ "AND" ].push({
          applyTo: { equalTo: toFirstLower(node.__typename) }
        });
      } else {
        filters.applyTo = { equalTo: toFirstLower(node.__typename) };
      }
    }
    return filters;
  }, [proposalId, loanEstimateId, node.__typename, user.id]);
  const variables = useMemo(() => ({
    where: {
      OR: [
        { common: { equalTo: true } },
        { assignedTo: { have: { link: user.id } } }
      ]
    }
  }), [user.id]);
  const [updateProposal] = useMutation<UpdateLoanProposal, UpdateLoanProposalVariables>(UPDATE_LOAN_PROPOSAL);
  const [updateLoanEstimateSent] = useMutation<UpdateLoanEstimateSent, UpdateLoanEstimateSentVariables>(UPDATE_LOAN_ESTIMATE_SENT);
  const [saveDraftMail, { loading: saveDraftLoading }] = useMutation<SaveDraftMail, SaveDraftMailVariables>(SAVE_DRAFT_MAIL);
  const [sendDraft, { loading: sendingMail }] = useMutation<SendDraftMail, SendDraftMailVariables>(SEND_DRAFT_MAIL);
  const [deleteDraftMail] = useMutation(DELETE_DRAFT_MAIL);
  const mailBoxes = useMemo(() => mBoxes.map(({ node }) => ({ ...node, disabled: node.disabled || node.domain.disabled })).sort((a, b) => {
    if (a?.assignedTo?.objectId == user.objectId || a?.assignedTo) {
      return -1;
    }
    return 1;
  }), [mBoxes, user.objectId]);
  const proposalSent = useQuery<GetLoanProposalSent, GetLoanProposalSentVariables>(GET_LOAN_PROPOSAL_SENT, {
    skip: !proposalId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      id: proposalId
    }
  });
  const loanEstimateSent = useQuery<GetLoanEstimateSent, GetLoanEstimateSentVariables>(GET_LOAN_ESTIMATE_SENT, {
    skip: !loanEstimateId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      id: loanEstimateId
    }
  });
  useEffect(() => {
    const mContacts = [];
    if (node.__typename == "Lead") {
      node.members.forEach((m) => {
          const phone = getDefaultPhoneNumberForCall(m.contact?.phones)?.number;
          m.contact.emails.forEach(e => {
            if (!e.optOut) {
              mContacts.push({
                contactId: m.contact.objectId,
                type: e.type,
                contactType: getHumanized("LeadMember", "type", m.type),
                optOut: e.optOut,
                value: e.address,
                address: e.address,
                objectName: m.contact.objectName,
                objectIcon: m.contact.objectIcon,
                disabled: e.optOut,
                phone: formatPhoneNumber(phone ?? "")
              });
            }
          });
        }
      );
      setMemberContacts(mContacts);
    } else {
      if (conversation.scope) {
        getScope({ variables: { id: conversation.scope.objectId } }).then(({ data = {} }) => {
          const scope = data[ toFirstLower(conversation.scope.__typename) ];
          if (conversation.scope.__typename == "Lead") {
            scope.members.forEach((m) => {
                const phone = getDefaultPhoneNumberForCall(m.contact?.phones)?.number;
                m.contact.emails.forEach(e => {
                  if (!e.optOut) {
                    mContacts.push({
                      contactId: m.contact.objectId,
                      type: e.type,
                      contactType: getHumanized("LeadMember", "type", m.type),
                      optOut: e.optOut,
                      value: e.address,
                      address: e.address,
                      objectName: m.contact.objectName,
                      objectIcon: m.contact.objectIcon,
                      disabled: e.optOut,
                      phone: formatPhoneNumber(phone ?? "")
                    });
                  }
                });
              }
            );
          } else {
            const phone = getDefaultPhoneNumberForCall(scope.phones)?.number;
            scope.emails?.forEach((e) => {
              if (!e.optOut) {
                mContacts.push({
                  contactId: scope.objectId,
                  type: e.type,
                  optOut: e.optOut,
                  value: e.address,
                  address: e.address,
                  objectName: scope.objectName,
                  objectIcon: scope.objectIcon,
                  disabled: e.optOut,
                  // contactType: getHumanized("LeadMember", "type", node.type),
                  phone: formatPhoneNumber(phone ?? "")
                });
              }
            });
          }

          setMemberContacts(mContacts);
        });
      } else {
        const phone = getDefaultPhoneNumberForCall(node.phones)?.number;
        node.emails?.forEach((e) => {
          if (!e.optOut) {
            mContacts.push({
              contactId: node.objectId,
              type: e.type,
              optOut: e.optOut,
              value: e.address,
              address: e.address,
              objectName: node.objectName,
              objectIcon: node.objectIcon,
              disabled: e.optOut,
              // contactType: getHumanized("LeadMember", "type", node.type),
              phone: formatPhoneNumber(phone ?? "")
            });
          }
        });
        setMemberContacts(mContacts);
      }
    }
  }, [node.members, node.emails, conversation.scope]);

  const to = useMemo(() => {
    const to = [...memberContacts];
    if (draftReplyToMessage) {
      const parties = draftReplyToMessage.parties.filter(p => {
        return p.party.objectId != user.objectId;
      });

      parties.forEach(p => {
        if (!to.find(t => t.value == p.address)) {
          if (p.party.__typename == "Contact") {
            const email = p.party.emails.find(email => email.address === p.address);
            if (email && !email.optOut) {
              to.push({
                type: email.type,
                optOut: email.optOut,
                value: email.address,
                address: email.address,
                objectName: p.party.objectName,
                objectIcon: p.party.objectIcon,
                disabled: email.optOut
              });
            }
          } else if (p.party.__typename == "User") {
            to.push({
              type: null,
              optOut: false,
              value: p.address,
              address: p.address,
              objectName: p.party.objectName,
              objectIcon: p.party.objectIcon,
              disabled: false
            });
          }
        }
      });
    }
    return to;
  }, [memberContacts, draftReplyToMessage]);
  const initials = useMemo(() => props.initialValues, [props.initialValues]);
  const initialValues = useMemo(() => {
    const enabledMailBoxes = mailBoxes.filter(m => !m.disabled && !m.domain.disabled);
    if (initials) {
      return {
        ...initials,
        attachments: initials.attachments.map((a) => (a.attachment ? { attachment: { ...a.attachment } } : { attachment: { ...a, ...a.file } })),
        from: enabledMailBoxes.find(mBox => mBox.address == initials.from)?.address ?? ""
      };
    }

    let from = enabledMailBoxes.find(m => m?.assignedTo?.objectId == user.objectId || m.common)?.address;
    const initial: any = {
      subject: "New email subject",
      attachments: [],
      html: "",
      to: [],
      cc: [],
      ...draftReplyToMessage,
      from
    };
    if (draftReplyToMessage && !isForward) {
      let partyMe;
      let otherParty = [];
      draftReplyToMessage.parties.forEach((p) => {
        if (p.party.objectId == user.objectId) {
          partyMe = p;
        } else {
          otherParty.push(p);
        }
      });
      const partyMailBox = enabledMailBoxes.find(m => partyMe && m.address == partyMe.address)?.address;
      if (partyMailBox) {
        initial.from = partyMailBox;
      } else {
        const assignedBox = enabledMailBoxes.find(m => m?.assignedTo?.objectId == user.objectId)?.address;
        if (assignedBox) {
          initial.from = assignedBox;
        }
      }
      initial.to = otherParty.filter(p => p.type !== "cc");
    } else {
      if (node.__typename == "Lead" && !isForward) {
        const primaryContact = node?.members.find(m => m.isPrimary);
        let email = primaryContact?.contact.emails.find(e => !e.optOut);
        if (email) {
          initial.to = [{
            ...email,
            objectName: primaryContact?.contact.objectName,
            objectIcon: primaryContact?.contact.objectIcon,
            contactType: getHumanized("LeadMember", "type", primaryContact?.contact.type)
          }];
        } else if (email = to.find(e => e.type && !e.optOut)) {
          initial.to = [{
            ...email,
            objectName: primaryContact?.contact.objectName,
            objectIcon: primaryContact?.contact.objectIcon,
            contactType: getHumanized("LeadMember", "type", primaryContact?.contact.type)
          }];
        }
      } else if (node.__typename == "Contact" && !isForward) {
        let email = node.emails.find(e => e.isPrimary && !e.optOut);
        if (email) {
          initial.to = [{
            ...email,
            objectName: node.objectName,
            objectIcon: node.objectIcon
          }];
        } else if (email = to.find(e => e.type && !e.optOut)) {
          initial.to = [{
            ...email,
            objectName: node.objectName,
            objectIcon: node.objectIcon
          }];
        }
      }
    }

    return initial;
  }, [draftReplyToMessage, user, to, mailBoxes, isForward, initials /*defaultTemplate*/]);

  const prepareMessageReplyHtml = (html) => {
    const participant = draftReplyToMessage.parties.find(p => p.type === "sender");
    return (
      `${html}
          <div class="gmail_quote relcu-quote">
            <br>
            On ${DateTime.fromISO(draftReplyToMessage.date).toFormat("MM d yyyy, h:mm a")}, ${participant.party.objectName} &lt;${participant.address}&gt; wrote:
            <br>
            <blockquote class="gmail_quote"
              style="margin:0 0 0 .8ex;border-left:1px #ccc solid;padding-left:1ex;">
              ${draftReplyToMessage.html}
            </blockquote>
          </div>`
    );
  };
  const prepareMessageFwdHtml = (html) => {
    const sender = draftReplyToMessage.parties.find(p => p.type === "sender");
    const receiver = draftReplyToMessage.parties.find(p => p.type === "receiver");
    const fields = [
      `From: ${sender.party.objectName} &lt;${sender.address}&gt;`,
      `Date: ${DateTime.fromISO(draftReplyToMessage.date).toFormat("MM d yyyy, h:mm a")}`,
      `Subject: ${draftReplyToMessage.subject}`
    ];
    if (receiver) {
      fields.push(`To: ${receiver.party.objectName} &lt;${receiver.address}&gt;`);
    }
    return `${html}
    <br><br>
      <div class="gmail_quote">
        <br>
        ---------- Forwarded message ---------
        <br><br>
        ${fields.join("<br>")}
        <br><br>
        ${draftReplyToMessage.html}
      </div>
    `;
  };

  const emailSignature = useMemo(() => {
    if ($settings?.emailSignature && $settings?.emailSignature[ 0 ]?.signature) {
      return $settings?.emailSignature[ 0 ]?.signature;
    }
  }, [$settings]);
  const previewHtml = useMemo(() => {
    if (draftReplyToMessage && !isForward) {
      return prepareMessageReplyHtml("");
    }
    if (draftReplyToMessage && isForward) {
      return prepareMessageFwdHtml("");
    }
  }, [draftReplyToMessage, isForward]);

  const handleDelete = useCallback((selected, field: string, form: FormApi<any>) => {
    const values = form.getFieldState(field).value;
    form.change(field, values.filter((v) => v != selected));
  }, []);
  const editorProps = useMemo(() => {
    const editorProps = {};
    if (isForward) {
      return {
        editorStyle: {},
        editorClassName: "mail__editor-forward",
        wrapperStyle: {
          height: undefined
        }
      };
    }

    return editorProps;
  }, [isForward]);
  const warning = useMemo(() => {
    if (to.length === 0) {
      if (node.__typename == "Lead") {
        return "None of the loan contacts have an email address, please update the contact details.";
      } else if (node.__typename == "Contact" || (node.__typename == "User" && conversation.scope)) {
        return "The contact does not have an email address, please update the contact details.";
      }
    }
  }, [to]);

  const saveMail = useThrottleCallback((data) => {
    if (sent.current) {
      return;
    }
    return saveDraftMail({
      variables: {
        objectId: props.draftId,
        input: {
          ...omit(data, ["flyer"]),
          attachments: data.attachments.filter(({ attachment }) => attachment.url).map(({ attachment }) => ({
            mimeType: attachment.mimeType,
            name: attachment.name,
            objectId: attachment.objectId,
            url: attachment.url
          })),
          template: data?.template,
          to: data.to.map(t => t.address),
          cc: data.cc.map(t => t.address)
        }
      }
    });
  }, 2000, false);

  const handleFormChange = useCallback((values) => {
    saveMail(values);
  }, []);

  const handleAttachmentChange = useCallback((value, previous, values) => {
    const attachments = value?.filter(({ attachment }) => {
      return attachment.url && !previous.some(({ attachment: prevAttachment }) => prevAttachment.url == attachment?.url);
    });
    if (!!attachments?.length || previous.length > value.length) {
      saveDraftMail({
        variables: {
          objectId: props.draftId,
          input: {
            from: values.from,
            to: values.to.map(t => t.address),
            subject: values.subject,
            template: values?.template,
            attachments: value.filter(({ attachment }) => attachment.url).map(({ attachment }) => ({
              mimeType: attachment.mimeType,
              name: attachment.name,
              objectId: attachment.objectId,
              url: attachment.url
            }))
          }
        }
      });
    }
  }, [props.draftId]);

  const handleDraftDelete = async (id) => {
    const { data } = await deleteDraftMail({
      variables: {
        objectId: id
      }
    });

    if (data.deleteDraftEmail.objectId) {
      const drafts = emailDraftsVar();
      const { [ id ]: removedValue, ...draftsWithout } = drafts;
      emailDraftsVar(draftsWithout);
      mailInputVar(null);
      props.onDelete?.(id);
    }
  };

  const handleSubmit = async ({ template, ...values }) => {
    sent.current = true;
    const memberTo = memberContacts;
    try {
      if (!values.from) {
        return { from: ` ` };
      }

      if (!values.subject?.trim()) {
        return { subject: `Subject is required.` };
      }
      const filtered = values.to?.filter(({ address }) => memberTo.find((t) => t.value === address)) || [];
      if (!filtered.length && ((node.__typename == "User" && conversation.scope) || node.__typename != "User")) {
        return { to: `` };
      }
      const attachments = values.attachments.map(({ attachment }) => ({
        mimeType: attachment.mimeType,
        name: attachment.name,
        objectId: attachment.objectId,
        url: attachment.url
      }));
      let html = values.html;
      let text;
      if (template && contentRendererRef?.current) {
        html = contentRendererRef.current.html;
        text = contentRendererRef.current.text;
      }
      if (signatureRendererRef.current) {
        html = applySignature(html, signatureRendererRef.current.html);
      }
      if (draftReplyToMessage && !isForward) {
        html = prepareMessageReplyHtml(html);
      }
      if (draftReplyToMessage && isForward) {
        html = prepareMessageFwdHtml(html);
      }
      if (values.proposalId) {
        delete values.proposalId;
      }
      const to = values.to.map(t => t.address);
      const cc = values.cc.map(t => t.address);
      const variables = {
        objectId: props.draftId,
        input: {
          ...values,
          template: template,
          to,
          cc,
          from: values.from,
          html: html,
          text: text || prepareMessageText(html),
          scope: (node.__typename != "Contact" && node.__typename != "User") ? node.id : null,
          attachments
        }
      };

      const result = await sendDraft({
        variables
      });
      if (result?.data?.sendDraftEmail?.id) {
        success("Email sent.");
        if (proposalId) {
          const { data: { loanProposal: { sent } } } = proposalSent;
          await updateProposal({
            variables: {
              id: proposalId,
              sent: sent ? sent + 1 : 1
            }
          });
        }
        if (loanEstimateId) {
          const { data: { loanEstimate: { sent } } } = loanEstimateSent;
          await updateLoanEstimateSent({
            variables: {
              id: loanEstimateId,
              sent: sent ? sent + 1 : 1
            }
          });
        }
      }

      mailInputVar(null);
      emailDraftsVar({});
      // selectedMailVar({});
      props.onSubmit?.({ template, ...values });
    } catch (e) {
      console.error(e);
      error(e?.message ?? "Something went wrong");
    }
  };
  const onRender = useCallback(({ subject }) => {
    setRenderedSubject(subject);
  }, [setRenderedSubject]);
  useSubscription<SubscribeMailBoxesDelete, SubscribeMailBoxesDeleteVariables>(SUBSCRIBE_MAILBOXES_DELETE, {
    variables,
    onData({ client, data: { data: { mailboxes: { event, node } } } }) {
      switch (event) {
        case SubscriptionEvent.DELETE:
          const data = client.readQuery<GetMailBoxes, GetMailBoxesVariables>({ query: GET_MAILBOXES, variables });
          client.writeQuery<GetMailBoxes, GetMailBoxesVariables>({
            query: GET_MAILBOXES,
            variables,
            data: {
              ...data,
              mailboxes: {
                ...data.mailboxes,
                edges: data.mailboxes.edges.filter(edge => edge.node.id !== node.id)
              }
            }
          });
      }
    }
  });

  return {
    to,
    node,
    warning,
    handleDelete,
    canAttachFlyer,
    templateFilters,
    saveDraftLoading,
    validateTo: (value, label) => {
      if (!value?.length && to.length && label != "CC") {
        return " ";
      }
      if (value?.length && to.length) {
        const invalidEmails = value.filter(v => !isEmail(v));
        const messageMapping = {
          "Lead": "At least one of the recipients must be a loan contact",
          "Contact": "At least one of the recipients must be the contact"
        };
        if (invalidEmails.length) {
          return "Invalid email address: " + invalidEmails.join(", ");
        }
        const contactEmails = to.map(t => t.value);
        const hasAnyContactSelected = value.some(v => contactEmails.includes(v));

        if (!hasAnyContactSelected && label === "To") {
          return messageMapping[ node.__typename ];
        }

        if (to.some(({ value: email, optOut }) => value.includes(email) && optOut)) {
          const { value: optOutEmail = "" } = to.find(item => value.includes(item.value) && item.optOut);
          return `${optOutEmail} is opt out. Please exclude from "${label}".`;
        }
      }
    },
    mailBoxes,
    initialValues,
    emailSignature,
    user,
    proposalId,
    loanEstimateId,
    editorProps,
    canUpdate,
    previewHtml,
    sendingMail,
    handleSubmit,
    handleFormChange,
    handleDraftDelete,
    handleAttachmentChange,
    signatureSettings: $settings?.emailSignature?.[ 0 ],
    renderedSubject,
    onRender,
    contentRendererRef,
    signatureRendererRef,
    memberContacts
  };
}
