import { useRef }                 from "react";
import { useEffect, useCallback } from "react";
import { ConfirmDialog }          from "@relcu/ui";
import { modal }                  from "@relcu/ui";
import { useNavigate }            from "@relcu/react-router";

export interface NavigationConfirmProps {
  enabled?: boolean;
  title?: string;
  header?: string;
  subTitle?: string;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

export const isSameOrigin = (url): boolean => {
  try {
    return new URL(url).origin === window.location.origin;
  } catch {
    return true; // Relative URLs are same origin
  }
};

export const isSamePath = (url): boolean => {
  try {
    const currentUrl = new URL(window[ "navigation" ].currentEntry.url);
    return (
      url.pathname === currentUrl.pathname &&
      url.search === currentUrl.search &&
      url.hash === currentUrl.hash
    );
  } catch {
    return false;
  }
};

export function useNavigationConfirm({
  enabled = true,
  title = "Changes will not be saved",
  subTitle = "The changes you have made will be lost if you proceed.",
  header = "Are you sure you want to continue?",
  onConfirm,
  onCancel
}: NavigationConfirmProps = {}) {
  const navigatingUrl = useRef<string>(null);
  const navigate = useNavigate();

  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    if (!enabled) {
      return;
    }

    event.preventDefault();
    return (event.returnValue = "");
  }, [enabled]);

  const handleNavigation = useCallback(async (event) => {
    if (!enabled || navigatingUrl.current == event.destination.url) {
      return;
    }

    const targetUrl = new URL(event.destination.url);
    if (isSamePath(targetUrl)) {
      return;
    }

    if (!isSameOrigin(targetUrl)) {
      return;
    }

    event.preventDefault();

    try {
      const { destroy } = modal(ConfirmDialog, {
        title: header,
        subTitle: title,
        content: subTitle,
        label: "CONTINUE",
        onClose: () => {
          onCancel?.();
          destroy();
        },
        onConfirm: () => {
          onConfirm?.();
          destroy();
          if (!navigatingUrl.current || navigatingUrl.current != event.destination.url) {
            navigatingUrl.current = event.destination.url;
          }
          navigate(targetUrl.pathname);
        }
      });

      // const { destroy } = modal(DirtyDialog, {
      //   title,
      //   subTitle,
      //   header,
      //   onClose: () => {
      //     onCancel?.();
      //     destroy();
      //   },
      //   onConfirm: () => {
      //     onConfirm?.();
      //     destroy();
      //     if (!navigatingUrl.current) {
      //       navigatingUrl.current = event.destination.url;
      //     }
      //     navigate(targetUrl.pathname);
      //   }
      // });
    } catch (error) {
      console.error("Navigation confirmation error:", error);
    }
  }, [enabled, modal, confirm, onConfirm, onCancel]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    // Handle browser back/forward buttons and regular navigation
    window[ "navigation" ].addEventListener("navigate", handleNavigation);

    // Handle page refresh and tab close
    // window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window[ "navigation" ].removeEventListener("navigate", handleNavigation);
      // window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enabled, handleNavigation, handleBeforeUnload]);
}
