import React                     from "react";
import { FC }                    from "react";
import { Button }                from "@relcu/rc";
import GoogleSvgUrl              from "../../../assets/google.svg";
import { MailDomain }            from "../../__types__/MailDomain";
import { useGmailConnectButton } from "./useGmailConnectButton";

export const GmailLoginButton: FC<any> = React.memo(function GmailLoginButton(props) {
  const config = window.__CONFIG__.gmail;
  const { handleLogin } = useGmailConnectButton({
    ...props,
    domain: {
      objectId: config.domainId,
      provider: {
        type: "gmail",
        clientId: config.clientId,
        returnUrl: config.returnUrl
      }
    } as MailDomain
  });

  return (
    <Button
      appearance={"text"}
      disabled={props.disabled}
      style={{ justifyContent: "center" }}
      onClick={handleLogin}
      startIcon={<img src={GoogleSvgUrl} alt="Authorize in Google"/>}
    >
      LOGIN WITH GOOGLE
    </Button>
  );
});
