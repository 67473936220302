import React                        from "react";
import { FC }                       from "react";
import { useContext }               from "react";
import { useRef }                   from "react";
import { Field }                    from "@relcu/form";
import { Typography }               from "@relcu/rc";
import { Stack }                    from "@relcu/rc";
import { replacementParamDropdown } from "../../../../../Generation/PlainTextEditor/ReplacementParamDropdown";
import Editor                       from "../../../MailView/Editor/Editor";
import { MailTemplateContext }      from "../Content";
import { SectionHeader }            from "../SectionHeader";
import { TemplatePreview }          from "../TemplatePreview";
import { EditorProps }              from "./HtmlEditor";
import SunEditorCore                from "suneditor/src/lib/core";
import plugins                      from "suneditor/src/plugins";
import "./mail-editor.css";

export const PlainTextEditor: FC<EditorProps> = React.memo(function PlainTextEditor(props) {
  const { preview } = useContext(MailTemplateContext);
  const editor = useRef<SunEditorCore>();
  const getSunEditorInstance = (core: SunEditorCore) => {
    editor.current = core;
  };

  const replacementDropdown = replacementParamDropdown(props.replacements);

  return <Stack childrenRenderMode={"clone"} style={{ flex: 1 }} className={"plain-text-editor-container"}>
    {
      !preview &&
      <Stack direction={"column"} style={{ flex: "1 0 50%", alignSelf: "stretch" }} childrenRenderMode={"clone"}>
        <SectionHeader>
          <Typography variant={"base16"} lineHeight={"lh20"}>Editor</Typography>
        </SectionHeader>
        <Field name={`content`}>
          {({ meta }) => {
            return meta.data.error ?
              <Typography
                style={{ color: "var(--rc-status-03-error-primary)", padding: "10px 20px 10px 20px" }}>
                {meta.data.error}
              </Typography> :
              null;
          }}
        </Field>
        <Stack.Item style={{ background: "var(--rc-background-tint2)", alignSelf: "stretch", flex: 1 }}>
          <Field name={`content`}>
            {({ input: { value, onChange } }) => {
              return <Editor
                getSunEditorInstance={getSunEditorInstance}
                plugins={{ ...plugins, replacementDropdown }}
                value={value}
                onChange={onChange}
                buttonList={[
                  {
                    name: "replacementParams",
                    dataDisplay: "container",
                    title: "Replacement params",
                    buttonClass: "inset-replacement-button",
                    innerHTML: "<i class=\"rc-icon icon-rc_data_object rc-icon-fs20 rs-icon insert-button-icon\"></i> INSERT VARIABLE"
                  }
                ]}
              />;
            }}
          </Field>
        </Stack.Item>
      </Stack>
    }
    <TemplatePreview/>
  </Stack>;
});
