import { JsonViewProps }                from "@relcu/ui";
import { useState }                     from "react";
import { useEffect }                    from "react";
import { useMemo }                      from "react";
import { useMutation }                  from "@apollo/client";
import { useNavigate }                  from "@relcu/react-router";
import { useRelayPagination }           from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { SubscriptionEvent }            from "../../../../types/graphql-global-types";
import { getPhoneNumberForCall }        from "../../../../utils/helpers";
import { isOverdue }                    from "../../../../utils/helpers";
import { divideDataByDate }             from "../../../../utils/helpers";
import { checkDuplicateOf }             from "../../../../utils/helpers";
import { getObjectPath }                from "../../../../utils/layoutUtils";
import { getField }                     from "../../../../utils/schemaUtils";
import { UpdateTaskDoneVariables }      from "../../../__types__/UpdateTaskDone";
import { UpdateTaskDone }               from "../../../__types__/UpdateTaskDone";
import { UpdateTaskDueDateVariables }   from "../../../__types__/UpdateTaskDueDate";
import { UpdateTaskDueDate }            from "../../../__types__/UpdateTaskDueDate";
import { usePermissions }               from "../../../AccessControl";
import { UPDATE_TASK_DUE_DATE }         from "../../../operations.graphql";
import { UPDATE_TASK_DONE }             from "../../../operations.graphql";
import { useTask }                      from "../../../useTask";
import { useViewerPhoneLines }          from "../../../useViewerPhoneLines";
import { useJqlSubscription }           from "../../Jql";
import { useJqlQuery }                  from "../../Jql";
// import { GetTasksByDynamicWhere_tasks_edges_node } from "./__types__/GetTasksByDynamicWhere";
// import { GetTasksByDynamicWhere }                  from "./__types__/GetTasksByDynamicWhere";
// import { GetTasksByDynamicWhereVariables }         from "./__types__/GetTasksByDynamicWhere";
// import { SubscribeTasksByDynamicWhere }            from "./__types__/SubscribeTasksByDynamicWhere";
// import { SubscribeTasksByDynamicWhereVariables }   from "./__types__/SubscribeTasksByDynamicWhere";

// type ViewerTask = GetTasksByDynamicWhere_tasks_edges_node;
// export const SUBSCRIBE_TASK_BY_DYNAMIC_WHERE = gql`
//   subscription SubscribeTasksByDynamicWhere($where: TaskSubscriptionWhereInput) {
//     tasks(where: $where) {
//       event
//       node {
//         id
//         objectId
//       }
//     }
//   }
// `;

// export const GET_TASK_BY_DYNAMIC_WHERE = gql`
//   ${MEMBER_OWN_FIELDS_FRAGMENT}
//   query GetTasksByDynamicWhere($before:String,$after:String,$where: TaskWhereInput! $order: [TaskOrder!]) {
//     tasks(where: $where, order: $order,
//       first: 38,
//       before: $before,
//       after: $after
//     ) {
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//       edges {
//         cursor
//         node {
//           id
//           objectId
//           createdAt
//           subject {
//             id
//             objectName
//             objectId
//             members{
//               ...MemberOwnFields
//               contact{
//                 id
//                 objectId
//                 objectName
//                 firstName
//                 lastName
//                 phones{
//                   default
//                   isPrimary
//                   type
//                   number
//                   smsOptOut
//                   callOptOut
//                 }
//               }
//             }
//           }
//           assignedTo {
//             id
//             objectId
//             objectName
//             objectIcon
//           }
//           title
//           done
//           dueDate
//         }
//       }
//     }
//   }
//
// `;

export function useReminderList({ title, jql }: JsonViewProps) {
  const { $object } = useSource();
  const [active, setActive] = useState(null);
  const { canUpdate } = usePermissions($object);
  const taskApi = useTask();
  const navigate = useNavigate();
  const { states } = getField("Lead", "leadStatus");
  const { queryVariables, subscriptionVariables } = useMemo(() => createWhereInputVariables($object.id, $object.__typename, title), [$object]);
  const query = useJqlQuery(jql.query.find, {
    operationName: "GetTaskList",
    variables: queryVariables
  });
  const { fromNumbers, hasMicrophoneIssue } = useViewerPhoneLines();
  const [updateDone] = useMutation<UpdateTaskDone, UpdateTaskDoneVariables>(UPDATE_TASK_DONE, {
    update() {
      query.refetch().catch(console.error);
    }
  });
  const [updateDueDate] = useMutation<UpdateTaskDueDate, UpdateTaskDueDateVariables>(UPDATE_TASK_DUE_DATE, {
    update() {
      query.refetch().catch(console.error);
    }
  });

  useJqlSubscription(jql.subscription, {
    variables: subscriptionVariables,
    operationName: "SubscribeTaskList",
    onData({ data: { data: { tasks: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
        case SubscriptionEvent.UPDATE:
          query.refetch();//this is made because new tasks can be in the middle of list(not only on start or end)
      }
    }
  });
  const api = {
    duplicateOf: checkDuplicateOf($object),
    active,
    setActive,
    get isEmpty() {
      return !api.data.edges?.length;
    },
    get data() {
      const { data: { tasks: { edges = [], pageInfo = {} } = {} } = {} } = query;
      return { pageInfo, edges, dividedData: divideDataByDate(edges, "dueDate") };
    },
    get loaded() {
      return !!query.data;
    },
    get isNotContact() {
      const status = $object.leadStatus?.status;
      return states[ status ]?.not_contact;
    },
    isOverdue: isOverdue,
    isCallable(task) {
      const availableNumber = getPhoneNumberForCall(task.subject?.contact?.phones);
      return !!fromNumbers.length && !!availableNumber && ($object.__typename !== "Lead" || $object.__typename == "Lead" && canUpdate) && !hasMicrophoneIssue
    },
    reload() {
      if (api.loaded) {
        query.refetch().catch(console.error);
      }
    },
    async fetchMore() {
      if (api.data.pageInfo) {
        return query.fetchMore({
          query: jql.query.find,
          variables: {
            ...queryVariables,
            after: api.data.pageInfo.endCursor
          }
        });
      }
    },
    onCallBack: (e, task) => taskApi.onCallBack({ e, task, fromNumbers }),
    onSnooze: (task, number: string) => taskApi.onSnooze({ task, number, update: updateDueDate }),
    contextSchemaHolder: taskApi.contextSchemaHolder,
    onSendSms: taskApi.onSendSms,
    onEdit: taskApi.onEdit,
    async onMarkAsDone(e, task) {
      e.stopPropagation();
      await updateDone({
        variables: {
          id: task.id,
          done: true
        }
      });
    },
    isExpired(task) {
      return isOverdue(task) && !task.done;
    },
    status(task) {
      if (api.isOverdue(task) && !task.done) {
        return "Missed";
      }
      return task.done ? "Done" : "Pending";
    },
    onRowClick(lead) {
      navigate(getObjectPath(lead));
    },
    setIsActive(isActive: boolean, task) {
      if (isActive) {
        setActive(active == task.objectId ? null : task.objectId);
      } else {
        setActive(null);
      }
    }
  };
  const pagination = useRelayPagination({
    pageInfo: api.data.pageInfo,
    onLoadMore: api.fetchMore,
    fromTop: false
  });

  useEffect(api.reload, []);

  return {
    ...api,
    ...pagination
  };
}

const createWhereInputVariables = (id: string, typeName: string, title: string) => {
  const field = typeName == "User" ? "assignedTo" : "subject";
  const schemaField = getField("Task", field);
  let queryVariables;
  if (!Array.isArray(schemaField.targetClass)) {
    queryVariables = {
      where: {
        [ field ]: {
          have: {
            id: {
              equalTo: id
            }
          }
        }
      }
    };
  } else {
    queryVariables = {
      where: {
        [ field ]: {
          have: {
            link: id
          }
        }
      }
    };
  }

  queryVariables.order = "dueDate_DESC";
  const subscriptionVariables: any = {
    where: {
      [ field ]: {
        have: {
          link: id
        }
      }
    }
  };
  subscriptionVariables.order = "dueDate_DESC";

  if (title !== "Tasks") {
    queryVariables.where = {
      ...queryVariables.where,
      done: {
        equalTo: false
      }
    };
    queryVariables.order = "dueDate_ASC";
    subscriptionVariables.where = {
      ...subscriptionVariables.where,
      done: {
        equalTo: false
      }
    };
    subscriptionVariables.order = "dueDate_ASC";
  }

  return {
    queryVariables,
    subscriptionVariables
  };
};
