import React                from "react";
import { useCallback }      from "react";
import { useBoxProps }      from "../../..";
import { Label }            from "../../..";
import { Box }              from "../../..";
import { checkType }        from "../../..";
import { useConsumeField }  from "../BaseField";
import { BaseFieldClasses } from "../BaseField";

export const ChoiceReadField = React.memo(function ChoiceReadField() {
  const { input: { readPlaceholder, type, label, required, value, options, read, write, handleClear, ...choiceProps } } = useConsumeField();

  const checkValue = useCallback(() => {
    if(type === "Checkbox"){
      return options.find(o => typeof o == "object" ? o.value === value : o === value);
    }
    return options.find(o => typeof o == "object" ? o.value == value : o == value);
  }, [options, value]);

  return <Box container direction={"column"} gap={"XXXS"} className={BaseFieldClasses.ReadField} {...useBoxProps(choiceProps)}>
    <Label>{label}</Label>
    {
      checkType(checkValue(), "label") ||
      <span className={BaseFieldClasses.ReadFieldNotAvailable}>{readPlaceholder ?? "-"}</span>
    }
  </Box>;
});
export default ChoiceReadField;
