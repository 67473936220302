import { AssignmentActivity }     from "./components/AssignmentActivity";
import { BulkMailActivity }       from "./components/BulkMailActivity";
import { BulkSMSActivity }        from "./components/BulkSMSActivity";
import { DistributionActivity }   from "./components/DistributionActivity";
import { ExportActivity }         from "./components/ExportActivity";
import { GenericActivity }        from "./components/GenericActivity";
import { MailMessageActivity }    from "./components/MailMessageActivity";
import { ObjectMergeActivity }    from "./components/ObjectMergeActivity";
import { PhoneCallActivity }      from "./components/PhoneCallActivity";
import { PhoneMessageActivity }   from "./components/PhoneMessageActivity";
import { PrioritizationActivity } from "./components/PrioritizationActivity";
import { ProposalActivity }       from "./components/ProposalActivity";
import { StatusChangeActivity }   from "./components/StatusChangeActivity";
import { WorkflowActivity }       from "./components/WorkflowActivity";
import { BulkAssignmentActivity } from "./components/BulkAssignmentActivity";
import { BulkStatusChangeActivity } from "./components/BulkStatusChangeActivity";

export const activityItemsMap = {
  "AssignmentActivity": AssignmentActivity,
  "StatusChangeActivity": StatusChangeActivity,
  "PhoneMessageActivity": PhoneMessageActivity,
  "PhoneCallActivity": PhoneCallActivity,
  "DistributionActivity": DistributionActivity,
  "PrioritizationActivity": PrioritizationActivity,

  "ObjectMergeActivity": ObjectMergeActivity,
  "MailMessageActivity": MailMessageActivity,
  "GenericActivity": GenericActivity,
  "BulkMailActivity": BulkMailActivity,
  "BulkSMSActivity": BulkSMSActivity,
  "ProposalActivity": ProposalActivity,
  "WorkflowActivity": WorkflowActivity,
  "ExportActivity": ExportActivity,
  "BulkAssignmentActivity": BulkAssignmentActivity,
  "BulkStatusChangeActivity": BulkStatusChangeActivity
};

export const Filters = {
  Lead: [
    {
      label: "Lead status update",
      value: "StatusChangeActivity"
    },
    {
      label: "Distribution",
      value: "DistributionActivity"
    },
    {
      label: "Calls",
      value: "PhoneCallActivity"
    },
    {
      label: "SMS",
      value: "PhoneMessageActivity"
    },
    {
      label: "Email",
      value: "MailMessageActivity"
    },
    {
      label: "Assignment update",
      value: "AssignmentActivity"
    },
    {
      label: "Leave/enter focus view",
      value: "PrioritizationActivity"
    },
    {
      label: "Custom activities",
      value: "GenericActivity"
    },
    {
      label: "Generate proposal",
      value: "ProposalActivity"
    },
    {
      label: "Workflow",
      value: "WorkflowActivity"
    },
    {
      label: "Export",
      value: "ExportActivity"
    }
  ],
  User: [
    {
      label: "Lead status update",
      value: "StatusChangeActivity"
    },
    {
      label: "Distribution",
      value: "DistributionActivity"
    },
    {
      label: "Calls",
      value: "PhoneCallActivity"
    },
    {
      label: "SMS",
      value: "PhoneMessageActivity"
    },
    {
      label: "Bulk SMS",
      value: "BulkSMSActivity"
    },
    {
      label: "Email",
      value: "MailMessageActivity"
    },
    {
      label: "Bulk email",
      value: "BulkMailActivity"
    },
    {
      label: "Assignment update",
      value: "AssignmentActivity"
    },
    {
      label: "Contact merge",
      value: "ObjectMergeActivity"
    },
    {
      label: "Custom activities",
      value: "GenericActivity"
    },
    {
      label: "Generate proposal",
      value: "ProposalActivity"
    },
    {
      label: "Export",
      value: "ExportActivity"
    },
    {
      label: "Bulk Assignment",
      value: "BulkAssignmentActivity"
    },
    {
      label: "Bulk Status Change",
      value: "BulkStatusChangeActivity"
    }
  ],
  Contact: [
    {
      label: "Calls",
      value: "PhoneCallActivity"
    },
    {
      label: "SMS",
      value: "PhoneMessageActivity"
    },
    {
      label: "Email",
      value: "MailMessageActivity"
    },
    {
      label: "Contact merge",
      value: "ObjectMergeActivity"
    },
    {
      label: "Custom activities",
      value: "GenericActivity"
    }
  ],
  ReferralPartner: [
    {
      label: "Calls",
      value: "PhoneCallActivity"
    },
    {
      label: "SMS",
      value: "PhoneMessageActivity"
    },
    {
      label: "Email",
      value: "MailMessageActivity"
    },
    {
      label: "Assignment update",
      value: "AssignmentActivity"
    },
    {
      label: "Custom activities",
      value: "GenericActivity"
    },
    {
      label: "Workflow",
      value: "WorkflowActivity"
    }
  ]
};

export const Exclusions = {
  User: {
    loan_officer: ['StatusChangeActivity', 'BulkStatusChangeActivity']
  }
};
