import { useRef }           from "react";
import { useCallback }      from "react";
import { useApolloClient }  from "@apollo/client";
import { FormApi }          from "@relcu/form";
import { setIn }            from "@relcu/form";
import { FORM_ERROR }       from "@relcu/form";
import { SubmissionErrors } from "@relcu/form";
import { useAlert }         from "@relcu/ui";
import { JsonFormProps }    from "@relcu/ui";
import { schemaVar }        from "../../../../reactiveVars";
import { ILayoutJql }       from "../../../../types/ILayouts";
import { transformFields }  from "../../../../utils/graphQlUtils";
import { flatten }          from "../../../../utils/helpers";
import { getJqlMutation }   from "../index";

export interface UseJqlForm extends Omit<JsonFormProps, "onSubmit"> {
  className: string;
  jql: ILayoutJql;
  successMessage?: string;
  afterSubmit?: (result, form: FormApi, variables) => void;
  beforeSubmit?: (values, form: FormApi) => void;
  onError?: (e, form: FormApi, variables) => SubmissionErrors | void;
  transformValues?: (values, form: FormApi) => any;
  onSubmit?: (
    values: { id, fields } | { fields },
    form: FormApi,
    callback?: (errors?: SubmissionErrors) => void
  ) =>
    | SubmissionErrors
    | Promise<SubmissionErrors | undefined>
    | undefined
    | void;
  getErrorMessage?(e, errors);
}

export function useJqlForm(options: UseJqlForm) {
  const { success, error } = useAlert();
  const client = useApolloClient();
  const {
    successMessage = "Changes saved successfully.",
    afterSubmit,
    beforeSubmit,
    jql,
    className,
    getErrorMessage,
    transformValues,
    onError,
    ...jsonFormProps
  } = options;
  const ref = useRef<HTMLFormElement>();
  const jqlForm = {
    parseError: (e) => {
      console.log("Parse error", e);
      let errors: Object = { [ FORM_ERROR ]: e.message };
      if (Array.isArray(e.graphQLErrors)) {
        for (let error of e?.graphQLErrors) {
          error?.extensions?.validationErrors?.forEach((validationError) => {
            const field = `${validationError.path.join(".")}.${validationError.field}`;
            errors = setIn(errors, field, validationError.message);
          });
        }
      }
      console.log("Form Error", errors);
      return errors;
    },
    onSubmit: async (values, form: FormApi, callback?) => {
      let variables;
      const schemas = schemaVar();
      try {
        await beforeSubmit?.(values, form);
        const { initialValues } = form.getState();
        values = transformValues?.(values, form) || values;
        const fields = transformFields(values, initialValues, className, schemas);
        const submit = options.onSubmit || jqlForm.submit;
        const id = options.initialValues?.id || options.initialValues?.objectId;
        variables = { id, fields };
        const result = await submit(variables, form, callback);
        jqlForm.onSuccess(result, form, variables);
      } catch (e) {
        const onFailure = onError || jqlForm.onError;
        return onFailure(e, form, variables);
      }
    },
    onError(e) {
      const errors = jqlForm.parseError(e);
      error(getErrorMessage?.(e, errors) || `Oops. Something went wrong!`);
      jqlForm.onFail(errors);
      return errors;
    },
    async update(variables) {
      const { documentNode: mutation } = getJqlMutation(jql.mutation.update, { operationName: `Update${className}Form` });
      const { data } = await client.mutate({
        refetchQueries: [`${className}ListViewQuery`],
        mutation,
        variables
      });
      return data?.[ jql.mutation.update.operation ];
    },
    async create(variables) {
      const { documentNode: mutation } = getJqlMutation(jql.mutation.create, { operationName: `Create${className}Form` });
      const { data } = await client.mutate({
        refetchQueries: [`${className}ListViewQuery`],
        mutation,
        variables
      });
      return data?.[ jql.mutation.create.operation ];
    },
    async submit(values, form: FormApi, callback?) {
      const { id, fields } = values;
      if (id) {
        return jqlForm.update({ input: { id, fields } });
      }
      return jqlForm.create({ input: { fields } });
    },
    onSuccess: (result, form: FormApi, variables) => {
      setTimeout(() => {
        const { submitSucceeded } = form.getState();
        if (submitSucceeded) {
          success(successMessage);
          afterSubmit?.(result, form, variables);
        }
      });
    },
    onFail: (errors) => {
      const flattenErrors = flatten(errors);
      const firstError = Object.keys(flattenErrors)[ 0 ];
      const invalidField = ref.current?.querySelector(`[name="${firstError}"]`);
      if (invalidField) {
        invalidField.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const onSubmit = useCallback(jqlForm.onSubmit, [options]);

  return {
    ref,
    ...jsonFormProps,
    className,
    onSubmit
  };
}
