import { useReactiveVar }        from "@apollo/client";
import React                     from "react";
import { useMemo }               from "react";
import { useCallback }           from "react";
import { FC }                    from "react";
import { IconType, Queue }       from "@relcu/ui";
import { useAlert }              from "@relcu/ui";
import { QueueStat }             from "@relcu/ui";
import { ButtonColors }          from "@relcu/ui";
import { audioDevicesVar }       from "../../../reactiveVars";
import { microphoneAccessVar }   from "../../../reactiveVars";
import { soundVar }              from "../../../reactiveVars";
import { QueueStats_queueStats } from "./__types__/QueueStats";

export const LeadQueue: FC<{
  queue: QueueStats_queueStats,
  take: (id) => void,
  loading: boolean,
  canRing: boolean,
  collapsed: boolean,
}> = React.memo(function LeadQueue({ queue, take, loading, collapsed, canRing }) {
  const sound = soundVar();
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const audioDevices = useReactiveVar(audioDevicesVar);
  const hasMicrophoneIssue = !microphoneAccess || !audioDevices?.audiooutput?.length ||!audioDevices?.audiooutput?.length
  const {
    isNextCall, id, isLimitExceeded,
    leadTakenAt, coolDownPeriod, queue: name,
    icon, buttonColor, isCoolingDown,
    dailyCapVisible, dailyCap, leadTakenDaily,
    weeklyCapVisible, weeklyCap, leadTakenWeekly,
    monthlyCapVisible, monthlyCap, leadTakenMonthly,
    countVisible, leadsAvailable, notify, coolDownVisible,
    webLeadSound, phoneLeadSound
  } = queue;
  const state = useMemo(() => {
    if (isLimitExceeded) {
      return "finished";
    }
    if (isCoolingDown) {
      return "coolDown";
    }
    if (isNextCall) {
      return "rigging";
    }
    if (leadsAvailable == 0) {
      // const soundSettings = isNextCall ? phoneLeadSound : webLeadSound;
      sound.pause(phoneLeadSound.sound as any).catch(console.error);
      sound.pause(webLeadSound.sound as any).catch(console.error);
      return "empty";
    }

    return "available";
  }, [isLimitExceeded, isNextCall, leadsAvailable, isCoolingDown]);
  //todo check
  window[ "sound" ] = sound;

  const showStatIcon = useMemo(() => {
    return dailyCapVisible || monthlyCapVisible || weeklyCapVisible || countVisible;
  }, [dailyCapVisible, monthlyCapVisible, weeklyCapVisible, countVisible]);

  const disableDaily = useMemo(() => dailyCap == leadTakenDaily, [dailyCap, leadTakenDaily]);
  const disableWeekly = useMemo(() => weeklyCap == leadTakenWeekly, [weeklyCap, leadTakenWeekly]);
  const disableMonthly = useMemo(() => monthlyCap == leadTakenMonthly, [monthlyCap, leadTakenMonthly]);

  const availableLeadsForUser = useMemo(() => {
    let availableLeads = queue.dailyCap - queue.leadTakenDaily;
    return availableLeads ?? 0;
  }, [queue.dailyCap, queue.leadTakenDaily]);
  const { info } = useAlert();

  const handleAnimate = useCallback(() => {
    if (notify) {
      const soundSettings = queue.isNextCall ? phoneLeadSound : webLeadSound;
      if (canRing) {
        sound.play(soundSettings?.sound as any, soundSettings?.repeat as any, true,(e:DOMException) => {
          if(e.code == 0){
            let w = info("Due to low interactions in the system, the browser has muted the sound effects. Please close this to unmute (i.e., new lead available to take, ringing incoming calls, etc.).",
              { autoClose: false, width: "auto" });
            document.body.addEventListener("click", () => {
              w.remove();
            }, { once: true });
          }
        });
      }
      toNotify();
    }
  }, [canRing, notify, queue.isNextCall, webLeadSound, phoneLeadSound]);

  function toNotify() {
    Notification.requestPermission().then(function (status) {
      if (status === "granted") {
        navigator.serviceWorker.ready.then(function (registration) {
          const worker = navigator.serviceWorker.controller || registration.active;
          worker?.postMessage({
            action: "notification_show",
            event:{
              title:"New leads are available",
              body: `${name} queue has new leads`,
              icon: "./assets/icon-512x512.png",
              tag: `${name}`,
              requireInteraction: false,
              data: {
                queueId: id,
                href: window.location.href
              },
              actions: [{
                action: "take_lead",
                title: "Take Lead"
              }]
            }
          });
        });
      }
    });
  }
  const handleTake = (id) => {
    const soundSettings = queue.isNextCall ? phoneLeadSound : webLeadSound;
    take(id);
    sound.pause(soundSettings?.sound as any).catch(console.error);
  };

  return (
    <Queue
      onAnimate={handleAnimate}
      collapsed={collapsed}
      icon={icon as IconType}
      stats={<QueueStat>
        {
          dailyCapVisible &&
          <QueueStat.Item label={"Daily"} tooltip={`Available for today: ${dailyCap}`}
                          value={`${leadTakenDaily}/${dailyCap}`} disabled={disableDaily}/>
        }
        {
          weeklyCapVisible &&
          <QueueStat.Item label={"Weekly"} tooltip={`Available for this week: ${weeklyCap}`}
                          value={`${leadTakenWeekly}/${weeklyCap}`} disabled={disableWeekly}/>
        }
        {
          monthlyCapVisible &&
          <QueueStat.Item label={"Monthly"} tooltip={`Available for this month: ${monthlyCap}`}
                          value={`${leadTakenMonthly}/${monthlyCap}`} disabled={disableMonthly}/>
        }
        {
          countVisible &&
          <QueueStat.Item label={"In queue"} tooltip={`In queue: ${leadsAvailable}`} value={leadsAvailable}/>
        }
      </QueueStat>}
      availableCount={leadsAvailable}
      queueName={name}
      repeatCount={queue.isNextCall ? phoneLeadSound?.repeat : webLeadSound?.repeat}
      isLimitExceeded={isLimitExceeded}
      showStatIcon={showStatIcon}
      color={buttonColor as ButtonColors}
      disabled={(!leadsAvailable || loading || (queue.isNextCall && hasMicrophoneIssue)) }
      animate={availableLeadsForUser}
      coolDownPeriod={coolDownPeriod}
      lastTakenAt={leadTakenAt}
      state={state}
      countVisible={countVisible}
      actionTitle={loading ? "Taking..." : (queue.isNextCall ? "Take a call" : "Take a lead")}
      showCoolDownTime={queue.coolDownVisible}
      onClick={() => handleTake(id)}/>
  );
});
