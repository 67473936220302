import { useCallback }             from "react";
import { useMemo }                 from "react";
import { useSource }               from "@relcu/ui";
import { PopupWindow }             from "../../../utils/PopupWindow";
import { GmailConnectButtonProps } from "./GmailConnectButton";

export function useGmailConnectButton(props: GmailConnectButtonProps) {
  const { domain } = props;
  const { $viewer } = useSource();
  const popupHeight = 650;
  const popupWidth = 500;
  const oauthUrl = useMemo(() => {
    const url = new URL(`${window.location.origin}/api/v1/login/google`);
    const data = btoa(JSON.stringify({
      domainId: domain.objectId,
      userId: $viewer?.objectId,
      popupId: "gmail-oauth-authorize"
    }));
    url.searchParams.append("data", data);
    return url.href;
  }, [domain]);

  const handleLogin = useCallback(() => {
    const left = (window.screen.width / 2) - (popupWidth / 2);
    const top = (window.screen.height / 2) - (popupHeight / 2);
    const popup = PopupWindow.open(
      "gmail-oauth-authorize",
      oauthUrl,
      {
        popup: false,
        height: popupHeight,
        width: popupWidth,
        top: top,
        left: left
      }
    );
    popup.then(
      (data: any) => {
        return props.onSuccess?.(data);
      },
      error => {
        //"Make sure to accept the \"React, compose, send and permanently delete all your email from Gmail.\" checkbox to connect you mailbox."
        console.error("ERROR DATA", error);
        props.onError(error);
      }
    );
  }, [oauthUrl]);

  return {
    handleLogin
  };
}


