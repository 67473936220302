import React                    from "react";
import { FC }                   from "react";
import { useFormState }         from "@relcu/final-form";
import { omit }                 from "@relcu/ui";
import { useNavigationConfirm } from "../../useNavigationConfirm";

export interface DirtyDialogControllerProps {
  header?: string
  title?: string,
  subTitle?: string
}

export const DirtyDialogController: FC<DirtyDialogControllerProps> = React.memo(function DirtyDialogController(props) {
  const {
    title = "You have unsaved changes that will be lost if you proceed.",
    subTitle = "Are you sure you want to continue without saving?",
    header = "Unsaved changes"
  } = props;
  const { submitting, dirtyFields } = useFormState({
    subscription: {
      dirty: true,
      submitting: true,
      dirtyFields: true
    }
  });
  const fields = omit(dirtyFields, ["from", "to", "loanProposal", "scope", "previewSettings", "previewSettings.mailbox", "previewSettings.user", "previewSettings.scope"]);

  useNavigationConfirm({
    title,
    subTitle,
    header,
    enabled: Object.keys(fields).length > 0 && !submitting
  });
  return null;
});

// export interface DirtyDialogProps extends DirtyDialogControllerProps {
//   onClose(),
//   onConfirm()
// }

// export const DirtyDialog: FC<DirtyDialogProps> = function DirtyDialog(props) {
//   const { onClose, onConfirm, header, title, subTitle } = props;
//
//   return (
//     <Modal
//       className={"unsaved-changes-modal"}
//       title={header ?? "Unsaved changes"}
//       open={true}
//       onClose={onClose}
//       closeIcon={true}
//       variant={"small"}>
//       <ModalBody gap={"XS"} container direction={"column"} flex={"1 1 auto"} justify={"center"} alignItems={"center"}>
//         <Typography variant={"base14"} weights={"medium"} lineHeight={"lh24"}>
//           {title ?? "You have unsaved changes that will be lost if you proceed."}
//         </Typography>
//         <Typography variant={"small12"} style={{ color: "var(--rc-text-secondary)" }}>
//           {subTitle ?? "Are you sure you want to continue without saving?"}
//         </Typography>
//       </ModalBody>
//       <ModalFooter>
//         <Button type="button" size={"sm"} onClick={onClose} appearance="text">CANCEL</Button>
//         <Button size={"sm"} onClick={onConfirm}>CONTINUE</Button>
//       </ModalFooter>
//     </Modal>
//   );
// };
