import React                                 from "react";
import { FC }                                from "react";
import { useMemo }                           from "react";
import { useContext }                        from "react";
import { useCallback }                       from "react";
import { useMutation }                       from "@apollo/client";
import { useApolloClient }                   from "@apollo/client";
import { pick }                              from "@relcu/ui";
import { omit }                              from "@relcu/ui";
import { useThrottleCallback }               from "@relcu/ui";
import { useCondition }                      from "@relcu/ui";
import { useSource }                         from "@relcu/ui";
import { Form }                              from "@relcu/rc";
import { FormSpy }                           from "@relcu/form";
import { arrayMutators }                     from "@relcu/form";
import { LoanEstimateOffer }                 from "../../../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE_OFFER }               from "../../../../../../graph/operations.graphql";
import { cleanObject }                       from "../../../../../../utils/helpers";
import { daysOfaYear }                       from "../../../../../../utils/helpers";
import { isNumeric }                         from "../../../../../../utils/utils";
import { Proposal }                          from "../../Proposal";
import { useAppendObCustomFields }           from "../../useDefaultOffer";
import { UPDATE_LOAN_ESTIMATE_OFFER }        from "../Offer";
import { WHEN_SECONDARY_FINANCING_NOT_NONE } from "../offer.conditions";
import { WHEN_IN_SURVEY_FEE_STATES }         from "../offer.conditions";
import { WHEN_IN_ATTORNEY_FEE_STATES }       from "../offer.conditions";
import { WHEN_IN_DOC_REVIEW_FEE_STATES }     from "../offer.conditions";
import { WHEN_IS_PESTINSPECTION_FEE }        from "../offer.conditions";
import { WHEN_LENDER_CREDITS }               from "../offer.conditions";
import { WHEN_DISCOUNT_POINT }               from "../offer.conditions";
import { UpdateLoanEstimateOffer }           from "../Offer/__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOfferVariables }  from "../Offer/__types__/UpdateLoanEstimateOffer";
import { PricingEngineContext }              from "../Offer/PricingEngineProvider";
import { MoreFeesEffector }                  from "./MoreFeesEffector";
import { RateCalculate }                     from "./RateCalculate";

export const Rate: FC<{
  data: Partial<LoanEstimateOffer>,
  fees: string[],
  isDirty: boolean,
  optionalFields: string[]
}> = React.memo((props) => {
  const { children, data, fees, isDirty, optionalFields } = props;
  const client = useApolloClient();
  const engine = useContext(PricingEngineContext);
  function omitIfNull(data: Record<string, any>, fields: string[]) {
    let params = { ...data };
    fields.forEach(field => {
      if (params[ field ] == null) {
        delete params[ field ];
      }
    });

    return params;
  }

  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(useAppendObCustomFields(UPDATE_LOAN_ESTIMATE_OFFER));
  const saveOffer = useThrottleCallback((data) => {
      const { objectId, ...rest } = data;
      const params = omitIfNull(rest, ["titleInsurance", "ownersTitle", "recordingCharges", "transferTax", "settlementFee", "titleCompany", "titleCompanyName"]);
      update({
        variables: {
          input: {
            id: objectId,
            fields: omit({
                ...params,
                mortech: params.mortech ? omit(params.mortech, ["__typename"]) : params.mortech,
                polly: params.polly ? omit(params.polly, ["__typename"]) : params.polly,
                optimalBlue: params.optimalBlue ? omit(params.optimalBlue, ["__typename"]) : params.optimalBlue
              }, ["__typename", "objectIcon", "isValid", "isTitleFeeEditable", "propertyAnnualInsuranceUnit", "propertyAnnualTaxUnit", "isDirty", "id"]
            )
          }
        }
      });
    }, 2000, false
  );

  const { $settings: { pricing: settings }, $object } = useSource();
  const loanEstimate = useContext(Proposal.Context);

  const handleFormChange = useCallback(({ values: formValues }) => {
    const { amortizationType, ...values } = formValues;
    queueMicrotask(() => {
      if (values.objectId && !isDirty) {
        const loanEstimateOffer = client.readFragment<LoanEstimateOffer>({
          fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
          fragmentName: "LoanEstimateOffer",
          id: client.cache.identify({ __typename: "LoanEstimateOffer", id: values.id })
        });
        const optionalFees = optionalFields.reduce((f, key) => {
          f[ key ] = fees.includes(key) ? (values[ key ] ?? null) : null;
          return f;
        }, {});

        client.writeFragment<LoanEstimateOffer>({
          fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
          fragmentName: "LoanEstimateOffer",
          data: {
            ...loanEstimateOffer,
            ...values,
            ...optionalFees
          },
          id: client.cache.identify({ __typename: "LoanEstimateOffer", id: values.id })
        });

        saveOffer({
          ...omitIfNull(loanEstimateOffer, ['polly','optimalBlue','mortech']),
          ...values,
          ...optionalFees
        });
      }
    });
  }, [fees, isDirty, optionalFields]);
  const prepaidInterestAmount = useMemo(() => (data.rate ? data.loanAmount / 100 * data.rate : 0) / daysOfaYear(new Date().getFullYear()), [data.loanAmount, data?.rate, settings]);
  const adminFee = useMemo(() => {
    if (data.productType == "va") {
      return { value: 0, editable: false };
    }
    let adminFee = {
      value: settings.closingFees.adminFee.default,
      editable: settings.closingFees.adminFee.editable
    };
    if ($object.leadSource?.leadPartner?.title == "Nerd Wallet" && isNumeric(settings.closingFees.adminFee.nerdWallet)) {
      adminFee = { value: settings.closingFees.adminFee.nerdWallet, editable: false };
    } else if ($object.leadSource?.leadPartner?.title == "Credit Karma" && isNumeric(settings.closingFees.adminFee.creditKarma)) {
      adminFee = { value: settings.closingFees.adminFee.creditKarma, editable: false };
    } else if ($object.leadSource?.leadPartner?.title == "Creative Planning" && isNumeric(settings.closingFees.adminFee.creativePlanning)) {
      adminFee = { value: settings.closingFees.adminFee.creativePlanning, editable: false };
    }
    return adminFee;
  }, [$object.leadSource, settings.closingFees.adminFee]);
  const points = useMemo(() => {
    return Math.round((Number(data.totalLoanAmount) * Math.abs(Number(100 - data.price)))) / 100;
  }, [data.totalLoanAmount, data.price]);
  const formData: Partial<LoanEstimateOffer> = useMemo(() => {
    return cleanObject({
      ...pick(data, [
        "id",
        "objectId",
        "amortizationType",
        "waive",
        "isTitleFeeEditable",
        "insurance",
        "taxes",
        "creditReportFee",
        "loanSafeFee",
        "mersFee",
        "verificationFee",
        "electronicDocumentDelivery",
        "transcriptFee",
        "lockInFee",
        "currentMortgageBalance",
        "propertyInsurancePrepaidAmount",
        "propertyInsurancePrepaidMonth",
        "propertyInsurancePrepaidTotal",
        "propertyTaxAmount",
        "propertyTaxMonth",
        "propertyTaxTotal",
        "propertyInsuranceYearly",
        "prepaidInterestTotal",
        "prepaidInterestAmount",
        "prepaidInterestDays",
        "adminFee",
        "withAppraisal",
        "appraisalFee",
        "rate",
        "apr",
        "price",
        "pi",
        "pmiCompany",
        "pmi",
        "monthlyPremium",
        "titleCompany",
        "titleCompanyName",
        "titleInsurance",
        "recordingCharges",
        "ownersTitle",
        "transferTax",
        "settlementFee",
        "lendersTitle",
        "totalLoanAmount",
        "loanAmount",
        "purchaseValue",
        "isStreamLine",
        "withCredit",
        "pricingEngine",
        "productType",
        "points",
        "pmiType",
        "pmiEligible",
        "loanTerm",
        "floodCertificationFee",
        "lenderCredits",
        "discountPoints",
        "financeMip",
        "financeFf",
        "mip",
        "ff",
        "pestInspectionFee",
        "docReviewFee",
        "attorneyFee",
        "surveyFee",
        "subordinationFee",
        "sellerConcession",
        "earnestMoneyPaid",
        "secondaryFinancing",
        "condoCertificateFee",
        "dti",
        "purchasePrice",
        "firstTimeHomeBuyer",
        "loanProgram",
        "loanPurpose",
        "propertyValue",
        "fico",
        "fico1",
        "ltv",
        "cltv",
        "initialArmTerm",
        "otherCostTotal",
        "creditReportTotal",
        "prepaidCostTotal",
        "titleFeeTotal"
      ], true)
    });
  }, [data]);

  const { apply: whenDiscountPoint } = useCondition({ conditions: WHEN_DISCOUNT_POINT, source: data });
  const { apply: whenLenderPoint } = useCondition({ conditions: WHEN_LENDER_CREDITS, source: data });
  const { apply: whenIsPestinspectionFee } = useCondition({ conditions: WHEN_IS_PESTINSPECTION_FEE, source: { ...data, $pestInspectionFeeStates: settings.closingFees.pestInspectionFeeStates, $state: loanEstimate.propertyState } });
  const { apply: whenInDocReviewFeeStates } = useCondition({ conditions: WHEN_IN_DOC_REVIEW_FEE_STATES, source: { ...data, $docReviewFeeStates: settings.closingFees.docReviewFeeStates, $state: loanEstimate.propertyState } });
  const { apply: whenInAttorneyFeeStates } = useCondition({ conditions: WHEN_IN_ATTORNEY_FEE_STATES, source: { ...data, ...loanEstimate, $attorneyFeeStates: settings.closingFees.attorneyFeeStates, $state: loanEstimate.propertyState } });
  const { apply: whenInSurveyFeeStates } = useCondition({ conditions: WHEN_IN_SURVEY_FEE_STATES, source: { ...data, $surveyFeeStates: settings.closingFees.surveyFeeStates, $state: loanEstimate.propertyState } });
  const { apply: whenSecondaryFinancingNotNone } = useCondition({ conditions: WHEN_SECONDARY_FINANCING_NOT_NONE, source: data });
  const isTitleFeeEditable = useMemo(() => {
    const selectedTitle = formData.titleCompany;
    return !!engine.titleCompanies.find(t => t.providerId == selectedTitle)?.editable;
  }, [formData]);
  const propertyAnnualInsurance = useMemo(() => data.propertyAnnualInsurance, [data.propertyAnnualInsurance]);
  const propertyAnnualTax = useMemo(() => data.propertyAnnualTax, [data.propertyAnnualTax]);
  const initialValues = useMemo(() => {
    const initialValue = {
      waive: true,

      /* -Credit report & other fees- */
      creditReportFee: settings.closingFees.creditReportFee * ($object.members.filter(m => ["borrower", "co_borrower"].includes(m.type)).length),
      loanSafeFee: settings.closingFees.loanSafeFee,
      mersFee: settings.closingFees.mersFee,
      verificationFee: settings.closingFees.verificationFee,
      electronicDocumentDelivery: settings.closingFees.electronicDocumentDelivery,
      transcriptFee: settings.closingFees.transcriptFee,
      /* -- */
      /* -Prepaid costs- */
      lockInFee: settings.closingFees.lockInFee,

      propertyTaxMonth: settings.closingFees.taxPrepaymentMonths,
      prepaidInterestAmount: prepaidInterestAmount,
      prepaidInterestDays: settings.closingFees.interestPrepaymentDays,
      propertyInsurancePrepaidMonth: settings.closingFees.insurancePrepaymentMonths,
      /* -- */
      /*Other closing options */

      ...formData,
      insurance: propertyAnnualInsurance / 12,
      taxes: propertyAnnualTax / 12,
      propertyInsurancePrepaidAmount: propertyAnnualInsurance / 12,
      propertyInsurancePrepaidTotal: (propertyAnnualInsurance / 12) * (formData.propertyInsurancePrepaidMonth ?? settings.closingFees.insurancePrepaymentMonths),
      propertyTaxAmount: propertyAnnualTax / 12,
      propertyTaxTotal: (propertyAnnualTax / 12) * (formData.propertyTaxMonth ?? settings.closingFees.taxPrepaymentMonths),
      propertyInsuranceYearly: loanEstimate.loanPurpose == "purchase" ? propertyAnnualInsurance : 0,
      adminFee: adminFee.value,
      isTitleFeeEditable: isTitleFeeEditable,
      propertyType: loanEstimate.propertyType,
      appraisalFee: formData.withAppraisal ? formData.appraisalFee : null,
      points: 100 - data.price,
      lenderCredits: whenLenderPoint ? points : 0,
      discountPoints: whenDiscountPoint ? points : 0,
      floodCertificationFee: settings.closingFees.floodCertificationFee,
      pestInspectionFee: whenIsPestinspectionFee ? settings.closingFees.pestInspectionFee : 0,
      docReviewFee: whenInDocReviewFeeStates ? settings.closingFees.docReviewFee : 0,
      attorneyFee: whenInAttorneyFeeStates ? settings.closingFees.attorneyFee : 0,
      surveyFee: whenInSurveyFeeStates ? settings.closingFees.surveyFee : 0,
      subordinationFee: whenSecondaryFinancingNotNone ? settings.closingFees.subordinationFee : 0
      /* -- */
    };

    return cleanObject(initialValue);
  }, [loanEstimate, formData, settings, $object.members, propertyAnnualInsurance, propertyAnnualTax, prepaidInterestAmount, isTitleFeeEditable]);

  return (
    <Form
      as="div"
      initialValues={initialValues}
      keepDirtyOnReinitialize={true}
      onSubmit={useCallback(() => undefined, [])}
      mutators={{
        ...arrayMutators
      }}>
      <FormSpy subscription={{ values: true, modified: true }} onChange={handleFormChange}/>
      <MoreFeesEffector fees={fees}/>
      <RateCalculate fees={fees}/>
      {children}
    </Form>
  );
});
