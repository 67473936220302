import React                       from "react";
import { FC }                      from "react";
import { FormWhere }               from "@relcu/ui";
import { useContext }              from "react";
import { FieldCell }               from "@relcu/rc";
import { WHEN_IS_OB_PRICING }      from "../offer.conditions";
import { WHEN_IS_POLLY_PRICING }      from "../offer.conditions";
import { WHEN_IS_MANUAL_PRICING }  from "../offer.conditions";
import { WHEN_IS_MORTECH_PRICING } from "../offer.conditions";
import { PricingEngineContext }    from "../Offer/PricingEngineProvider";

export const ProductCategoryCell: FC = React.memo(() => {
  const context = useContext(PricingEngineContext);

  return <>
    <FormWhere conditions={WHEN_IS_MORTECH_PRICING}>
      <FieldCell.Select name={"mortech.loanProductId"} data={context.mortechProductList} searchable={true} style={{position: "sticky", top: 80, zIndex: 2}}/>
    </FormWhere>
    <FormWhere conditions={WHEN_IS_OB_PRICING}>
      <FieldCell.Read plaintext name={"loanProduct"} style={{position: "sticky", top: 80, zIndex: 2}}/>
    </FormWhere>
    <FormWhere conditions={WHEN_IS_POLLY_PRICING}>
      <FieldCell.Read plaintext name={"loanProduct"} style={{position: "sticky", top: 80, zIndex: 2}}/>
    </FormWhere>
    <FormWhere conditions={WHEN_IS_MANUAL_PRICING}>
      <FieldCell.Read plaintext name={"loanProduct"} style={{position: "sticky", top: 80, zIndex: 2}}/>
    </FormWhere>
  </>;
});
