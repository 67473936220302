import React                       from "react";
import { FC }                      from "react";
import { useContext }              from "react";
import { FieldCell }               from "@relcu/rc";
import { FormWhere }               from "@relcu/ui";
import { useSchemaField }          from "../../../../useSchemaField";
import { WHEN_IS_OB_PRICING, WHEN_IS_POLLY_PRICING }      from "../offer.conditions";
import { WHEN_IS_MANUAL_PRICING }  from "../offer.conditions";
import { WHEN_IS_MORTECH_PRICING } from "../offer.conditions";
import { PricingEngineContext }    from "../Offer/PricingEngineProvider";
import { NullValueCell }           from "./NullValueCell";

export const LeadSourceCell: FC = React.memo(() => {
  const { options: sourceOptions } = useSchemaField("LoanEstimateOffer", "mortech.source");
  const context = useContext(PricingEngineContext);

  return <>
    <FormWhere conditions={WHEN_IS_MORTECH_PRICING}>
      <FieldCell.Select name={"mortech.source"} required data={sourceOptions}/>
    </FormWhere>
    <FormWhere conditions={WHEN_IS_OB_PRICING}>
      <FieldCell.Select name={"optimalBlue.source"} data={context.obsources}/>
    </FormWhere>
    <FormWhere conditions={WHEN_IS_MANUAL_PRICING}>
      <NullValueCell name={"source"}/>
    </FormWhere>
    <FormWhere conditions={WHEN_IS_POLLY_PRICING}>
      <NullValueCell name={"source"}/>
    </FormWhere>
  </>;
});
