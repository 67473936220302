import React                   from "react";
import { useEffect }           from "react";
import { useImperativeHandle } from "react";
import { forwardRef }          from "react";
import { useState }            from "react";
import { useRef }              from "react";
import { createRef }           from "react";
import { useCallback }         from "react";
import { ComponentProps }      from "react";
import ReactDOM                from "react-dom";
import { RefObject }           from "react";
import { ComponentType }       from "react";
import { ReactElement }        from "react";
import { useLatest }           from "../..";
import { ConfirmDialogProps }  from "./ConfirmDialog";
import { ConfirmDialog }       from "./ConfirmDialog";
import { ModalProps }          from "./Modal";
import { useNavigate }         from "@relcu/react-router";
import { useLocation }         from "@relcu/react-router";
import { Location }            from "@relcu/react-router";
import { guidGenerator }       from "../..";

function usePatchElement(): [ReactElement[], (element: ReactElement) => Function] {
  const [elements, setElements] = useState<ReactElement[]>([]);
  const patchElement = useCallback((element: ReactElement) => {
    setElements(originElements => [...originElements, element]);
    return () => {
      setElements(originElements => originElements.filter(ele => ele !== element));
    };
  }, []);
  return [elements, patchElement];
}
const destroyFns: Array<() => void> = [];
interface ElementsHolderRef {
  patchElement: ReturnType<typeof usePatchElement>[1];
  elements: any[];
}
const ElementsHolder = React.memo(forwardRef<ElementsHolderRef>((_props, ref) => {
  const [elements, patchElement] = usePatchElement();
  useImperativeHandle(
    ref,
    () => ({
      elements,
      patchElement
    }),
    []
  );
  return <>{elements}</>;
}));

const HookModal = forwardRef<HookModalRef, HookModalProps>(({ id, afterClose, config, component: Component }, ref) => {
  const location = useLocation();
  const locationRef = useLatest<Location>(location);
  // console.info("HookModal location state", location.state, locationRef.current.state);
  const navigate = useNavigate();
  const close = (...args: any[]) => {
    setVisible(false);
    afterClose(...args);
  };
  useEffect(() => {
    const modals = locationRef.current.state?.[ "modals" ] || [];
    const nextState = { ...locationRef.current.state, modals: [...modals, id] };
    navigate(`${locationRef.current.pathname}${locationRef.current.search.toString()}`, { state: nextState });
    return () => {
      const modals = (locationRef.current.state?.[ "modals" ] || []).filter(i => i != id);
      const nextState = { ...locationRef.current.state, modals: modals };
      navigate(`${locationRef.current.pathname}${locationRef.current.search.toString()}`, { state: nextState });
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (!locationRef.current.state?.[ "modals" ]?.includes(id)) {
        close();
      }
    }, 10);
  }, [location.state]);
  const [visible, setVisible] = useState(true);
  const [innerConfig, setInnerConfig] = useState<any>(config);

  useImperativeHandle(ref, () => ({
    destroy: close,
    update: (newConfig: ModalFuncProps) => {
      setInnerConfig(originConfig => ({
        ...originConfig,
        ...newConfig
      }));
    }
  }));
  return (
    <Component {...innerConfig} open={visible} onClose={close}/>
  );
});
export function useModal<ModalComponent extends ComponentType<any>>(type: ModalComponent): [(a: ComponentProps<ModalComponent>) => ModalFuncReturnType<ComponentProps<ModalComponent>>, ReactElement] {
  const holderRef = useRef<ElementsHolderRef>(null as any);
  // ========================== Effect ==========================
  const [actionQueue, setActionQueue] = useState<(() => void)[]>([]);

  useEffect(() => {
    if (actionQueue.length) {
      const cloneQueue = [...actionQueue];
      cloneQueue.forEach(action => {
        action();
      });

      setActionQueue([]);
    }
  }, [actionQueue]);
  const createModalFn = useCallback(
    function hookConfirm(config: ComponentProps<ModalComponent>) {
      const modalRef = createRef<HookModalRef>();
      let closeFunc: Function;
      const id = guidGenerator();
      const modal = (
        <HookModal
          ref={modalRef}
          id={id}
          component={type}
          key={`modal-${id}`}
          config={config as unknown}
          afterClose={(...args) => {
            if (typeof config[ "onClose" ] == "function") {
              config[ "onClose" ](...args);
            }
            closeFunc();
          }}
        />
      );
      closeFunc = holderRef.current?.patchElement(modal);
      return {
        destroy: () => {
          function destroyAction() {
            modalRef.current?.destroy();
          }

          if (modalRef.current) {
            destroyAction();
          } else {
            setActionQueue(prev => [...prev, destroyAction]);
          }
        },
        update: (newConfig: Partial<ModalProps>) => {
          function updateAction() {
            modalRef.current?.update(newConfig);
          }

          if (modalRef.current) {
            updateAction();
          } else {
            setActionQueue(prev => [...prev, updateAction]);
          }
        }
      };
    },
    []
  );

  return [createModalFn, <ElementsHolder ref={holderRef}/>];
}
export function modal<ModalComponent extends ComponentType>(ModalToRender: ModalComponent, config?: ComponentProps<ModalComponent>): ModalFuncReturnType<Omit<ComponentProps<ModalComponent>, "onClose" | "open">> {
  const container = document.createDocumentFragment();
  let currentConfig = { ...config, close, open: true };
  function destroy(...args: any[]) {
    ReactDOM.unmountComponentAtNode(container);
    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[ i ];
      if (fn === close) {
        destroyFns.splice(i, 1);
        break;
      }
    }
  }
  function render({ okText, cancelText, prefixCls: customizePrefixCls, close: onClose, ...props }: any) {
    ReactDOM.render(
      <ModalToRender
        onClose={destroy}
        {...props}
      />,
      container
    );
  }
  function close(...args: any[]) {
    currentConfig = {
      ...currentConfig,
      open: false
    };
    render(currentConfig);
    destroy.apply(this, args);
  }
  function update(configUpdate: {}) {
    if (typeof configUpdate === "function") {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate
      };
    }
    render(currentConfig);
  }
  render(currentConfig);
  destroyFns.push(close);
  return {
    destroy: close,
    update
  };
}
export function confirmModal(options: Omit<ConfirmDialogProps, "onConfirm" | "onClose">): Promise<boolean> {
  return new Promise((accept, reject) => {
    const { destroy } = modal(ConfirmDialog, {
      ...options,
      onClose: () => {
        reject(false);
        destroy();
      },
      onConfirm() {
        accept(true);
        destroy();
      }
    });
  });
}
export interface ModalFuncProps extends Omit<ModalProps, "onClose" | "open"> {

}
export interface ModalFuncReturnType<T = ModalFuncProps> {
  destroy: () => void;
  update: (props: Partial<T>) => void;
}
export interface HookModalProps {
  id: string;
  component: ComponentType;
  ref: RefObject<HookModalRef>;
  afterClose: Function;
  config: ModalFuncProps;
}
export interface HookModalRef {
  destroy: () => void;
  update: (config: ModalFuncProps) => void;
}
