import { Box }                    from "@relcu/ui";
import { useCallback }            from "react";
import React                      from "react";
import { FC }                     from "react";
export const MetabaseFrame: FC<{ view?:string, params?:{[key:string]:string}, title?:string }> = React.memo(function MetabaseFrame({ view,params,title }) {
  const authUrl = useCallback( () => {
      return `${location.origin}/api/v1/auth/metabase?return_to=${encodeURIComponent(view)}`
  },[view]);
  console.info(authUrl);
  return <Box  style={{height:"100%"}}>
    <iframe
      src={authUrl()}
      title={title}
      style={{ border: "none", width: "100%", height: "100%" }}
    />
  </Box>
});


export default MetabaseFrame;