import React                 from "react";
import { FC }                from "react";
import { Cell as TableCell } from "@relcu/rc";
import { StandardProps }     from "rsuite-table/src/@types/common";
import { useNullValue }      from "./NullValueCell";

interface CellProps extends StandardProps {
  name?: string;
  level?: number;
}
export const Cell: FC<CellProps> = React.memo(function TitleCell(props) {
  const { name, children, ...rest } = props;
  const shouldNull = useNullValue(name || "");
  return shouldNull ? null :
    <TableCell {...rest}>{children}</TableCell>;
});
