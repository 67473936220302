import React                      from "react";
import { FC }                     from "react";
import { IconType }               from "../../../../..";
import { EmptyList }              from "../../../../..";
import { Section }                from "../../../../..";
import { Loader }                 from "../../../../..";
import { Scrollbar }              from "../../../../..";
import { Box }                    from "../../../../..";
import { classNames as clsNames } from "../../../../..";
import { humanize }               from "../../../../../utils/helpers";
import { isObject }               from "../../../../../utils/helpers";
import { TextField }              from "../../../../Field/TextField";
import { Form }                   from "@relcu/form";

export interface ObjectViewProps {
  className?: string
  loading: boolean,
  data: Record<string, string>
  emptyIcon: IconType
  emptyText: string
  emptyContent: string
}
export const ObjectView: FC<ObjectViewProps> = React.memo(function ObjectView(props) {
  const { className, loading, data } = props;
  const classNames = clsNames(ObjectViewClasses.ObjectView, className);
  const renderObject = (obj, key = ""): any => {
    let keys: any[] = Object.keys(obj);
    return keys?.map((k) => {
      const name = key ? key + "_K." + k : k;
      let value = obj[ k ];
      if (isObject(value)) {
        return <Box key={k} container direction={"column"} gap={"XS"} flexBasis={"100%"}
                    className={ObjectViewClasses.Section}>
          <Box className={ObjectViewClasses.SectionTitle}>
            {humanize(k)}
          </Box>
          <Box container direction={"row"} gap={"XS"} wrap={"wrap"}>
            {renderObject(value, name)}
          </Box>
        </Box>;
      } else if (Array.isArray(value)) {
        return <Box key={k} container direction={"column"} gap={"XS"} flexBasis={"100%"}
                    className={ObjectViewClasses.Section}>
          <Box className={ObjectViewClasses.SectionTitle}>
            {humanize(k)}
          </Box>
          <Box container direction={"column"} gap={"XS"}>
            {value.map((o, i) => {
              const itemName = key ? key + `${i}` + k : k
              if (isObject(o)) {
                return <Box key={i} container direction={"row"} gap={"XS"} flexBasis={"100%"}
                            className={ObjectViewClasses.Section} wrap={"wrap"}>{renderObject(o, `${i}`)}</Box>;
              } else {
                return <TextField view={"read"} key={i} name={itemName} defaultValue={o != null ? String(o) : "-"}
                                  label={humanize(i.toString())}/>;
              }
            })}
          </Box>
        </Box>;
      }
      return <TextField view={"read"} key={k} name={name} label={humanize(k)}
                        defaultValue={obj[ k ] != null ? String(obj[ k ]) : "-"}
                        flexBasis={"30%"}/>;
    });
  };
  return <Form onSubmit={() => {
  }}>
    {({ handleSubmit }) => <>
      <form onSubmit={handleSubmit}>
        {loading && <Loader variant={"circular"}/>}
        {!loading &&
        <Section editable={false} container direction={"column"} className={classNames} title={"Original"}>
          {
            data ?
              renderObject(data) :
              <Box container justify={"center"} flex={"1 1 auto"}>
                <EmptyList icon={props.emptyIcon} content={`Seems this lead was created directly on relcu`}
                           title={`Lead has no original source`}
                           alignSelf={"center"}/>
              </Box>
          }
        </Section>}
      </form>
    </>}
  </Form>;
});
export const enum ObjectViewClasses {
  ObjectView = "object-view",
  Section = "object-section",
  SectionTitle = "object-section-title"
}
