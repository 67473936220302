import React                          from "react";
import { useRef }                     from "react";
import { useCallback }                from "react";
import { useMemo }                    from "react";
import { useEffect }                  from "react";
import { useContext }                 from "react";
import { useReactiveVar }             from "@apollo/client";
import { gql }                        from "@apollo/client";
import { useQuery }                   from "@apollo/client";
import { useMutation }                from "@apollo/client";
import { useNavigate }                from "@relcu/react-router";
import { useWorkerEvents }            from "@relcu/ui";
import { useAlert }                   from "@relcu/ui";
import { useThrottleCallback }        from "@relcu/ui";
import { usePrevious }                from "@relcu/ui";
import { simpleDebounce }             from "@relcu/ui";
import { ClientContext }              from "../../../Client";
import { MEMBER_OWN_FIELDS_FRAGMENT } from "../../../graph/operations.graphql";
import { deviceVar }                  from "../../../reactiveVars";
import { activeQueueVar }             from "../../../reactiveVars";
import { isEmpty }                    from "../../../utils/helpers";
import { QueueStatsSubscription }     from "../../__types__/QueueStatsSubscription";
import { QUEUE_STATS_SUBSCRIPTION }   from "../../operations.graphql";
import { useViewerPhoneLines }        from "../../useViewerPhoneLines";
import { QueueStats_queueStats }      from "./__types__/QueueStats";
import { QueueStats }                 from "./__types__/QueueStats";
import { QueueUser }                  from "./__types__/QueueUser";
import { TakeLeadVariables }          from "./__types__/TakeLead";
import { TakeLead }                   from "./__types__/TakeLead";

const QUEUE_STATS = gql`
  query QueueStats {
    queueStats{
      id
      active
      isPush
      queue
      buttonColor
      priority
      webLeadSound{
        sound
        repeat
      }
      phoneLeadSound{
        sound
        repeat
      }
      icon
      isNextCall
      leadsAvailable
      leadTakenAt
      leadTakenDaily
      leadTakenMonthly
      leadTakenWeekly
      isLimitExceeded
      coolDownPeriod
      isCoolingDown
      countVisible
      coolDownVisible
      dailyCapVisible
      monthlyCapVisible
      weeklyCapVisible
      dailyCap
      weeklyCap
      monthlyCap
      notify
      autoDial
    }
  }
`;

const QUEUE_USER = gql`
  query QueueUser {
    viewer {
      user {
        role
      }
    }
  }
`;

export function useQueueStats() {
  const { fromNumbers, hasMicrophoneIssue } = useViewerPhoneLines();
  const navigate = useNavigate();
  const { sessionId } = useContext(ClientContext);
  const [load, { loading: loadingTakeLead }] = useMutation<TakeLead, TakeLeadVariables>(TAKE_LEAD);
  const { info, error } = useAlert();
  const { data, loading, subscribeToMore } = useQuery<QueueStats>(QUEUE_STATS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    returnPartialData: true
  });
  const { data: { viewer: { user: { role } = {} } = {} } = {} } = useQuery<QueueUser>(QUEUE_USER, { fetchPolicy: "cache-only" });

  const [queueStats, setQueueStats] = React.useState<any>();
  const [leftArrowBadge, setLeftArrowBadge] = React.useState<boolean>(false);
  const [rightArrowBadge, setRightArrowBadge] = React.useState<boolean>(false);
  const [rightButtonAnimation, setRightButtonAnimation] = React.useState<boolean>(false);
  const prev = usePrevious(data);
  const mouseOverRef = useRef(false);
  const activeQueue = useReactiveVar(activeQueueVar);
  const subscribe = () => {
    return subscribeToMore<QueueStatsSubscription>({
      document: QUEUE_STATS_SUBSCRIPTION,
      updateQuery(prev, { subscriptionData: { data: { queueStats: { node, event } } } }) {
        setQueueStats(node);
        switch (event) {
          case "UPDATE":
            if (prev.queueStats.find(eq => eq.id == node.id)) {
              return Object.assign({}, prev, {
                queueStats: prev.queueStats.map((eQueue) => eQueue.id == node.id ? { ...eQueue, ...node } : eQueue)
              });
            } else {
              return Object.assign({}, prev, {
                queueStats: [...prev.queueStats, node]
              });
            }
          case "DELETE":
            return Object.assign({}, prev, {
              queueStats: prev.queueStats.filter(({ id }) => id !== node.id)
            });
          default:
            return prev;
        }
      }
    });
  };

  useEffect(subscribe, []);
  const api = {
    queues() {
      let sortedStats = [];
      if (data?.queueStats) {
        sortedStats = [...data.queueStats];
        sortedStats = sortedStats.filter(a => (a.active && !a.isPush && a.dailyCap !== 0 && a.weeklyCap !== 0 && a.monthlyCap !== 0));
        sortedStats = sortedStats.sort((a, b) => {
          if (a.priority < b.priority) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return sortedStats;
    }
  };

  const handleMouseOver = (e) => {
    (simpleDebounce(() => mouseOverRef.current = true, 500))();
  };
  const handleMouseOut = (e) => {
    (simpleDebounce(() => mouseOverRef.current = false, 500))();
  };

  const queues: QueueStats_queueStats[] = useMemo(api.queues, [data]);

  useEffect(() => {
    if (!isEmpty(queues) && isEmpty(activeQueue)) {
      activeQueueVar(queues.findIndex(q => q.leadsAvailable > 0) || 0);
    }
  }, [queues, activeQueue]);

  const setActiveQueue = useCallback((current) => {
    return activeQueueVar(current);
  }, [queues]);

  useEffect(() => {
    const leadsAvailableUpdatedQueue = prev?.queueStats.find(stat => (stat?.id !== queueStats?.id) && (stat?.leadsAvailable !== queueStats?.leadsAvailable));
    if (leadsAvailableUpdatedQueue) {
      const updatedQueue = queues.find(q => q.id == queueStats?.id);
      const isHigherPriority = updatedQueue?.priority <= queues[ activeQueue ]?.priority;
      if (updatedQueue && !updatedQueue?.isLimitExceeded && !updatedQueue?.isCoolingDown && !mouseOverRef.current) {
        const isHigherPriority = updatedQueue?.priority <= queues[ activeQueue ]?.priority;
        if (isHigherPriority) {
          const updatedQueueIndex = queues.findIndex(q => q.id == queueStats?.id);
          activeQueueVar(updatedQueueIndex);
        } else {
          setRightButtonAnimation(true);
        }
      }
    }
  }, [queueStats, queues]);

  useEffect(() => {
    if (!isEmpty(queues) && !isEmpty(activeQueue)) {
      const leftQueues = queues.slice(0, activeQueue).filter(q => q.leadsAvailable > 0 && !q.isLimitExceeded && !q.isCoolingDown);
      const rightQueues = queues.slice(activeQueue + 1).filter(q => q.leadsAvailable > 0 && !q.isLimitExceeded && !q.isCoolingDown);
      setLeftArrowBadge(!isEmpty(leftQueues));
      setRightArrowBadge(!isEmpty(rightQueues));
    }
  }, [queues, activeQueue]);

  const take = useThrottleCallback(async (id) => {
    const { isNextCall, autoDial } = queues.find(queue => queue.id == id);
    try {
      const { call } = deviceVar();
      const { data } = await load({ variables: { queue: id, isCall: isNextCall, sessionId } });
      const lead = Object(data?.takeLead);
      if (!isNextCall && autoDial) {
        if (fromNumbers[ 0 ] && !hasMicrophoneIssue) {
          const primaryMember = lead.members.find((member) => member.isPrimary);
          primaryMember.contact.phones[ 0 ]?.number && !primaryMember.contact.phones[ 0 ]?.callOptOut && await call({
            from: fromNumbers[ 0 ].value,
            to: primaryMember.contact.phones[ 0 ]?.number,
            contactId: primaryMember.contact?.objectId,
            contactName: primaryMember.contact?.objectName,
            scopeId: lead.objectId,
            scopeName: lead.objectName,
            scopeClassName: lead.__typename
          });
        }
      }
      navigate(`/lead/${lead.objectId}/details`);
    } catch (e) {
      console.error(e);
      if (e.message === "The last lead was taken") {
        info(e.message);
      } else {
        error(e.message);
      }
    }
  }, 2000, true);
  const onWorkerMessage = useCallback(message => {
    console.log("Message from service worker", message);
    if (message.data.action == "notification_click") {
      if (message.data?.event?.queueId && message.data.event.action == "take_lead") {
        take(message.data.event.queueId);
      }
    }
  }, []);
  useWorkerEvents(onWorkerMessage);
  return {
    ...api,
    role,
    loading,
    activeQueue,
    handleMouseOut,
    handleMouseOver,
    setActiveQueue,
    hasRightBadge: () => rightArrowBadge,
    hasLeftBadge: () => leftArrowBadge,
    rightButtonAnimation,
    setRightButtonAnimation,
    queues,
    take,
    loadingTakeLead
  };
}

const TAKE_LEAD = gql`
  mutation TakeLead( $queue:String!,$isCall:Boolean,$sessionId:String!) {
    takeLead(queue:$queue ,isCall:$isCall,sessionId: $sessionId){
      id
      objectId
      objectName
      timezone
      members {
        ...MemberOwnFields
        contact {
          id
          objectId
          objectIcon
          objectName
          firstName
          lastName
          middleName
          phones {
            callOptOut
            number
          }
        }
      }
    }
  }
  ${MEMBER_OWN_FIELDS_FRAGMENT}
`;
