import React        from "react";
import { useState } from "react";

export const NullValuesProvider = React.memo((props) => {
  const [nulls, setNulls] = useState<Record<string, Set<number>>>({});
  const registerNull = (key, index, action = "increase") => {
    setNulls(nulls => {
      if (!nulls[ key ]) {
        return {
          ...nulls,
          [ key ]: new Set([index])
        };
      } else {
        let updatedNulls;
        if (action == "increase") {
          updatedNulls = nulls[ key ].add(index);
        } else if (nulls[ key ].delete(index)) {
          updatedNulls = nulls[ key ];
        }
        return {
          ...nulls,
          [ key ]: updatedNulls
        };
      }
    });
  };
  return <NullValuesContext.Provider value={{ nulls, registerNull }}>{props.children}</NullValuesContext.Provider>;
});
export const NullValuesContext = React.createContext<{
  nulls: Record<string, Set<number>>,
  registerNull: (key, index, action?: "increase" | "decrease") => void
}>(null);
