import React                                               from "react";
import { FC }                                              from "react";
import { useMemo }                                         from "react";
import { gql }                                             from "@apollo/client";
import { useLazyQuery }                                    from "@apollo/client";
import { useMutation }                                     from "@apollo/client";
import { useQuery }                                        from "@apollo/client";
import { confirmModal }                                    from "@relcu/ui";
import { useAlert }                                        from "@relcu/ui";
import { BackDropLoader }                                  from "@relcu/ui";
import { modal }                                           from "@relcu/ui";
import { StateWarningDialog }                              from "@relcu/ui";
import { Avatar, useSelect }                               from "@relcu/ui";
import { MenuItem }                                        from "@relcu/ui";
import { ModalProps }                                      from "@relcu/ui";
import { Modal }                                           from "@relcu/ui";
import { ModalBody }                                       from "@relcu/ui";
import { ButtonVariants }                                  from "@relcu/ui";
import { Button }                                          from "@relcu/ui";
import { ModalFooter }                                     from "@relcu/ui";
import { SearchInput }                                     from "@relcu/ui";
import { TypographyColor }                                 from "@relcu/ui";
import { TypographySize }                                  from "@relcu/ui";
import { Typography }                                      from "@relcu/ui";
import { LeadWhereInput }                                  from "../../../types/graphql-global-types";
import { AllUsers }                                        from "../../__types__/AllUsers";
import { ALL_USERS }                                       from "../../operations.graphql";
import { CommonModalClasses }                              from "../CommonModal/CommonModalClasses";
import { GetMismatchesLeadsByUserLicensedStatesVariables } from "./__types__/GetMismatchesLeadsByUserLicensedStates";
import { GetMismatchesLeadsByUserLicensedStates }          from "./__types__/GetMismatchesLeadsByUserLicensedStates";
import { UpdateLeadsVariables }                            from "./__types__/UpdateLeads";
import { UpdateLeads }                                     from "./__types__/UpdateLeads";
import "../CommonModal/common-modal.css";

export interface BulkAssignModalProps extends Partial<ModalProps> {
  ids?: string[];
  where?: LeadWhereInput;
  totalCount?: number;
  destroyHandler: () => void;
}

export const BulkUserAssignModal: FC<BulkAssignModalProps> = React.memo(function BulkAssignModal(props) {
  const { onClose, ids, where: queryWhere, destroyHandler, totalCount } = props;
  const { info } = useAlert();
  const { data: { users: { edges = [] } = {} } = {}, loading: userLoading } = useQuery<AllUsers>(ALL_USERS);
  const [updateLeads, { loading }] = useMutation<UpdateLeads, UpdateLeadsVariables>(UPDATE_LEADS, {
    context: {
      action: "SetAssignedTo",
      errorPolicy: "ignore"
    }
  });
  const [loadMismatchLeads] = useLazyQuery<GetMismatchesLeadsByUserLicensedStates, GetMismatchesLeadsByUserLicensedStatesVariables>(ALL_MISMATCHES_LEADS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const options = useMemo(() => {
    return edges.filter(({ node }) => !node.deactivated).map(({ node }) => ({
      label: node.objectName, value: node.id, icon: node.objectIcon, licensedStates: node.licensedStates, team: node.team
    }));
  }, [edges]);

  const {
    selected,
    setSearch,
    search,
    handleSelect,
    items
  } = useSelect({
    options,
    optionLabel: "label",
    optionKey: "value"
  });

  const handleSubmit = async () => {
    try {
      if (ids.includes("*")) {
        await confirmModal({
          title: `You are about to reassign ${totalCount} leads.`,
          content: "This may affect the focus views, distribution, automation rules, and other areas of the platform depending on your configurations.",
          label: "CONFIRM"
        });
      }
      const { data: { leads: { edges = [] } = {} } = {} } = await loadMismatchLeads({ variables: { ids, states: selected.licensedStates } });
      if (edges.length) {
        destroyHandler();
        const { destroy: destroyWarningDialog } = modal(StateWarningDialog, {
          closeIcon: true,
          leads: edges.map(({ node }) => `${node.borrowerFirstName} ${node.borrowerLastName}`),
          onAssignAllAnyway: async () => {
            await assignLeads(ids);
            destroyWarningDialog();
          },
          onSkipMismatches: async () => {
            let filteredIds = ids.filter(item => !edges.map(({ node }) => node.id).includes(item))
            if(filteredIds.length)
              await assignLeads(filteredIds);
            else
              info(`No leads left after skipping mismatched leads.`, {
                autoClose: false
              });
            destroyWarningDialog();
          },
          onClose: () => {
            destroyWarningDialog();
          }
        });
      } else {
        await assignLeads(ids);
      }
    } catch {

    }
  };

  const assignLeads = async (ids) => {
    const where = ids.includes("*") ? queryWhere : {
      id: {
        in: ids
      }
    };
    updateLeads({
      variables: {
        where: where,
        fields: {
          assignedAt: new Date(),
          assignedTo: {
            link: selected.value
          },
          assignedUserTeam: selected.team
        }
      }
    }).catch((e) => {
      console.error(e);
    });
    destroyHandler();
  };

  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    disableBackdropClick={false}
    title={"Assign to a user"}
    className={CommonModalClasses.BulkModal}
  >
    <ModalBody container direction={"column"} flex={1} className={CommonModalClasses.Container}>
      <SearchInput value={search} onChange={setSearch}/>
      <div className={CommonModalClasses.ScrollContainer}>
        {
          userLoading ?
            <BackDropLoader/>
            :
            items.map((item, index) => {
              return <MenuItem
                className={CommonModalClasses.Item}
                disabled={item.disabled}
                selected={item.value == selected?.value}
                key={index}
                thumbnail={<Avatar text={item.label} icon={item.icon}/>}
                onClick={(e) => {
                  console.info(item);
                  handleSelect(item);
                }}>
                <Typography
                  size={item.disabled ? TypographySize.TextTiny : TypographySize.Text}
                  color={item.disabled ? TypographyColor.Secondary : TypographyColor.ExtraDark}
                >
                  {item.disabled ? item.label.toUpperCase() : item.label}
                </Typography>
              </MenuItem>;
            })
        }
      </div>
    </ModalBody>
    <ModalFooter borderOnTop>
      <Button variant={ButtonVariants.Outline} onClick={onClose}>CANCEL</Button>
      <Button onClick={handleSubmit} disabled={!selected || loading}>ASSIGN</Button>
    </ModalFooter>
  </Modal>;
});

export const UPDATE_LEADS = gql`
  mutation UpdateLeads($where:LeadWhereInput!,$fields:UpdateLeadFieldsInput!) {
    updateLeads(where:$where,fields:$fields)
  }
`;

const ALL_MISMATCHES_LEADS = gql`
  query GetMismatchesLeadsByUserLicensedStates($ids:[ID], $states: [String]) {
    leads(where: {
      AND: [
        {id: {in: $ids}}
        {propertyState: {notIn: $states}}
        {propertyState: {notEqualTo: null}}
      ]
    }) {
      edges {
        node {
          id
          objectId
          borrowerFirstName
          borrowerLastName
          propertyState
        }
      }
    }
  }
`;
