import React                 from "react";
import { useCallback }       from "react";
import { useMemo }           from "react";
import { FormApi }           from "@relcu/form";
import { Form }              from "@relcu/form";
import { Field }             from "@relcu/form";
import { Typography }        from "@relcu/rc";
import { defaultValidator }  from "@relcu/ui";
import { UrlField }          from "@relcu/ui";
import { deepClone }         from "@relcu/ui";
import { AlertColors }       from "@relcu/ui";
import { Alert }             from "@relcu/ui";
import { PhoneNumberField }  from "@relcu/ui";
import { ChoiceField }       from "@relcu/ui";
import { FilterBuilder }     from "@relcu/ui";
import { deepPick }          from "@relcu/ui";
import { formUtils }         from "@relcu/ui";
import { ButtonVariants }    from "@relcu/ui";
import { Button }            from "@relcu/ui";
import { ModalFooter }       from "@relcu/ui";
import { SwitchField }       from "@relcu/ui";
import { TextField }         from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { Modal }             from "@relcu/ui";
import { defaultMutators }   from "@relcu/ui";
import { ModalBody }         from "@relcu/ui";
import { layoutVar }         from "../../../../reactiveVars";
import { PointerField }      from "../../Field/PointerField";
import { useJqlForm }        from "../../Jql/JqlForm";
import { FilterContext }     from "../PageViewDialog/Steps/Filter";
import { FilterValueEditor } from "../PageViewDialog/Steps/Filter/FilterValueEditor";
import { SchemaDialogProps } from "../SchemaDialog";
import { useSchemaDialog }   from "../SchemaDialog";
import "./phone-line-dialog.css";

export interface PhoneLineDialogProps extends SchemaDialogProps {

}

export const PhoneLineDialog = React.memo<PhoneLineDialogProps>(function PhoneLineDialog(props) {
    const layouts = layoutVar();
    const {
      initialValues,
      user,
      sections,
      jql,
      formProps,
      action,
      className,
      afterSubmit,
      loading,
      title,
      ...modal
    } = useSchemaDialog(props);

    const leadFilters = useMemo(() => {
      const leadFilters = deepClone(layouts.Settings.tabs.find(t => t.path == "queue-settings").properties.dialog.properties.leadFilters);
      return leadFilters.filter(leadFilter => {
        if (!["leadSource.objectId", "leadSourceId", "leadSourceCampaign"].includes(leadFilter.name)) {
          for (let operator of leadFilter.operators) {
            if (operator.name == "notNull") {
              operator.name = "exists";
            }
            if (operator.name == "is null") {
              operator.name = "notExists";
            }
          }
          return true;
        }
      });
    }, [layouts]);
    const initial = useMemo(() => {
      const fields = formUtils.getFieldNames(sections);
      const data = deepPick(initialValues || {}, fields);
      if (!data.callRouteToUserRules?.rules?.length) {
        data.callRouteToUserRules = {
          "combinator": "and",
          "rules": [{
            "field": "assignedTo.objectId",
            "id": "r-default-1",
            "operator": "exists",
            "valueSource": "value",
            "value": ""
          }]
        };
      }
      return data;
    }, [initialValues]);
    const jqlForm = useJqlForm({
      jql, className, sections, initialValues,
      getErrorMessage(e, errors) {
        return e[ Object.keys(e)[ 0 ] ];
      },
      beforeSubmit: (values, formApi: FormApi) => {
        const registeredFields = formApi.getRegisteredFields();
        Object.keys(values).forEach((key) => {
          if (!registeredFields.includes(key)) {
            delete values[ key ];
          }
        });
      },
      afterSubmit: () => props.onClose()
    });

    const validate = useCallback((values) => {
      const errors = {};
      const { assignedTo, callRouteToUser, callRouteToUserRules } = values;
      const isCampaign = assignedTo?.__typename == "LeadSource";
      if (isCampaign) {
        const isValid = !Object.values(defaultValidator(callRouteToUserRules)).filter(rv => (typeof rv !== "boolean") || rv === false).length;
        if (!isValid) {
          errors[ "callRouteToUserRules" ] = "Filtering is incomplete.";
        }
      }
      return errors;
    }, []);
    return (
      <Modal
        {...modal}
        className={"phone-line-dialog"}
        variant={"big"}
        open={props.open}
        onClose={props.onClose}
        title={`Edit phone line`}
        closeIcon={false}
        disableBackdropClick={false}
      >
        <Form
          onSubmit={jqlForm.onSubmit}
          validate={validate}
          initialValues={initial}
          subscription={{ submitting: true, values: true }}
          mutators={defaultMutators}>
          {
            ({ form, values, handleSubmit, submitting }) => {
              return <form
                onSubmit={handleSubmit}
                noValidate
                style={{ display: "contents" }}>
                <ModalBody style={{ position: "relative" }} container direction={"column"} gap={"XS"} flexGrow={1}
                           className="base-dialog-container">
                  <Box gap={"XS"} container direction="row" style={{ padding: "12px 8px" }}>
                    <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                      <TextField
                        name={`name`}
                        halfSize
                        label={"Name"}
                        required={true}
                        flex={1}
                      />
                    </Box>
                    <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                      <SwitchField name={"common"} label={"Common"}/>
                    </Box>
                  </Box>
                  <Box gap={"XS"} container direction="row" style={{ padding: "12px 8px" }}>
                    <Field name={"common"} subscription={{ value: true }}>
                      {({ input }) => (
                        !input.value && <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                          <PointerField placeholder={"Assigned To"}
                                        optionKey={"objectId"}
                                        optionLabel={"objectName"}
                                        label={"Assigned to"}
                                        name={"assignedTo"}
                                        flexGrow={1}
                                        targetClass={["User", "LeadSource"]}/>

                        </Box>
                      )}
                    </Field>
                    <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                      <TextField
                        name={`number`}
                        halfSize
                        disabled
                        label={"Number"}
                        required={true}
                        placeholder={"Email prefix"}
                        flex={1}
                      />
                    </Box>
                  </Box>

                  <Field name={"common"} subscription={{ value: true }}>
                    {

                      ({ input, meta }) => {
                        return !input.value && <Field name={"assignedTo"} subscription={{ value: true }}>
                          {
                            ({ input, meta }) => {
                              return input.value?.__typename == "LeadSource" && <>
                                <Box gap={"XS"} container direction={"column"}>
                                  <Box style={{ padding: "0 8px" }}>
                                    <Typography variant={"base16"} weights={"medium"}>Lead matching</Typography>
                                  </Box>
                                  <SwitchField name={"matchWithinLeadSource"} placeholder={"Match lead only in selected lead source"} />
                                </Box>
                                <Box gap={"XS"} container direction={"column"}>
                                  <Box style={{ padding: "0 8px" }}>
                                    <Typography variant={"base16"} weights={"medium"}>Call routing</Typography>
                                  </Box>
                                  <SwitchField name={"callRouteToUser"} placeholder={"Always route to assigned user"}/>
                                </Box>
                                <Box gap={"XS"} container direction="column" style={{ padding: "0 8px" }}>

                                  <Field name={"callRouteToUser"} subscription={{ value: true }}>
                                    {
                                      ({ input, meta }) => {
                                        return <>
                                          {!input.value && <FilterContext.Provider value={{ schemaName: "Lead" }}>
                                            <Box>
                                              <Typography variant={"base16"} weights={"medium"}>Route call
                                                when</Typography>
                                            </Box>
                                            <Field name={"callRouteToUserRules"}>
                                              {
                                                ({ input, meta }) => {
                                                  const error = meta.error;
                                                  return <><FilterBuilder
                                                    maxGroupLevel={0}
                                                    query={input.value}
                                                    onQueryChange={(data) => {
                                                      // onChange();
                                                      input.onChange(data);
                                                    }}
                                                    valueEditor={FilterValueEditor}
                                                    fields={leadFilters}
                                                  />
                                                    {
                                                      error && meta.touched &&
                                                      <Alert stretch variant={AlertColors.Error}>
                                                        {error}
                                                      </Alert>
                                                    }
                                                  </>;
                                                }
                                              }
                                            </Field>

                                          </FilterContext.Provider>
                                          }
                                        </>;
                                      }
                                    }
                                  </Field>
                                </Box>
                                <Box style={{ padding: "0 8px" }}>
                                  <Typography variant={"base16"} weights={"medium"}>Hold media</Typography>
                                </Box>
                                <Box gap={"XS"} container direction="row" style={{ padding: "0 8px" }}>
                                  <UrlField
                                    name={`holdMediaUrl`}
                                    halfSize
                                    required
                                    label={"Hold media URL"}
                                    flex={1}
                                  />
                                  <ChoiceField
                                    options={[
                                      { label: "Do not repeat", value: 1 },
                                      { label: "Infinite", value: 1000 },
                                      { label: "2", value: 2 },
                                      { label: "4", value: 4 },
                                      { label: "6", value: 6 },
                                      { label: "8", value: 8 }
                                    ]}
                                    required
                                    name={`holdMediaRepeat`}
                                    halfSize
                                    label={"Media repetition"}
                                    flex={1}
                                  />
                                </Box>
                                <Field name={"holdMediaRepeat"}>
                                  {
                                    ({ input, meta }) => {

                                      return input.value !== 1000 && <>
                                        <Box style={{ padding: "0 8px" }}>
                                          <Typography variant={"base16"} weights={"medium"}>If no answer</Typography>
                                        </Box>
                                        <Box gap={"XS"} container direction="row" style={{ padding: "0 8px" }}>
                                          <ChoiceField
                                            required
                                            options={[
                                              { label: "Drop", value: "drop" },
                                              { label: "Forward", value: "forward" },
                                              { label: "Prompt voicemail", value: "voicemail" }
                                            ]}
                                            name={`resolution`}
                                            halfSize
                                            label={"Resolution"}
                                            flex={1}
                                          />
                                          <Field name={"resolution"} subscription={{ value: true }}>
                                            {
                                              ({ input, meta }) => {
                                                return <>
                                                  {input.value == "forward" &&
                                                    <PhoneNumberField
                                                      required
                                                      name={"forwardToNumber"}
                                                      label={"To"}
                                                      flex={1}
                                                    />}
                                                  {input.value == "voicemail" &&
                                                    <UrlField name={`voicemailMediaUrl`} required halfSize
                                                              label={"Voicemail media URL"}/>}
                                                </>;
                                              }
                                            }
                                          </Field>
                                        </Box>
                                      </>;
                                    }}
                                </Field>

                              </>;
                            }
                          }
                        </Field>;
                      }}
                  </Field>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={submitting} type="button" variant={ButtonVariants.Outline}
                          onClick={props.onClose}>CANCEL</Button>
                  <Button disabled={submitting} type="submit">{action.toUpperCase()}</Button>
                </ModalFooter>
              </form>;
            }
          }
        </Form>
      </Modal>
    );
  }
);
