import { gql }                               from "@apollo/client";
import { ApolloClient }                      from "@apollo/client";
import { Subscription }                      from "zen-observable-ts";
import { ActiveCall_conferences_node_calls } from "./__types__/ActiveCall";
import { ActiveCall_conferences_node }       from "./__types__/ActiveCall";
import { ActiveCall }                        from "./__types__/ActiveCall";
import { GetActiveCall }                     from "./__types__/GetActiveCall";
import { HoldParticipantVariables }          from "./__types__/HoldParticipant";
import { HoldParticipant }                   from "./__types__/HoldParticipant";
import { InviteParticipantVariables }        from "./__types__/InviteParticipant";
import { InviteParticipant }                 from "./__types__/InviteParticipant";
import { LeadMembersVariables }              from "./__types__/LeadMembers";
import { LeadMembers }                       from "./__types__/LeadMembers";
import { RemoveParticipantVariables }        from "./__types__/RemoveParticipant";
import { RemoveParticipant }                 from "./__types__/RemoveParticipant";
import { Client }                            from "./Client";
import { GetSettings }                       from "./graph/__types__/GetSettings";
import { ViewerPhoneLines }                  from "./graph/__types__/ViewerPhoneLines";
import { ViewerTwToken }                     from "./graph/__types__/ViewerTwToken";
import { VIEWER_PHONE_LINES }                from "./graph/operations.graphql";
import { GET_SETTINGS }                      from "./graph/operations.graphql";
import { TIME_ZONE_CONTACT_NAME_FRAGMENT }   from "./graph/operations.graphql";
import { VIEWER_TW_TOKEN }                   from "./graph/operations.graphql";
import { audioNotificationOutputIdVar }      from "./reactiveVars";
import { fullScreenVar }                     from "./reactiveVars";
import { AudioDevicesVar }                   from "./reactiveVars";
import { AudioDevice }                       from "./reactiveVars";
import { audioDevicesVar }                   from "./reactiveVars";
import { audioInputIdVar }                   from "./reactiveVars";
import { audioOutputIdVar }                  from "./reactiveVars";
import { deviceVar }                         from "./reactiveVars";
import { Device as TwDevice }                from "./types/twilio";
import { TwilioError }                       from "./types/twilio";
import { Call }                              from "./types/twilio";
import { checkTimezone, toNodeId }           from "./utils/helpers";
import { HybridCall, HybridCalls }           from "./types/hybridCall";

declare const Twilio: {
  Call: typeof Call
  Device: typeof TwDevice
};

const relcuCallStatuses = new Set(["ringing", "in-progress"]);
const activeCallStatuses = new Set(["connecting", "pending", "ringing", "queued", "in-progress", "initiated", "invited", "open"]); //todo check maybe invited not need

export class Tw {
  #device: TwDevice;
  #activeCall: Call;
  #relcuCall: ActiveCall_conferences_node;
  #relcuCallSubscription: Subscription;
  #client: ApolloClient<object>;
  #token: string;
  get device() {
    return this.#device;
  }
  constructor(client: Client) {
    this.#client = client.client;
    client.soundPreferences.addEventListener("change", () => {
      if (this.device) {
        this.getAvailableSpeakers().map((d: any) => {
          this.device.audio.availableOutputDevices.set(d.deviceId, d);
        });
        this.getAvailableMicrophones().map((d: any) => {
          this.device.audio.availableInputDevices.set(d.deviceId, d);
        });
        this.device.audio.ringtoneDevices.set(this.getActiveSpeaker());
        this.device.audio.speakerDevices.set(this.getActiveSpeaker());
        if (this.device.isBusy) {
          this.device.audio.setInputDevice(this.getActiveMicrophone());
        }
      }
    });
    // navigator.mediaDevices.addEventListener("devicechange", () => this.#setupDevices());
  }
  get tokenRefreshTime() {
    const parsedToken = this.parseJwt(this.#token);
    return (parsedToken.exp - parsedToken.iat) * 850;
  }
  set relcuCall(call) {
    this.#relcuCall = call;
    // deviceVar({ ...deviceVar(), relcuCall: call, activeCall: call });
    //todo not no is there would be a side effects if updateDeviceVar will call, so I just update activeCall but need to check

    this.#updateDeviceVar();
  }

  get active() {
    return this.#getConnection(this.#activeCall);
  }

  get relcuCall() {
    return this.#relcuCall;
  }

  get isConference() {
    return !!this.relcuCall?.conferenceSid;
  }

  #transformToHybridCall(call: ActiveCall_conferences_node_calls): HybridCall {
    const { party, ...rest } = call;

    const transformedCall = {
      ...rest,
      id: party.id,
      objectId: party.objectId,
      objectName: party.objectName,
      objectIcon: party.objectIcon,
      __typename: party.__typename
    };

    if (party.__typename == "Contact") {
      transformedCall[ "company" ] = party.company;
    }

    if (party.__typename == "User") {
      transformedCall[ "role" ] = party.role;
      transformedCall[ "licensedStates" ] = party.licensedStates;
    }

    return transformedCall as HybridCall;
  }

  #getMyCall(caller: HybridCall, called: HybridCall, parties: HybridCall[], direction: "incoming" | "outgoing"): HybridCall {
    const { phoneLines, objectId } = this.viewerUserInfo;
    let myCall;

    if (parties?.length > 0) {
      myCall = parties?.find(c => c.objectId == objectId && activeCallStatuses.has(c.status));//(c.status == "ringing" || c.status == "queued" || c.status == "in-progress")
    }

    if (!myCall && activeCallStatuses.has(called.status)) {
      myCall = (called?.objectId == objectId || phoneLines.edges.find(({ node }) => node.number == called.number) || direction == "incoming") && called;
    }

    if (!myCall && activeCallStatuses.has(caller.status) && !myCall) {
      myCall = (caller?.objectId == objectId || phoneLines.edges.find(({ node }) => node.number == caller.number) || direction == "outgoing") && caller;
    }

    return myCall;
  }

  get calls() {
    const active = this.active;
    if (!active) {
      return null;
    }

    const relcu = this.relcuCall;
    const parties = relcu?.calls.filter((c) => c.type != "caller" && c.type != "called")?.map(party => this.#transformToHybridCall(party)) ?? [];
    let contactInfo = {
      company: active.params.contactCompany,
      objectId: active.params.contactId,
      scopeId: active.params.scopeId,
      scopeName: active.params.scopeName,
      scopeClassName: active.params.scopeClassName,
      objectName: active.params.contactName,
      number: active.direction == "outgoing" ? active.params.to : active.params.from,
      status: active.status//not sure putting active status as call status is a right idea but its need to fast open and call render
    };
    let caller: HybridCall = active.direction == "outgoing" ?
      {
        status: active.status,//not sure putting active status as call status is a right idea but its need to fast open and call render
        number: active.params.from,
        scopeId: active.params.scopeId,
        scopeName: active.params.scopeName,
        scopeClassName: active.params.scopeClassName
      } :
      contactInfo;
    let called: HybridCall = active.direction == "incoming" ?
      {
        status: active.status,//not sure putting active status as call status is a right idea but its need to fast open and call render
        number: active.params.to,
        scopeId: active.params.scopeId,
        scopeName: active.params.scopeName,
        scopeClassName: active.params.scopeClassName
      } :
      contactInfo;

    if (window.__CONFIG__.advancedPhone && relcu) {
      const relcuCaller = relcu?.calls.find((c) => c.type == "caller");
      const relcuCalled = relcu?.calls.find((c) => c.type == "called");

      caller = {
        ...caller,
        ...this.#transformToHybridCall(relcuCaller)
      };
      called = {
        ...called,
        ...this.#transformToHybridCall(relcuCalled)
      };
    }

    const calls: HybridCall[] = [caller, called, ...parties];
    const myCall: HybridCall = this.#getMyCall(caller, called, parties, active.direction);
    if (myCall) {
      myCall[ "callSid" ] = active.callSid;
      myCall[ "muted" ] = active.muted;
    }

    let initialDisplayCall = active.direction == "incoming" ? caller : called;
    const activeCalls = calls.filter(call => activeCallStatuses.has(call.status) && (call.number != myCall?.number));//todo initiated possibly not need

    if (myCall?.type == "party") {
      initialDisplayCall = calls.find(c => c.type != "party" && c.__typename == "Contact");
    }

    return {
      caller,
      called,
      parties,
      myCall,
      calls,
      initialDisplayCall,
      activeCalls
    };
  }

  getAvailableSpeakers() {
    const devices = audioDevicesVar();
    return devices.audiooutput;
  }
  getAvailableMicrophones() {
    const devices = audioDevicesVar();
    return devices.audioinput;

  }

  getActiveSpeaker() {
    const devices = audioDevicesVar();
    const selectedDeviceId = audioOutputIdVar();
    const selectedDevice = devices.audiooutput.find(d => d.deviceId == selectedDeviceId);

    return selectedDevice?.deviceId || devices.audiooutput[ 0 ]?.deviceId || null;
  }
  getActiveMicrophone() {
    const devices = audioDevicesVar();
    const selectedDeviceId = audioInputIdVar();
    const selectedDevice = devices.audioinput.find(d => d.deviceId == selectedDeviceId);

    return selectedDevice?.deviceId || devices.audioinput[ 0 ]?.deviceId || null;
  }

  async refreshToken() {
    const { data: { viewer: { twilioToken } = {} } = {} } = await this.#client.query<ViewerTwToken>({
      query: VIEWER_TW_TOKEN,
      fetchPolicy: "network-only"
    });
    this.#token = twilioToken;
    this.#log("TW token refreshed");
  }
  async reconnect() {
    const currentTokenParsed = this.parseJwt(this.#token);
    await this.refreshToken();
    if ((currentTokenParsed.exp * 1000) - Date.now() <= 0) {
      this.destroy();
      this.setup();
    } else {
      if (this.#device.state !== "unregistered") {
        await this.device.updateToken(this.#token);
      }
      //todo in ongoing call case after reconnect there is no call but device busy is true
      if (this.#activeCall && !this.device?.calls?.length && !this.device.isBusy) {
        this.#cleanActiveCAll();
        this.#updateDeviceVar();
      } else if (this.#activeCall?.parameters?.CallSid) {
        if (!this.#relcuCallSubscription?.closed) {
          await this.#subscribeRelcuCall(this.#activeCall.parameters.CallSid);
        }
        await this.#getRelcuCall(this.#activeCall.parameters.CallSid);
      }
    }
  }
  parseJwt(token) {
    try {
      return JSON.parse(atob(token.split(".")[ 1 ]));
    } catch (e) {
      return null;
    }
  };

  setup() {
    const token = this.viewerToken;
    if (token) {
      this.#token = token;
      const additionalSettings = window.__CONFIG__.twilioOptions ? window.__CONFIG__.twilioOptions : {};
      this.#device = new Twilio.Device(token, {
        allowIncomingWhileBusy: true,
        appName: "@relcu/web",
        appVersion: window.__CONFIG__.versions.web,
        codecPreferences: ["opus", "pcmu"],
        ...additionalSettings
      });
      (this.device.audio as any).incoming(false);
      this.#device.on("registered", this.#onRegistered.bind(this));
      this.#device.on("registering", this.#onRegistering.bind(this));
      this.#device.on("unregistered", this.#onUnRegistered.bind(this));
      this.#device.on("error", this.#onError.bind(this));
      this.#device.on("incoming", this.#onIncoming.bind(this));
      this.#device.on("destroyed", this.#onDestroyed.bind(this));
      this.#device.on("tokenWillExpire", this.#onTokenWillExpire.bind(this));
      this.#device.register().catch(console.error);
      // this.#setupDevices().catch(console.error);
    }
  }
  destroy() {
    if (this.#device) {
      this.#device.destroy();
      this.#device = null;
    }
  }
  #onError(error: TwilioError) {
    this.#log("OnError ", error);
    if ([31202, 31205, 20104].includes(error.code)) {
      this.refreshToken().then(() => {
        this.device.updateToken(this.#token);
      });
    }
    if (error.code == 31201) {
      alert("Error occurred while accessing microphone");
    }
    if (error.code == 31206) {
      this.#log("Rate limit reached, recreating device");
      this.destroy();
      this.setup();
    }
  }
  #onRegistered() {
    this.#log("The device is ready to receive incoming calls.", this.device);
    this.#updateDeviceVar();
  }
  #onRegistering() {
    this.#log("Registering device", this.device);
    this.#updateDeviceVar();
  }
  #onUnRegistered(device: Device) {
    this.#log("UnRegistering device", device);
    if (this.#activeCall?.status() === "closed") {
      this.destroy();
      this.setup();
      return;
    }
    this.#updateDeviceVar();
  }
  #onDestroyed(device: TwDevice) {
    this.#updateDeviceVar();
  }
  #onTokenWillExpire(device: TwDevice) {
    this.#log("On token will expire");
    this.refreshToken().then(async () => {
      this.device.updateToken(this.#token);
    });
  }
  async #onIncoming(call: Call) {
    if (this.device?.isBusy || !!this.#activeCall) {
      this.#log("Rejected <-:", this.device?.isBusy, !!this.#activeCall);
      call.reject();
      return;
    }

    fullScreenVar(false);
    const sessionId = sessionStorage.getItem("sessionId");
    this.#setActiveCall(call);
    if (call.customParameters.get("autoAnswer") && !this.device?.isBusy) {
      if (sessionId == call.customParameters.get("sessionId")) {
        await this.#acceptCall(call);
      } else {
        call.ignore();
      }
    }
    this.#updateDeviceVar();
  }
  async #acceptCall(call: Call) {
    await this.#device.audio.setInputDevice(this.getActiveMicrophone());
    await this.#device.audio.speakerDevices.set(this.getActiveSpeaker());
    await this.#device.audio.ringtoneDevices.set(this.getActiveSpeaker());
    call.accept();
  }
  get settings() {
    const { settings } = this.#client.readQuery<GetSettings>({ query: GET_SETTINGS });
    return settings;
  }
  get viewerToken() {
    const { viewer } = this.#client.readQuery<ViewerTwToken>({ query: VIEWER_TW_TOKEN });
    return viewer?.twilioToken;
  }
  get viewerUserInfo() {
    const { viewer: { user: { phoneLines, objectId } } = {} } = this.#client.readQuery<ViewerPhoneLines>({ query: VIEWER_PHONE_LINES });
    return { objectId, phoneLines };
  }
  async checkTimezone(scopeId: string) {
    const { data: { lead } = {} } = await this.#client.query<LeadMembers, LeadMembersVariables>({
      fetchPolicy: "cache-first",
      query: LEAD_MEMBERS,
      variables: {
        id: toNodeId({ className: "Lead", objectId: scopeId })
      }
    });
    return checkTimezone(lead, this.settings);
  }
  #log(...args) {
    console.log(...args);
    window[ "DD_LOGS" ]?.logger?.log(...args);
  }
  async #getRelcuCallData(sid) {
    if (window.__CONFIG__.advancedPhone && sid) {
      await this.#subscribeRelcuCall(sid);
      await this.#getRelcuCall(sid);
    }
  }

  async #getRelcuCall(sid: string) {
    const { data } = await this.#client.query<GetActiveCall>({
      fetchPolicy: "network-only",
      query: gql`
        query GetActiveCall {
          conferences(
            where:{
              calls:{
                have:{
                  call: {equalTo: "${sid}"}
                }
              }
            }
          ) {
            edges {
              node {
                ...Conference
              }
            }
          }
        }
      ${CONFERENCE_CALL_FRAGMENT}`
    });

    if (data.conferences.edges[ 0 ]) {
      this.#relcuCall = data.conferences.edges[ 0 ].node;
      this.#updateDeviceVar();
    }
  }

  async #subscribeRelcuCall(sid) {
    const subscribe = await this.#client.subscribe<ActiveCall>({
      query: gql`
        subscription ActiveCall{
          conferences(
            where:{
              calls:{
                have:{
                  call: {equalTo: "${sid}"},
                }
              }
            },
            events: [UPDATE]
          ){
            event
            node{
              ...Conference
            }
          }
        }
      ${CONFERENCE_CALL_FRAGMENT}`
    });

    this.#relcuCallSubscription = subscribe.subscribe((message) => {
      if (this.#activeCall) {
        this.relcuCall = message.data?.conferences?.node;
      }
    });
  }
  #getConnection(c: Call) {
    if (c) {
      const { parameters, direction, customParameters } = c;
      const message = Object.fromEntries<any>(customParameters.entries());
      return <DeviceCall>{
        acceptedAt: c[ "acceptedAt" ],
        direction: String(direction).toLowerCase(),
        warnings: c[ "warnings" ],
        status: c.status(),
        callSid: parameters.CallSid,
        get from() {
          return parameters.From || message.from;
        },
        get to() {
          return parameters.To || message.to;
        },
        params: (message) as DeviceCallParams,
        muted: c.isMuted(),
        //todo need to remove everything from active, except twilio actions

        accept: () => {
          return this.#acceptCall(c);
        },
        reject: () => {
          console.info("REJECT", c);
          c.reject();
        },
        drop: () => c.disconnect(),
        sendDigits: (digits) => c.sendDigits(digits),
        mute: (state) => {
          c.mute(state);
          this.#updateDeviceVar();
        }
      };
    }
  };
  #updateDeviceVar() {
    const active = this.active;
    const calls = this.#device.calls.map(this.#getConnection).filter(c => !!c); //&& c.callSid != active.callSid
    const hybridCalls = this.calls;

    const value: Device = {
      state: this.#device.state,
      active, //remove direction form active
      isBusy: this.device?.isBusy,
      audio: this.#device.audio,
      status: relcuCallStatuses.has(hybridCalls?.myCall?.status) ? hybridCalls?.myCall?.status : active?.status,
      hybridCalls: hybridCalls,
      isConference: this.isConference,
      direction: active?.direction,
      advancedPhone: window.__CONFIG__.advancedPhone,
      incoming: calls,
      invite: async (from, to, holdId) => {
        try {
          const { data: { inviteParticipant: conference } = {} } = await this.#client.query<InviteParticipant, InviteParticipantVariables>({
            fetchPolicy: "no-cache",
            query: INVITE_PARTICIPANT,
            variables: { conferenceId: this.#relcuCall.objectId, from, to }
          });
          if (holdId) {
            await this.#client.query<HoldParticipant, HoldParticipantVariables>({
              fetchPolicy: "no-cache",
              query: HOLD_PARTICIPANT,
              variables: { conferenceSid: conference.conferenceSid, callSid: holdId, hold: true }
            });
          }

        } catch (e) {
          console.error("ERROR INVITE", e);
        }
      },
      remove: async (removeId, unHoldId) => {
        try {
          await this.#client.query<RemoveParticipant, RemoveParticipantVariables>({
            fetchPolicy: "no-cache",
            query: REMOVE_PARTICIPANT,
            variables: { conferenceSid: this.#relcuCall.conferenceSid, callId: removeId }
          });

          if (unHoldId) {
            await this.#client.query<HoldParticipant, HoldParticipantVariables>({
              fetchPolicy: "no-cache",
              query: HOLD_PARTICIPANT,
              variables: { conferenceSid: this.#relcuCall.conferenceSid, callSid: unHoldId, hold: false }
            });
          }
        } catch (e) {
          console.error("ERROR REMOVE", e);
        }

      },
      unHold: async (holdId: string, hold: boolean) => {
        await this.#client.query<HoldParticipant, HoldParticipantVariables>({
          fetchPolicy: "no-cache",
          query: HOLD_PARTICIPANT,
          variables: { conferenceSid: this.#relcuCall.conferenceSid, callSid: holdId, hold: hold ?? false }
        });
      },
      call: async ({ markAsRead, ...params }: DeviceCallParams) => {
        const isLead = params.scopeClassName == "Lead"
        //const hasScope = !!params.scopeClassName
        const tzConfirmed = isLead && await this.checkTimezone(params.scopeId);
        // if(isLead && !tzConfirmed){
        //     return false;
        // }
        
        if (!isLead || tzConfirmed) {
          params[ "From" ] = params.from;
          params[ "To" ] = params.to;
          await this.#device.audio.speakerDevices.set(this.getActiveSpeaker());
          await this.#device.audio.setInputDevice(this.getActiveMicrophone());
          await this.#device.audio.ringtoneDevices.set(this.getActiveSpeaker());
          const call = await this.device.connect({ params } as any);
          this.#setActiveCall(call);
          this.#updateDeviceVar();
          markAsRead?.(params.to);
          return true;
        }
        return false;
      }
    };
    deviceVar(value);
  }
  #setActiveCall(call: Call) {
    this.#activeCall = call;
    if (call.direction == "INCOMING") {
      this.#getRelcuCallData(call.parameters?.CallSid).catch(console.error);
    }
    this.#activeCall.on("accept", async (call) => {
      call[ "acceptedAt" ] = new Date();
      if (call.direction == "OUTGOING") {
        await this.#getRelcuCallData(call.parameters?.CallSid).catch(console.error);
      }
      this.#updateDeviceVar();
    });
    this.#activeCall.on("cancel", () => {
      console.info("on Call Cancel", call);
      this.#cleanActiveCAll();
      this.#updateDeviceVar();
    });
    this.#activeCall.on("reject", () => {
      this.#cleanActiveCAll();
      console.info("on Call Reject", call);
      this.#updateDeviceVar();
    });
    this.#activeCall.on("disconnect", async (call) => {
      this.#log("Disconnect");
      this.#cleanActiveCAll();
      await this.#device.audio.unsetInputDevice();
      this.#log("on Call Disconnect", call);
      this.#updateDeviceVar();
    });
    this.#activeCall.on("error", async (error: TwilioError) => {
      console.info("on Call Error", call, error);
      call.disconnect();
    });
    this.#activeCall.on("mute", (isMuted, call) => {
      this.#updateDeviceVar();
    });
    this.#activeCall.on("warning", (warning) => {
      this.#activeCall[ "warnings" ] ??= [];
      this.#activeCall[ "warnings" ].push(warning);
      this.#updateDeviceVar();
      console.info("on Call Warning", call, warning);
    });
    this.#activeCall.on("warning-cleared", (warning) => {
      this.#activeCall[ "warnings" ] ??= [];
      this.#activeCall[ "warnings" ] = this.#activeCall[ "warnings" ].filter(w => w !== warning);
      this.#updateDeviceVar();
      console.info("on Call WarningCleared", call, warning);
    });
    this.#activeCall.on("reconnecting", (args) => {
      console.info("on Call Reconnecting", args, call);
    });
    this.#activeCall.on("reconnected", () => {
      console.info("on Call Reconnected", call);
    });
    // this.#activeCall.on("")
    this.#activeCall.on("transportClose", async () => {
      console.info("on transportClose", call, call.status());

    });
  }
  #cleanActiveCAll() {
    if (this.#activeCall) {
      this.#activeCall.removeAllListeners("accept");
      this.#activeCall.removeAllListeners("cancel");
      this.#activeCall.removeAllListeners("reject");
      this.#activeCall.removeAllListeners("disconnect");
      this.#activeCall.removeAllListeners("error");
      this.#activeCall.removeAllListeners("mute");
      this.#activeCall.removeAllListeners("warning");
      this.#activeCall.removeAllListeners("warning-cleared");
      this.#activeCall.removeAllListeners("reconnecting");
      this.#activeCall.removeAllListeners("reconnected");
      this.#activeCall = null;
    }
    if (this.#relcuCallSubscription) {
      this.#relcuCallSubscription.unsubscribe();
      this.#relcuCallSubscription = null;
    }
    this.#relcuCall = null;
  }
  static #sortAudioDevices(devices: AudioDevice[]): AudioDevicesVar {
    const audioInput = new Map();
    const audioOutput = new Map();
    devices.forEach(device => {
      device.kind == "audiooutput" ?
        !audioOutput.has(device.label) && audioOutput.set(device.label, device)
        :
        !audioInput.has(device.label) && audioInput.set(device.label, device);
    });//not used groupId because in that case default devices lost

    return {
      audioinput: Array.from(audioInput.values()),
      audiooutput: Array.from(audioOutput.values())
    };
  }
}

export interface Device {
  state: string,
  isBusy?: boolean
  audio: any,
  active?: DeviceCall,
  status: string,
  hybridCalls: HybridCalls,
  direction: "incoming" | "outgoing",
  isConference?: boolean,
  advancedPhone: boolean,
  invite?(from: string, to: string, holdId: string),
  remove?(removeId: string, unHoldId?: string)
  unHold?(holdId: string, hold?: boolean),
  incoming: DeviceCall[],
  call?: (params: DeviceCallParams) => Promise<boolean>
}
export interface DeviceCall {
  direction: "outgoing" | "incoming";
  status: "pending" | "connecting" | "ringing" | "open" | "closed";
  from: string;
  to: string;
  warnings?: string[];
  params: DeviceCallParams;
  callSid: string;
  muted: boolean;
  accept(): void;
  acceptedAt: Date;
  sendDigits(digits: string): void;
  reject(): void;
  drop(): void;
  mute(muted: boolean): void;
}
export interface DeviceCallParams {
  contactId?: string;
  contactName?: string;
  contactCompany?: string;
  scopeId?: string;
  scopeName?: string;
  scopeClassName?: string;
  from?: string;
  to: string;
  markAsRead?(to: string);
}

export const CONFERENCE_CALL_FRAGMENT = gql`
  fragment Conference on Conference {
    objectId
    status
    sid
    missed
    unread
    direction
    startDate
    conferenceSid
    scope {
      ...on Document {
        objectName
        objectId
        objectIcon
      }
      ...on Node {
        id
      }
    }
    calls {
      status
      call
      number
      status
      type
      moderator
      hold
      startDate
      endDate
      party {
        ...on Node {
          id
        }
        ...on Document{
          objectId
          objectName
          objectIcon
        }
        ...on Contact {
          company
        }
        ...on User {
          role
          licensedStates
        }
      }
    }
  }
`;
export const HOLD_PARTICIPANT = gql`
  query HoldParticipant($callSid: String, $conferenceSid: String, $hold: Boolean) {
    holdParticipant(callSid: $callSid, conferenceSid: $conferenceSid, hold: $hold)
  }
`;

export const MUTE_PARTICIPANT = gql`
  query MuteParticipant($callSid: String, $conferenceSid: String, $mute: Boolean) {
    muteParticipant(callSid: $callSid, conferenceSid: $conferenceSid, mute: $mute)
  }
`;
export const INVITE_PARTICIPANT = gql`
  query InviteParticipant($conferenceId: String, $from: String, $to: String) {
    inviteParticipant(conferenceId: $conferenceId, from: $from, to: $to) {
      ...Conference
    }
  }
  ${CONFERENCE_CALL_FRAGMENT}
`;

export const REMOVE_PARTICIPANT = gql`
  query RemoveParticipant($conferenceSid: String, $callId: String) {
    removeParticipant(conferenceSid: $conferenceSid, callSid: $callId)
  }
`;

export const LEAD_MEMBERS = gql`
  query LeadMembers($id: ID!) {
    lead(id: $id) {
      ...LeadTimeZone
    }
  }
  ${TIME_ZONE_CONTACT_NAME_FRAGMENT}
`;
