import React                                              from "react";
import { CommonClasses }                                  from "@relcu/ui";
import { TimelineItem }                                   from "@relcu/ui";
import { TimelineItemIcon }                               from "@relcu/ui";
import { TimelineItemIconColors }                         from "@relcu/ui";
import { TimelineItemSubject }                            from "@relcu/ui";
import { Typography }                                     from "@relcu/ui";
import { TypographyColor }                                from "@relcu/ui";
import { TypographyWeight }                               from "@relcu/ui";
import { Link }                                           from "@relcu/react-router";
import { BulkStatusChangeActivity as BulkStatusChangeActivityType } from "../../../../__types__/BulkStatusChangeActivity";

export interface BulkStatusChangeActivityProps extends BulkStatusChangeActivityType {
    createdAt: string;
}

export const BulkStatusChangeActivity = React.memo<BulkStatusChangeActivityProps>(function BulkStatusChangeActivity(props) {
    const {number, statusChangedBy, statusNewValue, createdAt} = props;
    const statusChanger = statusChangedBy ? statusChangedBy.objectName : "Relcu";

    return <TimelineItem
        avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"flash_on"}/>}
        primary={<TimelineItemSubject>
            {statusChangedBy?.objectId && !statusChangedBy?.objectId.startsWith("int:") ?
                <Link to={`/user/${statusChangedBy.objectId}/lead`}>
                    <Typography className={CommonClasses.ClickableIcon}>{statusChanger}</Typography>
                </Link>
                :
                <Typography>{statusChanger}</Typography>
            }
            <Typography color={TypographyColor.Secondary}>updated status on </Typography>
            <Typography>{number? number: 'undefined'}</Typography>
            <Typography color={TypographyColor.Secondary}>leads to</Typography>

            <Typography weight={TypographyWeight.Medium}>{statusNewValue?.statusName || "Unknown"}</Typography>

        </TimelineItemSubject>}
        creationDate={createdAt}>
    </TimelineItem>;
});
