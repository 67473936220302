import { useMemo }                                                           from "react";
import { ReactNode }                                                         from "react";
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { MaskInput }                                                         from "../../..";
import { classNames }                                                        from "../../..";
import { Calendar }                                                          from "../../..";
import { FontIcon }                                                          from "../../..";
import { Label }                                                             from "../../..";
import { Box }                                                               from "../../..";
import { InputState }                                                        from "../../..";
import { useImperativeState }                                                from "../../..";
import { Alignment }                                                         from "../../../constants/Alignemnt";
import { BoxComponentProps }                                                 from "../../Box";
import { isValidDate }                                                       from "../../Calendar/date-fns";
import { Popper }                                                            from "../../Popper";
import { BaseInputProps }                                                    from "../BaseInput";
import { BaseInputClasses }                                                  from "../BaseInput";

export interface DatePickerProps extends BoxComponentProps, BaseInputProps {
  value?: Date
  icon?: ReactNode,
  type?: "date" | "datetime"
  message?: string
  readOnly?: boolean
  read?: boolean
  alwaysShowMask?: boolean
  allowNull?: false
  placeholder?: string
  onChange?(date)
  onBlur?(event)
  onFocus?(event)
  onApply?(Date)
  startYear?: any
  endYear?: any
  alignment?: Alignment
  dateBlocked?: (date: Date) => boolean
}

DatePicker.defaultProps = {
  disabled: false,
  readOnly: false,
  alignment: Alignment.BottomLeft
};
export function DatePicker(props: DatePickerProps) {
  const {
    alignment,
    dateBlocked,
    label,
    className,
    state,
    readOnly,
    required,
    halfSize,
    fullSize,
    allowNull,
    disabled,
    icon,
    message,
    alwaysShowMask,
    placeholder,
    onFocus,
    onBlur,
    type,
    onChange,
    value,
    startYear,
    endYear,
    onApply,
    ...p
  } = props;
  const [anchorBounding, setAnchorBounding] = useState(null);
  const [inputDate, setInputDate] = useImperativeState(value, onChange);
  const prevDateRef = useRef(null);
  const maskPlaceholder = useMemo(() => type === "datetime" ? "MM/DD/YYYY hh:mm __" : "MM/DD/YYYY", [type]);
  const mask = useMemo(() => type === "datetime" ? "99/99/9999 99:99 aa" : "99/99/9999", [type]);
  const ref: MutableRefObject<any> = useRef();
  let disabledMod = disabled || readOnly;
  const classes = classNames(BaseInputClasses.Input, {
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ BaseInputClasses.Active ]: !!anchorBounding
  }, DatePickerClasses.DateField, className);

  useEffect(() => {
    if (isValidDate(inputDate)) {
      prevDateRef.current = new Date(inputDate);
    }
  }, [inputDate]);

  const togglePopper = event => {
    setAnchorBounding(!anchorBounding ? ref.current.getBoundingClientRect() : null);
  };

  useEffect(() => {
    if (readOnly && anchorBounding) {
      setAnchorBounding(null);
    }//for keyPress case
  }, [readOnly]);

  return (
    <Box container
         className={classes}
         onFocus={(e) => onFocus && onFocus(e)}
         onBlur={(e) => onBlur && onBlur(e)}
         {...p}
    >
      <Box container flex={1} gap={"XXXS"} direction={"column"}>
        <Box container
             direction={"row"}
             alignItems={"center"}
             ref={ref}
             gap={"XXS"}
             className={BaseInputClasses.MainContainer}>
          {icon}
          <Box container direction={"column"} gap={"XXXS"} flex={1}
               className={BaseInputClasses.InputContainer}>
            {label && <Label required={required}>{label}</Label>}
            <Box container alignItems={"center"} className={DatePickerClasses.DateFieldPreview}>
              {/*{inputDate ? String(inputDate) : placeholder || maskPlaceholder || "Select value"}*/}
              {/*todo change input part on dropdowns*/}
              <MaskInput
                placeholder={placeholder || "n/a"}
                disabled={disabledMod}
                alwaysShowMask={alwaysShowMask}
                mask={mask}
                maskPlaceholder={maskPlaceholder}
                value={isValidDate(inputDate) ? String(inputDate) : inputDate}
                onChange={useCallback((e) => {
                  setInputDate(isValidDate(e.target.value) ? new Date(e.target.value) : e.target.value);
                }, [setInputDate])}
              />
            </Box>
          </Box>
          <Box container className={BaseInputClasses.StateContainer} gap={"XXS"}>
            {
              !readOnly &&
              <>
                <FontIcon type={"insert_invitation"} onClick={togglePopper}/>
                {/*{(state == InputState.Success || state == InputState.Error) &&*/}
                {/*<FontIcon item type={state == InputState.Success ? "done" : "warning"}/>}*/}
              </>
            }
          </Box>
        </Box>
        <Box className={BaseInputClasses.Message}>{message}</Box>
      </Box>
      <Popper onClickAway={useCallback(() => setAnchorBounding(null), [])} open={!!anchorBounding}
              anchorBounding={anchorBounding} alignment={alignment}>
        <Calendar
          yearProps={{ startYear, endYear }}
          type={type}
          className={DatePickerClasses.DateFieldCalendar}
          isDateBlocked={dateBlocked}
          range={false}
          date={useMemo(() => {
            if (!inputDate) {
              return undefined;
            }
            return isValidDate(inputDate) ? new Date(inputDate) : prevDateRef.current;
          }, [inputDate, prevDateRef.current])}
          onDatesChange={({ startDate, endDate, focusedInput }) => {
            setInputDate({ startDate, endDate }[ focusedInput ]);
            // setAnchorBounding(null);
          }}
          onApply={onApply ? () => {
            setAnchorBounding(null);
            onApply(inputDate);
          } : null}
        />
      </Popper>
    </Box>
  );
}

export enum DatePickerClasses {
  DateField = "date-field",
  DateFieldCalendar = "date-field-calendar",
  DateFieldPreview = "date-field__preview"
}
