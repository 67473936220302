import { schemaVar }            from "../reactiveVars";
import { IDateField }           from "../types/ISchemas";
import { IBaseField }           from "../types/ISchemas";
import { IAliasField }          from "../types/ISchemas";
import { ISchemas }             from "../types/ISchemas";
import { IBooleanField }        from "../types/ISchemas";
import { IChoiceField }         from "../types/ISchemas";
import { IStatusField }         from "../types/ISchemas";
import { IObjectField }         from "../types/ISchemas";
import { IArrayField }          from "../types/ISchemas";
import { IRelationField }       from "../types/ISchemas";
import { IPasswordField }       from "../types/ISchemas";
import { IPointerField }        from "../types/ISchemas";
import { IField }               from "../types/ISchemas";
import { toFirstLower }         from "./helpers";
import { transformNameToLabel } from "./helpers";

const keysCache = {};
const keysRegex = /[.[\]]+/;
export function toPath(key: string) {
  if (key === null || key === undefined || !key.length) {
    return [];
  }

  if (typeof key !== "string") {
    throw new Error("toPath() expects a string");
  }

  if (keysCache[ key ] == null) {
    keysCache[ key ] = key.split(keysRegex).filter(Boolean);
  }
  return keysCache[ key ]?.slice();
}
export function isPointerField(field: IField): field is IPointerField {
  return field?.type === "Pointer";
}
export function isPasswordField(field: IField): field is IPasswordField {
  return field?.type === "Password";
}
export function isRelationField(field: IField): field is IRelationField {
  return field?.type === "Relation";
}
export function isArrayField(field): field is IArrayField {
  return field?.type === "Array" && !!field?.targetClass;
}
export function isObjectField(field): field is IObjectField {
  return field?.type === "Object" && !!field?.targetClass;
}
export function isStatusField(field): field is IStatusField {
  return field?.type === "Status";
}
export function isChoiceField(field): field is IChoiceField {
  return field?.type === "Choice";
}
export function isBooleanField(field): field is IBooleanField {
  return field?.type === "Boolean";
}
export function isDateField(field): field is IDateField {
  return field?.type === "Date";
}
export function isReferenceField(field: IField) {
  return (
    isObjectField(field) ||
    isRelationField(field) ||
    isArrayField(field) ||
    isPointerField(field)
  );
}
export function isReservedField(fieldName: string) {
  return ["__typename"].includes(fieldName);
}
export function isAliasField(field): field is IAliasField {
  return !!field?.alias;
}
export function getFields(schemas: ISchemas, className: string) {
  const fields = schemas[ className ].fields;
  return Object.keys(fields).map(name => ({
    ...fields[ name ],
    name
  }));
}
export function getField(className: string, fieldName: string) {
  if (isReservedField(fieldName)) {
    return undefined;
  }
  let current;
  const schemas = schemaVar();
  let schema = schemas[ className ];
  const path = toPath(fieldName);
  for (let i = 0; i < path.length; i++) {
    const key = path[ i ];
    current = schema.fields[ key ];
    //TODO review the fix
    if (isReferenceField(current)) {
      if (Array.isArray(current.targetClass)) {
        return current;
      }
      schema = schemas[ current.targetClass ];
      if (!schema) {
        schema = current;
      }
    }
    if (isStatusField(current)) {
      return <any>current;
    }
    if (!schema) {
      return;
    }
    if (current === undefined || current === null || typeof current !== "object" || Array.isArray(current) && isNaN(key)) {
      return undefined;
    }
  }
  return current;
}
export function getNullValues(schemas: ISchemas, className: string) {
  return getFields(schemas, className).reduce((obj, field) => {
    obj[ field.name ] = null;
    if (isObjectField(field)) {
      obj[ field.name ] = getNullValues(schemas, field.targetClass);
    }
    obj[ "__typename" ] = className;
    return obj;
  }, {});
}
export function getFieldRecursion<F extends IBaseField = IField>(className: string, fieldName: string, resolveCross?: (s: string[]) => string): F {
  const path: string[] = toPath(fieldName);
  if (path.length == 1) {
    return getField(className, fieldName);
  }
  const name = path.shift();
  const field = getField(className, name);
  if (isReferenceField(field)) {
    let targetClass = field.targetClass;
    if (Array.isArray(targetClass)) {
      targetClass = resolveCross ? resolveCross(field.targetClass) : field.targetClass[ 0 ];
    }
    return getFieldRecursion(targetClass, path.join("."));
  }
  return field;
}

export function getSchema(className) {
  return schemaVar()[ className ];
}
export function getHumanized(classname: string, fieldName: string, value: any) {
  const field = getField(classname, fieldName);
  if (fieldName == "leadStatus.action" && field.actions[ value ]) {
    return field.actions[ value ].name;
  }
  if (isChoiceField(field)) {
    return field.options.find((option) => option.value === value)?.label;
  }
  if (isStatusField(field) && field.states) {
    if (value?.__typename == "Status") {
      let result = { ...value, row: { field, status: value.status } };
      if (field.states[ value.status ]) {
        result.status = field.states[ value.status ].status;
      }
      if (value.action && field.actions[ value.action ]) {
        result.action = field.actions[ value.action ].name;
      }
      return result;
    } else if (field.states[ value ]) {
      const { status } = field.states[ value ];
      return `${status}`;
    }
  }
  return value;
}

export function getReplacementParams(types: string[], templateType: string = "email") {
  const results = [];
  types.map(type => {
    const schema = getSchema(type);
    Object.entries(schema.fields).filter(field => {
      return field[ 1 ]?.[ "template" ]?.[ templateType ];
    }).map(field => {
      const name = field[ 0 ];
      const firstLowerType = (type === "Settings") ? "company" : toFirstLower(type);
      results.push({
        role: transformNameToLabel((type === "Settings") ? "Company" : type),
        value: `{{${firstLowerType}.${name}}}`,
        label: name
      });
    });
  });

  return results;
}
