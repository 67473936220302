import React                 from "react";
import { BoxComponentProps } from "@relcu/ui";
import { AppBar }            from "@relcu/ui";
import { useSideBar }        from "./useSideBar";

export const SideBar = React.memo<BoxComponentProps>(function SideBar() {
  const { contextSchemaHolder, contextSearchHolder, user, items, onAction } = useSideBar();
  return (
    <React.Fragment>
      <AppBar
        user={user}
        onAction={onAction}
        items={items}
      />
      {contextSchemaHolder}
      {contextSearchHolder}
    </React.Fragment>
  );
});
