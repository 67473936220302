import React                    from "react";
import { useEffect }            from "react";
import { useContext }           from "react";
import { ReadCell }             from "@relcu/rc";
import { Proposal }             from "../../Proposal";
import { NullValuesContext }    from "../NullValueProvider";
import { PricingEngineContext } from "../Offer/PricingEngineProvider";

export function useNullValue(name) {
  const context = useContext(Proposal.Context);
  const offersLength = context?.offers?.edges.length;
  const { nulls } = useContext(NullValuesContext);
  const nullIndexes = nulls?.[ name ];
  return (nullIndexes && nullIndexes.size === offersLength);
}

export const NullValueCell = React.memo<{ name: string }>(function NullValueCell(props) {
  const shouldNull = useNullValue(props.name);
  const { index } = useContext(PricingEngineContext);
  const { registerNull } = useContext(NullValuesContext);
  useEffect(() => {
    registerNull(props.name, index);

    return () => registerNull(props.name, index, "decrease");
  }, [props.name, index]);
  return shouldNull ?
    null :
    <ReadCell justify={"end"}>
      —
    </ReadCell>;
});
