import { OnChange }                    from "@relcu/form";
import React                           from "react";
import { FC }                          from "react";
import { useContext }                  from "react";
import { useState }                    from "react";
import { useReactiveVar }              from "@apollo/client";
import { useLazyQuery }                from "@apollo/client";
import { useNavigate }                 from "@relcu/react-router";
import { useField }                    from "@relcu/final-form";
import { FormSpy }                     from "@relcu/final-form";
import { useForm }                     from "@relcu/final-form";
import { FormField }                   from "@relcu/rc";
import { Advice }                      from "@relcu/rc";
import { IconButton }                  from "@relcu/rc";
import { Icon }                        from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { Stack }                       from "@relcu/rc";
import { useSource }                   from "@relcu/ui";
import { toFirstLower }                from "@relcu/ui";
import { toFirstUpper }                from "@relcu/ui";
import { useAlert }                    from "@relcu/ui";
import { layoutVar }                   from "../../../../../../reactiveVars";
import { loadingVar }                  from "../../../../../../reactiveVars";
import { MessageDraft }                from "../../../../../../reactiveVars";
import { messageDraftsVar }            from "../../../../../../reactiveVars";
import { getDefaultPhoneNumberForSMS } from "../../../../../../utils/helpers";
import { getNodeEmail }                from "../../../../../../utils/helpers";
import { handleFlyerUpload }           from "../../../../../../utils/helpers";
import { transformNameToLabel }        from "../../../../../../utils/helpers";
import { getObjectPath }               from "../../../../../../utils/layoutUtils";
import { PreviewSettings }             from "../../../../../Generation";
import { useMailApi }                  from "../../../../../useMailApi";
import { PointerPickerProps }          from "../../../../Field/PointerPicker";
import { useJqlLazyQuery }             from "../../../../Jql";
import { GetMergeTagsVariables }       from "../../__types__/GetMergeTags";
import { GetMergeTags }                from "../../__types__/GetMergeTags";
import { GET_MERGE_TAGS }              from "../../FlyerGenerationViewQueries";
import { FlyerContext }                from "../Content";
import { MailboxPreview }              from "./MailboxPreview";
import { ScopePreview }                from "./ScopePreview";
import { UserPreview }                 from "./UserPreview";

const requiredFields = {
  "scope": "Scope name",
  "user": "User",
  "mailbox": "Email",
  "jsonContent": "Content"
};

export const FlyerPreviewSettings: FC = React.memo(function TemplatePreviewSettings(props) {
  const { isGenerating, isLO, emailEditorRef } = useContext(FlyerContext);
  const layout = layoutVar();
  const { $object, $viewer } = useSource();
  const navigate = useNavigate();
  const loading = useReactiveVar(loadingVar);
  const [downloading, setDownloading] = useState(false);
  const form = useForm();
  const { input: { value: previewSettings } } = useField("previewSettings");
  const { success, error, info } = useAlert();
  const { nodeEmail, createDraftMail } = useMailApi($object);
  const [getMergeTags] = useLazyQuery<GetMergeTags, GetMergeTagsVariables>(GET_MERGE_TAGS, {
    fetchPolicy: "network-only"
  });
  const canSend = $object.__typename == "User" ? $object.flyerPermissions?.send : $viewer.flyerPermissions?.send || $object.className === "Settings";
  const [getFlyerScope] = useJqlLazyQuery({
    operation: previewSettings.scope?.__typename && toFirstLower(previewSettings.scope.__typename),
    variables: {
      id: {
        name: "id",
        type: `ID!`
      }
    },
    fields: layout[ previewSettings.scope?.__typename ]?.jql?.query?.get?.fields
  }, {
    operationName: `GetFlyerScope`
  });

  function getErrors(type) {
    const { applyTo, jsonContent, previewSettings } = form.getState().values;
    return Object.keys(requiredFields).reduce((previousValue, currentValue) => {
      if (!{ jsonContent, ...previewSettings }[ currentValue ]) {
        const name = currentValue == "scope" ? applyTo : requiredFields[ currentValue ];
        if (currentValue == "mailbox" && type != "email") {
          return previousValue;
        } else if (name) {
          previousValue.push(`${transformNameToLabel(toFirstUpper(name))} is required.`);
        }
      }

      return previousValue;
    }, []);
  }

  async function handleDownload() {
    try {
      setDownloading(true);
      info("PDF or image generation can take up to 30 seconds.");
      const { values } = form.getState();
      const mergeTags = await getMergeTags({
        variables: {
          scope: values.previewSettings.scope.id,
          user: values.previewSettings.user.objectId
        }
      });

      const tags = Object.entries(mergeTags.data.getMergeTags).reduce((previousValue, currentValue) => {
        previousValue[ `{{${currentValue[ 0 ]}}}` ] = currentValue[ 1 ];
        return previousValue;
      }, {});

      if (values.availableForType == "sms") {
        emailEditorRef.current.editor.exportImage((data) => {
            setDownloading(false);
            if (data.url) {
              let link = document.createElement("a");
              link.href = data.url;
              link.target = "_blank";
              link.dispatchEvent(new MouseEvent("click"));
            }
          },
          {
            mergeTags: tags
          }
        );
      } else {
        emailEditorRef.current.editor.exportPdf((data) => {
            setDownloading(false);
            if (data.url) {
              let link = document.createElement("a");
              link.href = data.url;
              link.target = "_blank";
              link.dispatchEvent(new MouseEvent("click"));
            }
          },
          {
            mergeTags: tags
          }
        );
      }
    } catch (e) {
      setDownloading(false);
      error(e.message);
    }
  }
  const handleSendVia = async (type: "sms" | "email") => {
    try {
      const { jsonContent, previewSettings, title } = form.getState().values;
      loadingVar(true);
      const data = new FormData();
      data.append("data", JSON.stringify({
        jsonContent,
        generationType: type == "email" ? "pdf" : "image",
        user: previewSettings.user?.objectId,
        scope: previewSettings.scope?.id,
        name: title
      }));
      const { data: scope } = await getFlyerScope({
        variables: {
          id: previewSettings.scope.id
        }
      });

      if (type == "email") {
        let to = nodeEmail;
        let from = previewSettings.mailbox.address;
        if (isGenerating) {
          to = getNodeEmail(scope[ toFirstLower(previewSettings.scope.__typename) ]);
        }

        if (!to) {
          error("The recipient has opted out or does not have an email address.");
          loadingVar(false);
          return;
        }

        info("PDF or image generation can take up to 30 seconds.");
        const file = await handleFlyerUpload(data);
        const selectedScope = isGenerating ? previewSettings.scope : $object;
        const { data: { createDraftEmail: { conversation: { objectId } } } } = await createDraftMail({
          subject: "New email with flyer",
          from: from,
          to: [to],
          html: "",
          text: "",
          scope: selectedScope.id,
          attachments: [{
            url: file.url,
            objectId: file.objectId,
            mimeType: file.mimeType,
            name: file.name
          }]
        });
        setTimeout(() => {
          navigate(`${getObjectPath(selectedScope)}/emails?from=${objectId}`);
        });
      } else {
        const scopeClassName = toFirstLower(previewSettings.scope.__typename);
        const number = getDefaultPhoneNumberForSMS(scope[ scopeClassName ].phones, scopeClassName);

        if (!number || number.smsOptOut || number.optOut) {
          error("The recipient has opted out or does not have an phone number.");
          loadingVar(false);
          return;
        }

        info("PDF or image generation can take up to 30 seconds.");
        const file = await handleFlyerUpload(data);
        const drafts = messageDraftsVar();
        const selectedScope = isGenerating ? previewSettings.scope : $object;
        const { id, ...attachment } = file;
        const draft: MessageDraft = {
          type: "sms",
          attachment: [{ attachment }]
        };
        messageDraftsVar({
          ...drafts,
          [ selectedScope.objectId ]: draft
        });

        setTimeout(() => {
          navigate(`${getObjectPath(selectedScope)}/sms`);
        });
      }
      loadingVar(false);
      success("Flyer generated.");
    } catch (e) {
      loadingVar(false);
      error(e.message);
    }
  };

  return <PreviewSettings>
    <PreviewSettings.Section>
      <PreviewSettings.Title>
        Apply to
      </PreviewSettings.Title>
      <FormField<PointerPickerProps>
        component={UserPreview}
        name={"previewSettings.user"}
        label={"User"}
        errorMessage={false}
        properties={{
          disabled: isLO
        }}
      />
      <FormSpy subscription={{ values: true, initialValues: true }}>
        {
          ({ values }) => {
            return values.previewSettings.user && values.availableForType == "email" ?
              <FormField<PointerPickerProps>
                component={MailboxPreview}
                name={"previewSettings.mailbox"}
                label={"Email"}
                errorMessage={false}
              />
              : null;
          }
        }
      </FormSpy>
      <FormSpy subscription={{ values: true, initialValues: true }}>
        {
          ({ values }) => {
            return <>
              {
                values.applyTo && values.previewSettings?.user &&
                <FormField<PointerPickerProps>
                  component={ScopePreview}
                  name={"previewSettings.scope"}
                  label={transformNameToLabel(values.applyTo)}
                  errorMessage={false}
                  properties={{
                    disabled: !isGenerating,
                    targetClass: toFirstUpper(values.applyTo)
                  }}
                />
              }
              <Stack spacing={12} style={{ alignSelf: "center" }}>
                {
                  canSend &&
                  <Button
                    size={"xs"}
                    onClick={() => handleSendVia(values.availableForType)}
                    loading={loading}
                    disabled={loading || !values.applyTo || !values.enabled || !!Object.keys(getErrors(values.availableForType)).length}>
                    {`SEND VIA ${values.availableForType == "sms" ? "MMS" : "EMAIL"} `}
                  </Button>
                }
                {/*todo change to this after making "availableForType" checkbox*/}
                {/*<ToggleDropdown*/}
                {/*  onClick={(event) => event.stopPropagation()}*/}
                {/*  toggleRenderer={*/}
                {/*    <Button*/}
                {/*      size={"xs"}*/}
                {/*      style={{ alignSelf: "end" }}*/}
                {/*      endIcon={<Icon type="keyboard_arrow_down"/>}>*/}
                {/*      SEND VIA*/}
                {/*    </Button>*/}
                {/*  }*/}
                {/*  placement={"autoVerticalEnd"}>*/}
                {/*  <ToggleDropdown.Item*/}
                {/*    eventKey={3}*/}
                {/*    onSelect={(event) => undefined}>*/}
                {/*    <Icon type={"email"}/>*/}
                {/*    Email*/}
                {/*  </ToggleDropdown.Item>*/}
                {/*  <ToggleDropdown.Item*/}
                {/*    eventKey={3}*/}
                {/*    onSelect={(event) => undefined}>*/}
                {/*    <Icon type={"forum"}/>*/}
                {/*    SMS*/}
                {/*  </ToggleDropdown.Item>*/}
                {/*</ToggleDropdown>*/}
                <Advice text={"Download"}>
                  <IconButton
                    loading={downloading}
                    appearance={"text"}
                    size={"xs"}
                    icon={<Icon type={"file_download"}/>}
                    disabled={downloading || !!Object.keys(getErrors(values.availableForType)).length}
                    onClick={handleDownload}
                  />
                </Advice>
              </Stack>
            </>;
          }
        }
      </FormSpy>
    </PreviewSettings.Section>
  </PreviewSettings>;
});




