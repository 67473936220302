import React                          from "react";
import { FC }                         from "react";
import { useEffect }                  from "react";
import { useCallback }                from "react";
import { useMemo }                    from "react";
import { useState }                   from "react";
import { DateTime }                   from "luxon";
import { useLazyQuery }               from "@apollo/client";
import { gql }                        from "@apollo/client";
import { useApolloClient }            from "@apollo/client";
import { isValidDate }                from "@relcu/ui";
import { useSource }                  from "@relcu/ui";
import { MultiSelectVariants }        from "@relcu/ui";
import { TypographyColor }            from "@relcu/ui";
import { ButtonColors }               from "@relcu/ui";
import { BoxComponentProps }          from "@relcu/ui";
import { ButtonVariants }             from "@relcu/ui";
import { Button }                     from "@relcu/ui";
import { ChipsColors }                from "@relcu/ui";
import { Chips }                      from "@relcu/ui";
import { CommonClasses }              from "@relcu/ui";
import { FontIcon }                   from "@relcu/ui";
import { Typography }                 from "@relcu/ui";
import { DatePicker }                 from "@relcu/ui";
import { MultiSelect }                from "@relcu/ui";
import { HorizontalDivider }          from "@relcu/ui";
import { CircularLoader }             from "@relcu/ui";
import { EmptyList }                  from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { JsonPageProps }              from "@relcu/ui";
import { Alignment }                  from "@relcu/ui";
import { ActivityOrder }              from "../../../../types/graphql-global-types";
import { divideDataByDate, toNodeId } from "../../../../utils/helpers";
import { Activity }                   from "../../../__types__/Activity";
import { ACTIVITY_FRAGMENT }          from "../../../operations.graphql";
import { RelayQuery }                 from "../../../Relay";
import { GetActivities }              from "./__types__/GetActivities";
import { GetActivityVariables }       from "./__types__/GetActivity";
import { GetActivity }                from "./__types__/GetActivity";
import { Filters }                    from "./constants";
import { Exclusions }                 from "./constants";
import { activityItemsMap }           from "./constants";
import { TimelineViewClasses }        from "./TimelineViewClasses";
import "./timeline-view.css";

const TimelineView: FC<JsonPageProps> = React.memo((props) => {
  const { $object, $viewer } = useSource();
  const client = useApolloClient();
  let filterOptions = Filters[ $object.__typename ];
  let exclusions = Exclusions[ $object.__typename ];
  if ($object.__typename === "User" && $viewer.role === "loan_officer") {
    filterOptions = filterOptions.filter(f => !exclusions[ $viewer.role ].includes(f.value));
  }
  const [filterDate, setFilterDate] = useState();
  const [date, setDate] = useState<string>();
  const [fromObjectId, setFromObjectId] = useState<string>();
  const savedFilters = localStorage.getItem(`${$object.__typename}-activities`);
  const [filters, setFilters] = useState(JSON.parse(savedFilters) || filterOptions);
  const { queryVariables, subscriptionVariables } = useMemo(() => createWhereInputVariables($object, filters), [$object, filters]);
  const qVariables = useMemo(() => {
    const activity = client.readFragment({
      id: client.cache.identify({ id: toNodeId({ className: "Activity", objectId: fromObjectId }), __typename: "Activity" }),
      fragment: SELECTED_ACTIVITY,
      fragmentName: "ShortActivity"
    });
    const vars = {
      ...queryVariables
    };

    if (!activity?.type || (filters.findIndex((filter) => filter.value == activity.type) > -1)) {
      vars[ "from" ] = fromObjectId;
    }

    return vars;
  }, [queryVariables, fromObjectId]);

  const GET_FIRST_ACTIVITY_BY_DATE = gql`
    query GetActivity($first:Int, $where: ActivityWhereInput!, $order: [ActivityOrder!]){
      activities(first: $first, where: $where, order: $order){
        edges {
          node {
            createdAt
            objectId
          }
        }
      }
    }
  `;
  const [loadActivity] = useLazyQuery<GetActivity, GetActivityVariables>(GET_FIRST_ACTIVITY_BY_DATE, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "network-only"
  });
  const setSelectedFilters = useCallback((filters) => {
    setFilters(filters);
    localStorage.setItem(`${$object.__typename}-activities`, JSON.stringify(filters));
  }, []);
  const setSelectedDate = useCallback(async (date) => {
    setFilterDate(date);
  }, []);

  const excludedFilters = useMemo(() => {
    let excluded = [];
    filterOptions.map(option => {
      const selected = filters.find(filter => filter.value === option.value);
      if (!selected) {
        excluded.push(option);
      }
    });
    return excluded;
  }, [filters, filterOptions]);

  const removeChip = useCallback((chip) => {
    setFilters((filters) => [...filters, chip]);
    localStorage.setItem(`${$object.__typename}-activities`, JSON.stringify([...filters, chip]));
  }, []);

  const getFromObject = async (date) => {
    const queryDate = DateTime.fromFormat(date ?? "", "LL/dd/yyyy").plus({ days: 1 });
    const { data: { activities: { edges: beforeEdges = [] } = {} } = {} } = await loadActivity({
      variables: {
        ...queryVariables,
        first: 1,
        order: [ActivityOrder.createdAt_DESC],
        where: {
          ...queryVariables.where,
          createdAt: { lessThanOrEqualTo: queryDate }
        }
      }
    });
    if (beforeEdges.length) {
      return beforeEdges.at(0).node.objectId;
    }
    const { data: { activities: { edges: afterEdges = [] } = {} } = {} } = await loadActivity({
      variables: {
        ...queryVariables,
        first: 1,
        order: [ActivityOrder.createdAt_ASC],
        where: {
          ...queryVariables.where,
          createdAt: { greaterThanOrEqualTo: queryDate }
        }
      }
    });
    if (afterEdges.length) {
      return afterEdges.at(0).node.objectId;
    }
  };

  function handleChange(value) {
    if (isValidDate(value)) {
      value = DateTime.fromJSDate(value).toFormat("LL/dd/yyyy");
    }
    value = value || null;
    setDate(value);
  }
  function clearAll() {
    setFilters(filterOptions);
    localStorage.setItem(`${$object.__typename}-activities`, JSON.stringify(filterOptions));
  }

  useEffect(() => {
    if (filterDate) {
      getFromObject(filterDate).then((r) => {
        setFromObjectId(r);
      });
    }
  }, [filterDate, queryVariables]);

  return <Box container direction={"column"} className={TimelineViewClasses.TimelineView} flex={"1 1 auto"}>
    <Box container gap={"XS"} direction={"column"} className={TimelineViewClasses.TimelineViewFilterContainer}>
      <Box container justify={"space-between"}>
        <Box container alignItems={"center"}>
          <Typography color={TypographyColor.Secondary}
                      className={TimelineViewClasses.TimelineViewFilterSelectLabel}> Activity type: </Typography>
          <MultiSelect
            searchable={true}
            filterable={false}
            onChange={setSelectedFilters}
            value={filters}
            options={filterOptions}
            variant={MultiSelectVariants.Ghost}
            optionKey={"value"}
            renderHeader={(options) => {
              return <Box container gap={"XXS"} alignItems={"center"}>
                {
                  <Typography>
                    {options.length ? `${options.length} selected` : "No selected activities"}
                  </Typography>
                }
                <FontIcon type={"keyboard_arrow_down"} className={CommonClasses.ClickableIcon}/>
              </Box>;
            }}
            width={250}
          />
        </Box>
        <DatePicker onApply={(date => setSelectedDate(date))}
                    placeholder={" "} type={"date"}
                    onChange={handleChange}
                    value={date ?? DateTime.now().toFormat("LL/dd/yyyy") as any}
                    alignment={Alignment.BottomRight}
                    dateBlocked={(day) => DateTime.now() <= DateTime.fromISO(day.toISOString())}
        />
      </Box>
      {!!excludedFilters.length &&
        <Box container gap={"XS"} alignItems={"center"} wrap={"wrap"}>
          <Typography color={TypographyColor.Secondary}> Excluded: </Typography>
          {excludedFilters.map((f, i) => {
            return <Chips key={i} label={f.label} onDelete={() => removeChip(f)} color={ChipsColors.Grey}/>;
          })}
          <Button variant={ButtonVariants.Ghost} color={ButtonColors.Info} onClick={clearAll}>Clear all</Button>
        </Box>}
    </Box>
    <Box><HorizontalDivider/></Box>
    <RelayQuery<GetActivities>
      className={"Activity"}
      rowHeight={40}
      key={filters}
      scrollId={fromObjectId}
      setScrollIntoId={setFromObjectId}
      query={{
        document: GET_ACTIVITIES,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: qVariables
      }}
      subscription={{
        document: SUBSCRIBE_ACTIVITIES,
        variables: subscriptionVariables
      }}
      render={(renderProps) => {
        const {
          scrollContainerRef,
          beforeLoaderRef,
          afterLoaderRef,
          register,
          data: { activities: { edges = [], pageInfo } } = {}
        } = renderProps;
        const dividedData = divideDataByDate(edges);
        const isEmpty = !edges.length;
        return (
          <Box className={TimelineViewClasses.TimelineView} container direction={"column"} flex={1}
               ref={scrollContainerRef}>
            {
              !isEmpty ?
                <Box container direction={"column"} gap={"XXS"} flexGrow={1}
                     className={TimelineViewClasses.TimelineViewContainer}>
                  {pageInfo?.hasPreviousPage &&
                    <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>}

                  {dividedData.map((item, index) =>
                    <TimelineViewActivity key={index} activity={item.node || item}
                                          ref={e => register(e, item.node.objectId)}/>
                  )}

                  {pageInfo?.hasNextPage &&
                    <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>}
                </Box>
                :
                <Box container flex={1} justify={"center"} alignItems={"center"}>
                  <EmptyList icon={"history"} title={"No activity"}
                             content={`There were no timeline activities recorded`}
                             alignSelf={"center"}/>
                </Box>
            }
          </Box>
        );
      }}
    />
  </Box>;
});

interface TimelineViewActivityProps {
  activity: Activity | string;
}

const TimelineViewActivity = React.forwardRef<any, TimelineViewActivityProps>((props, ref) => {
  const { activity } = props;

  if (typeof activity == "string") {
    return <TimelineViewDate children={activity}/>;
  }
  const { type, createdAt, data, relatedTo, createdBy } = activity;
  const ActivityComponent = activityItemsMap[ type ];

  return <div className={TimelineViewClasses.TimelineViewItemWrapper} ref={ref}><ActivityComponent
    createdAt={createdAt} {...data} initiatorObjectId={createdBy.objectId} relatedTo={relatedTo}/></div>;
});

const TimelineViewDate: FC<BoxComponentProps> = React.memo(props => {
  const { children } = props;
  return (
    <Box container direction={"column"} alignItems={"center"} className={TimelineViewClasses.TimelineViewDate}>
      <Typography>{children}</Typography>
    </Box>
  );
});

const SELECTED_ACTIVITY = gql`
  fragment ShortActivity on Activity{
    id
    type
  }
`;

const GET_ACTIVITIES = gql`
  ${ACTIVITY_FRAGMENT}
  query GetActivities($before:String,$after:String,$first:Int,$last:Int, $from:String, $where: ActivityWhereInput!){
    activities(where: $where, order:createdAt_DESC, first: $first, last:$last, before: $before, from:$from, after: $after){
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...Activity
        }
      }
    }
  }
`;
const SUBSCRIBE_ACTIVITIES = gql`
  ${ACTIVITY_FRAGMENT}
  subscription SubscribeActivities($where: ActivitySubscriptionWhereInput) {
    activities(where: $where) {
      event
      node {
        ...Activity
      }
    }
  }
`;
const createWhereInputVariables = (node, types) => {
  const whereTypes = types.map((type) => type.value);

  return {
    queryVariables: {
      where: {
        type: {
          in: whereTypes
        },
        relatedTo: {
          contains: [node.objectId]
        }
      }
    },
    subscriptionVariables: {
      where: {
        relatedTo: {
          contains: [node.objectId]
        },
        type: {
          in: whereTypes
        }
      }
    }
  };
};

export default TimelineView;
//2023-03-01T12:48:39.097Z
